import React, { FC, useContext } from 'react';
// components
import { Button, Text, Centered, Modal } from '../common';
import { ZipCodeLookupToggle } from '../zip-code';
import { Language } from '../../context/language';

interface ISubmitRequestErrorModal {
  isOpen: boolean;
  handleClose: () => void;
  message: string;
}

export const SubmitRequestErrorModal: FC<ISubmitRequestErrorModal> = ({ isOpen, handleClose, message }) => {
  const { isFrench } = useContext(Language);
  return (
    <Modal isOpen={isOpen} onDismiss={handleClose}>
      <Centered flexDirection="column" padding="x1" paddingTop={{ xs: 'x1', md: 'x2' }} paddingBottom={{ xs: 'x1', md: 'x2' }}>
        <Text forwardedAs="h4" color="primary" fontSize="xxl">
          {isFrench ? 'Erreur de soumission' : 'Submit Error'}
        </Text>
        <Text marginTop="m" marginBottom="m">
          {message}
        </Text>
        {isFrench && (
          <Text marginBottom="m">
            Veuillez réessayer, ou <ZipCodeLookupToggle onClick={handleClose} text="contacter le centre d'assistance" /> pour obtenir de l'aide.
          </Text>
        )}
        {!isFrench && (
          <Text marginBottom="m">
            Please try again{message.includes('24 hours') ? ', after 24 hours' : ''}, or <ZipCodeLookupToggle onClick={handleClose} text="contact the support center" /> for help.
          </Text>
        )}
        <Button backgroundColor="primary" padding="xs" width="x4" color="white" onClick={handleClose}>
          Ok
        </Button>
      </Centered>
    </Modal>
  );
};
