import React, { FC } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import styled from '@xstyled/styled-components';
import { system, createSystemComponent, SystemProps } from '@xstyled/system';
import { darken } from 'polished';

const SystemLink = createSystemComponent(React, RouterLink);

export const Link: FC<SystemProps & LinkProps> = styled(SystemLink)`
  color: primary;
  ${system};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  ${(p: any) =>
    p.backgroundColor === 'primary'
      ? `
    &:focus {
      outline-color: ${darken(0.25, p.theme.colors[p.backgroundColor])};
    }
    `
      : `
    &:focus {
      outline-color: ${p.theme.colors.primary};
    }
    `}
`;

interface IExternalLink {
  href: string;
}

export const ExternalLink: FC<SystemProps & IExternalLink> = styled.aBox.attrs({
  target: '_blank',
  rel: 'noopener noreferrer'
})`
  color: primary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  ${(p: any) =>
    p.backgroundColor === 'primary'
      ? `
    &:focus {
      outline-color: ${darken(0.25, p.theme.colors[p.backgroundColor])};
    }
    `
      : `
    &:focus {
      outline-color: ${p.theme.colors.primary};
    }
    `}
`;
