interface IStates {
  text: string;
  key: string;
}

export const states: IStates[] = [
  {
    text: 'Alabama',
    key: 'AL'
  },
  {
    text: 'Alaska',
    key: 'AK'
  },
  {
    text: 'Alberta',
    key: 'AB'
  },
  {
    text: 'American Samoa',
    key: 'AS'
  },
  {
    text: 'Arizona',
    key: 'AZ'
  },
  {
    text: 'Arkansas',
    key: 'AR'
  },
  {
    text: 'British Columbia',
    key: 'BC'
  },
  {
    text: 'California',
    key: 'CA'
  },
  {
    text: 'Colorado',
    key: 'CO'
  },
  {
    text: 'Connecticut',
    key: 'CT'
  },
  {
    text: 'Delaware',
    key: 'DE'
  },
  {
    text: 'District Of Columbia',
    key: 'DC'
  },
  {
    text: 'Federated States Of Micronesia',
    key: 'FM'
  },
  {
    text: 'Florida',
    key: 'FL'
  },
  {
    text: 'Georgia',
    key: 'GA'
  },
  {
    text: 'Guam',
    key: 'GU'
  },
  {
    text: 'Hawaii',
    key: 'HI'
  },
  {
    text: 'Idaho',
    key: 'ID'
  },
  {
    text: 'Illinois',
    key: 'IL'
  },
  {
    text: 'Indiana',
    key: 'IN'
  },
  {
    text: 'Iowa',
    key: 'IA'
  },
  {
    text: 'Kansas',
    key: 'KS'
  },
  {
    text: 'Kentucky',
    key: 'KY'
  },
  {
    text: 'Louisiana',
    key: 'LA'
  },
  {
    text: 'Maine',
    key: 'ME'
  },
  {
    text: 'Manitoba',
    key: 'MB'
  },
  {
    text: 'Marshall Islands',
    key: 'MH'
  },
  {
    text: 'Maryland',
    key: 'MD'
  },
  {
    text: 'Massachusetts',
    key: 'MA'
  },
  {
    text: 'Michigan',
    key: 'MI'
  },
  {
    text: 'Minnesota',
    key: 'MN'
  },
  {
    text: 'Mississippi',
    key: 'MS'
  },
  {
    text: 'Missouri',
    key: 'MO'
  },
  {
    text: 'Montana',
    key: 'MT'
  },
  {
    text: 'Nebraska',
    key: 'NE'
  },
  {
    text: 'Nevada',
    key: 'NV'
  },
  {
    text: 'New Brunswick',
    key: 'NB'
  },
  {
    text: 'New Hampshire',
    key: 'NH'
  },
  {
    text: 'New Jersey',
    key: 'NJ'
  },
  {
    text: 'New Mexico',
    key: 'NM'
  },
  {
    text: 'New York',
    key: 'NY'
  },
  {
    text: 'Newfoundland and Labrador',
    key: 'NL'
  },
  {
    text: 'North Carolina',
    key: 'NC'
  },
  {
    text: 'North Dakota',
    key: 'ND'
  },
  {
    text: 'Northern Mariana Islands',
    key: 'MP'
  },
  {
    text: 'Northwest Territories',
    key: 'NT'
  },
  {
    text: 'Nova Scotia',
    key: 'NS'
  },
  {
    text: 'Ohio',
    key: 'OH'
  },
  {
    text: 'Oklahoma',
    key: 'OK'
  },
  {
    text: 'Ontario',
    key: 'ON'
  },
  {
    text: 'Oregon',
    key: 'OR'
  },
  {
    text: 'Palau',
    key: 'PW'
  },
  {
    text: 'Pennsylvania',
    key: 'PA'
  },
  {
    text: 'Prince Edward Island',
    key: 'PE'
  },
  {
    text: 'Puerto Rico',
    key: 'PR'
  },
  {
    text: 'Quebec',
    key: 'QC'
  },
  {
    text: 'Rhode Island',
    key: 'RI'
  },
  {
    text: 'Saskatchewan',
    key: 'SK'
  },
  {
    text: 'South Carolina',
    key: 'SC'
  },
  {
    text: 'South Dakota',
    key: 'SD'
  },
  {
    text: 'Tennessee',
    key: 'TN'
  },
  {
    text: 'Texas',
    key: 'TX'
  },
  {
    text: 'Utah',
    key: 'UT'
  },
  {
    text: 'Vermont',
    key: 'VT'
  },
  {
    text: 'Virgin Islands',
    key: 'VI'
  },
  {
    text: 'Virginia',
    key: 'VA'
  },
  {
    text: 'Washington',
    key: 'WA'
  },
  {
    text: 'West Virginia',
    key: 'WV'
  },
  {
    text: 'Wisconsin',
    key: 'WI'
  },
  {
    text: 'Wyoming',
    key: 'WY'
  },
  {
    text: 'Yukon',
    key: 'YT'
  }
];

// send the key to this func and it will send back true/false if canada prov
export const isCanadaProv = (val: string) =>
  val === 'ON' ||
  val === 'AB' ||
  val === 'BC' ||
  val === 'MB' ||
  val === 'NB' ||
  val === 'NL' ||
  val === 'NT' ||
  val === 'NS' ||
  val === 'NU' ||
  val === 'PE' ||
  val === 'QC' ||
  val === 'SK' ||
  val === 'YT';
