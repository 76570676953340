import styled, { Box } from '@xstyled/styled-components';
import React, { FC, useContext } from 'react';
// components
import { PrivacyPolicyContent } from '../components/privacy-policy';
import { PageTitle, Page } from '../components/skeleton';
// services
import { withTracker } from '../services';
// theme
import theme from '../theme';
import { Language } from '../context/language';

const PrivacyPolicy: FC = () => {
  const { isFrench } = useContext(Language);

  return (
    <Page
      secCol={
        <Box padding={{ xs: 'm', md: '0' }} paddingTop="0" paddingBottom="0">
          <PageTitle>{isFrench ? 'Politique de confidentialité' : 'Privacy Policy'}</PageTitle>
          <StyledBox>
            <PrivacyPolicyContent />
          </StyledBox>
        </Box>
      }
    />
  );
};

const StyledBox = styled(Box)`
  padding: 0 1.5rem 0 0;

  @media (min-width: ${theme.breakpoints.md}px) {
    overflow: auto;
    max-height: 52vh;
  }

  @media (min-width: ${theme.breakpoints.md}px) and (min-height: ${theme.breakpoints.sm}px) {
    max-height: 58vh;
  }

  @media (min-width: ${theme.breakpoints.md}px) and (min-height: ${theme.breakpoints.md}px) {
    max-height: 65vh;
  }
  @media (min-width: ${theme.breakpoints.lg}px) {
    max-height: 33rem;
  }
`;

export default withTracker(PrivacyPolicy);
