import React from 'react';
import { Box } from '@xstyled/styled-components';
// components
import { Image, Text } from '../common';
import { CopyExample } from '../kitchen-sink/Copy';
import image from '../../static/logo-horizontal-color.png';

export const ImageExample = () => {
  return (
    <Box marginTop="x1" marginBottom="x1">
      <hr />
      <Text forwardedAs="h2" marginBottom="s">
        Image
      </Text>
      <Image src={image} alt="" width="100%" />
      <CopyExample>{`<Image src={image} alt="" width="100%" />`}</CopyExample>
    </Box>
  );
};
