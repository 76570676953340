import React from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { createAI } from '../../services';
import { withRouter } from 'react-router-dom';

interface IInsightsProviderProps {
  history: History;
  instrumentationKey: string;
  callback?: () => void;
}
/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class InsightsProvider extends React.Component<IInsightsProviderProps> {
  state = {
    initialized: false
  };

  componentDidMount() {
    const { history, instrumentationKey, callback } = this.props;
    const { initialized } = this.state;
    if (!Boolean(initialized) && Boolean(instrumentationKey) && Boolean(history)) {
      createAI.initialize(instrumentationKey, history);
      this.setState({ initialized: true });
    }
    callback && callback();
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(withAITracking(createAI.reactPlugin, InsightsProvider) as any) as any;
