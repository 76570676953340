import React, { FC } from 'react';
import styled from '@xstyled/styled-components';
import { system, SystemProps } from '@xstyled/system';
import { Dropdown as OfficeDropdown, IDropdownProps } from '@fluentui/react';

export const Dropdown: FC<SystemProps & IDropdownProps> = styled((props: SystemProps & IDropdownProps) => {
  return (
    <OfficeDropdown
      {...props}
      styles={{
        dropdownItemSelected: {
          margin: '0.5rem 0',
          height: 'auto'
        },
        dropdownItem: {
          margin: '0.5rem 0',
          height: 'auto'
        },
        dropdownOptionText: {
          whiteSpace: 'normal'
        },
        errorMessage: { color: '#d0021b' }
      }}
    />
  );
})`
  ${system}
`;
