import React, { FC, useState, useEffect, useContext } from 'react';
import to from 'await-to-js';
import { useMedia } from 'react-use';
// components
import { SearchInput, Button, Link, ButtonLink, Loader, NoResult, ErrorMessage } from '../common';
import { Box } from '@xstyled/styled-components';
import { getAccountByAssetId } from '../../fetch';
import { AccountInformation, NationalAccountModal } from '../account-number';
import { DisabledAppModal } from '../skeleton';
import { BarcodeExample } from '../scan-barcode';
// fetch
import { postLogin } from '../../fetch/login';
// hooks
import { useGlobalState } from '../../stateManagement';
import { IEquipmentInner, EquipmentTypes, IBottlerAccess, Apps, IOutletInner, IOutletAccountEquipment } from '../../models';
// models
import { EquipmentLookupTypes } from '../../models/equipment-lookup-type';
import { setLoginId, removeLoginId } from '../../services';
// context
import { BottlersAccess } from '../../context/bottlersAccess';
// helpers
import { filteredEquipmentOptions } from '../../helpers';
import { Language } from '../../context/language';
import { ConsentInfoModal, ConsentModal } from '../select-equipment';
import { useHistory } from 'react-router-dom';

interface IAssetContent {
  setSubHeading: (val: string) => void;
}

export const AssetContent: FC<IAssetContent> = ({ setSubHeading }) => {
  const [value, setValue] = useState<string>('');
  const [result, setResult] = useState<IOutletAccountEquipment | null>(null);
  const [selectedEquipment, setSelectedEquipment] = useState<IEquipmentInner | null>(null);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isAssetNotSupported, setAssetNotSupported] = useState<boolean>(false);
  const [showMessageModal, setMessageModal] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const isTabletOrDesktop = useMedia('(min-width: 768px)');
  const [selectedOutlet, showDisabledAppModal] = useState<IOutletInner | null>(null);
  const [showConsentModal, setConsentModal] = useState<boolean>(false);
  const [showConsentInfoModal, setConsentInfoModal] = useState<boolean>(false);
  // hooks
  const { setAccount, setEquipment } = useGlobalState();

  const history = useHistory();

  const handleSearch = async () => {
    setFetching(true);
    setResult(null);
    setAssetNotSupported(false);
    setSubHeading('');
    const [, data] = await to(getAccountByAssetId(value));
    setFetching(false);
    if (data && data.err) {
      return setError(data.err);
    }

    const login = await postLogin({ appId: Apps.COKE_SERVICES_APP, equipmentLookupTypeId: EquipmentLookupTypes.EQUIPMENT_SERIAL_NUMBER });
    if (login.res && login.res.login) {
      await setLoginId(login.res?.login?.id);
    }

    if (data && data.account && Object.values(data).length === 0) {
      return setSubHeading('No Results');
    }
    if (data && data.equipment && filteredEquipmentOptions.includes(data.equipment.equipmentType)) {
      setAssetNotSupported(true);
      return setSubHeading('Not Supported');
    }

    setSubHeading(isFrench ? 'Résultats de recherche' : 'Search Results');
    setSelectedEquipment((data && data.equipment) as IEquipmentInner);
    return setResult(data as IOutletAccountEquipment);
  };

  const checkClearErrors = () => {
    if (error) {
      setError(null);
    }
    if (result && Object.values(result).length === 0) {
      setSubHeading('');
      setResult(null);
    }
  };

  useEffect(() => {
    removeLoginId();
  }, []);

  const isNationalAccount =
    result?.account.marketingId &&
    result?.account.marketingId?.charAt(0) !== '2' &&
    (selectedEquipment?.equipmentTypeId === EquipmentTypes.Fountain ||
      selectedEquipment?.equipmentTypeId === EquipmentTypes.PostMix ||
      selectedEquipment?.equipmentTypeId === EquipmentTypes.PreMix);

  const { bottlersAccess } = useContext(BottlersAccess);
  const selectedBottler: IBottlerAccess | undefined = bottlersAccess && result ? bottlersAccess.find(bottler => bottler.bottlerId === result.account.bottlerId) : undefined;

  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  return (
    <>
      <BarcodeExample
        message={
          isFrench
            ? `Le numéro d’équipement peut être situé à l'avant ou sur le côté de la machine. Assurez-vous d’inscrire le numéro d’équipement qui se trouve au-dessus du code d’item/numéro de modèle (le numéro d’équipement de l’étiquette en exemple sur cette page est DN0000000).`
            : `The asset number can be located on the front or side of the machine.  Please be sure to type in the asset number above the asset code/equipment number label (the asset number in the sample label on this page is DN00000000).`
        }
      />
      <Box display="flex" width="100%">
        <SearchInput
          placeholder={isFrench ? 'Entrer un numéro d’équipement…' : 'Enter an asset number...'}
          width={isTabletOrDesktop ? '50%' : 'auto'}
          minWidth="1px"
          flex={isTabletOrDesktop ? '0 0 auto' : '1'}
          value={value}
          disabled={isFetching}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === 13) {
              handleSearch();
            }
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            checkClearErrors();
            setValue(e.target.value);
          }}
        />
        <Button
          backgroundColor={value && !isFetching ? 'primary' : 'grayLight'}
          color="white"
          disabled={!value || isFetching}
          onClick={handleSearch}
          width={{ xs: 'auto', sm: '47%' }}
        >
          {isFrench && (isTabletOrDesktop && isFrench ? 'Rechercher le numéro d’équipement' : 'Rechercher')}
          {!isFrench && (isTabletOrDesktop && !isFrench ? 'Search Asset Number' : 'Search')}
        </Button>
      </Box>
      {!isFetching && result && Object.values(result).length > 0 && (
        <Box marginRight={{ xs: '0', md: '1.25rem' }} marginBottom="x2" marginTop="x2">
          <AccountInformation accountInfo={result.account} type="asset number" />
          <ButtonLink
            paddingTop="s"
            paddingBottom="s"
            display="block"
            textAlign="center"
            to={selectedBottler && selectedBottler.isActive ? '/submit-request' : ''}
            color="white"
            marginTop="m"
            marginBottom="m"
            width="100%"
            backgroundColor="primary"
            onClick={(e: React.MouseEvent) => {
              const isCharged = result.equipment?.serviceCodeDescription;
              if (isCharged) {
                e.preventDefault();
                setConsentModal(true);
              }

              if (selectedBottler && !selectedBottler.isActive) {
                e.preventDefault();
                return showDisabledAppModal(result.account);
              }

              if (isNationalAccount) {
                e.preventDefault();
                return setMessageModal(true);
              }
              setAccount(result.account);
              setEquipment({
                ...selectedEquipment
              } as IEquipmentInner);
            }}
          >
            {isFrench ? 'Confirmer - Compte correct' : 'Confirm - Account Correct'}
          </ButtonLink>
          <ButtonLink
            to="/equipment-account-number"
            width={{ xs: 'auto', md: '100%' }}
            backgroundColor="grayLighter"
            marginBottom="m"
            paddingTop="s"
            paddingBottom="s"
            paddingLeft={{ xs: 's', md: 0 }}
            paddingRight={{ xs: 's', md: 0 }}
            display="block"
            textAlign="center"
            color="black"
          >
            {isFrench ? 'Compte incorrect - Recherche par numéro de compte' : 'Incorrect Account - Search By Account Number'}
          </ButtonLink>
        </Box>
      )}
      {isFetching && (
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" width="100%" height="x16">
          <Loader />
        </Box>
      )}
      {!isFetching && result && Object.values(result).length === 0 && (
        <>
          {isFrench && (
            <NoResult>
              Aucun résultat trouvé pour le numéro d’actif {value}. Essayez à nouveau de rechercher un autre numéro de ressource, ou{' '}
              <Link to="/">sélectionnez une autre option</Link> pour localiser l’équipement.
            </NoResult>
          )}
          {!isFrench && (
            <NoResult>
              No results found for asset number {value}. Please try searching again for a different asset number, or <Link to="/">select a different option</Link> to locate the
              equipment.
            </NoResult>
          )}
        </>
      )}
      {!isFetching && isAssetNotSupported && (
        <ErrorMessage>
          {isFrench
            ? 'Désolé, le numéro de ressource saisi n’est actuellement pas pris en charge par cette application.'
            : 'Sorry, the entered asset number is not currently supported by this application.'}
        </ErrorMessage>
      )}
      {!isFetching && error && error.message.includes('500') && (
        <ErrorMessage>
          {isFrench
            ? 'Désolé, une erreur s’est produite lors de la recherche de votre numéro de ressource, veuillez actualiser et réessayer.'
            : 'Sorry, there was an error searching for your asset number, please refresh and try again.'}
        </ErrorMessage>
      )}
      <NationalAccountModal isOpen={showMessageModal} handleClose={() => setMessageModal(false)} />
      <DisabledAppModal isOpen={selectedOutlet ? true : false} selectedBottler={selectedBottler} selectedOutlet={selectedOutlet} handleClose={() => showDisabledAppModal(null)} />
      <ConsentInfoModal
        isOpen={showConsentInfoModal}
        handleClose={() => {
          history.push('/');
          setConsentInfoModal(false);
        }}
      />
      <ConsentModal
        message={
          result?.equipment
            ? isFrench
              ? result?.equipment.serviceCodeFrenchDescription
              : result?.equipment.serviceCodeDescription
            : isFrench
            ? 'Veuillez noter que notre politique de service a changé, ce qui pourrait entraîner des frais pour cette activité de service. En sélectionnant « Consentement » ci-dessous, vous acceptez les frais potentiels, le cas échéant.'
            : 'Please be advised that our service policy has changed that may result in a charge for this service activity.  By selecting “Consent” below, you are agreeing to potential charge(s), if applicable, by the service technician.'
        }
        isOpen={showConsentModal}
        handleClose={type => {
          if (type === 'Cancel') {
            setConsentModal(false);
            return setConsentInfoModal(true);
          }
          history.push('/submit-request');
          setConsentModal(false);
        }}
      />
    </>
  );
};
