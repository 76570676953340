import React from 'react';
import styled from '@xstyled/styled-components';
import { useCopyToClipboard } from 'react-use';
// components
import { Button } from '../common';

export const CopyExample = ({ children }: { children: string }) => {
  const [state, copyToClipboard] = useCopyToClipboard();

  return (
    <>
      <Example>{children}</Example>
      <Button marginTop="s" marginBottom="s" backgroundColor="confirmation" color="white" padding="xs" onClick={() => copyToClipboard(children)}>
        {state.value ? 'Copied' : 'Copy'}
      </Button>
    </>
  );
};

const Example = styled.div`
  font-size: s;
  margin-top: s;
  background-color: grayLighter;
  padding: s;
  color: black;
`;
