import React, { FC, useState, useEffect, useRef, useContext } from 'react';
import { useMedia } from 'react-use';
import { breakpoints } from '@xstyled/system';
import to from 'await-to-js';
// components
import { Text, Scanner, ErrorMessage, Link, Loader, Modal, Button, Centered, IconButton, ButtonLink } from '../common';
import { BarcodeExample } from './BarcodeExample';
import { DisabledAppModal } from '../skeleton';
// @ts-ignore
import styled, { Box, css } from '@xstyled/styled-components';
import { AccountInformation, NationalAccountModal } from '../account-number';
// models
import { IOutletInner, IEquipmentInner, IOutletDynamic, EquipmentTypes, IBottlerAccess, Apps, EquipmentLookupTypes } from '../../models';
// fetch
import { getAccountByAssetId, postLogin } from '../../fetch';
// hooks
import { useGlobalState } from '../../stateManagement';
// helpers
import { filteredEquipmentOptions } from '../../helpers';
import { setLoginId, removeLoginId } from '../../services';
// context
import { BottlersAccess } from '../../context/bottlersAccess';
import { Language } from '../../context/language';

interface IScanBarcodeContentProps {
  setHeading: (val: string) => void;
}

export const ScanBarcodeContent: FC<IScanBarcodeContentProps> = ({ setHeading }) => {
  const [isCameraSupported, setCameraSupported] = useState(false);
  const [isCameraAllowed, setCameraAllowed] = useState<boolean>(false);
  const [isCheckingPermissions, setCheckingPermissions] = useState(true);
  // modal that pops up when visiting the barcode page
  const [isShowingMessage, showMessage] = useState<boolean>(true);
  const [deviceFeatures, setCameraFeatures] = useState<any>(null);
  const [isTorchOn, turnOnTorch] = useState<boolean>(false);
  const [isAssetNotSupported, setAssetNotSupported] = useState<boolean>(false);
  const [hasTouchedTorch, touchedTorch] = useState<boolean>(false);
  const [isShowingTroubleShoot, showTroubleShoot] = useState<boolean>(false);
  const [showMessageModal, setMessageModal] = useState<boolean>(false);
  const [result, setResult] = useState<IOutletInner | any | null>(null);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [selectedEquipment, setSelectedEquipment] = useState<IEquipmentInner | null>(null);
  const [selectedOutlet, showDisabledAppModal] = useState<IOutletInner | null>(null);
  const scannerRef = useRef(null);
  const isMobile = useMedia('(max-width: 768px)');
  // state management
  const { setAccount, setEquipment } = useGlobalState();

  const askPermissions = async () => {
    try {
      const data = await navigator.mediaDevices.getUserMedia({
        video: {
          width: {
            min: 640
          },
          height: { min: 480 },
          facingMode: 'environment',
          aspectRatio: { min: 1, max: 2 }
        }
      });

      if (data) {
        setCheckingPermissions(false);
        setCameraAllowed(true);
        // timeout here so we call all of the available camera features
        setTimeout(() => {
          setCameraFeatures(data.getVideoTracks()[0].getCapabilities());
        }, 500);
      }
    } catch (error) {
      setCheckingPermissions(false);
      setCameraAllowed(false);
      setCameraSupported(false);
    }
  };

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      askPermissions().then(() => {
        setCameraSupported(true);
      });
    } else {
      setCameraAllowed(false);
      setCheckingPermissions(false);
      setCameraSupported(false);
    }
  }, []);

  const handleSearch = async (assetNumber: string) => {
    setFetching(true);
    setResult(null);
    setHeading('');
    setAssetNotSupported(false);
    const [, data] = await to(getAccountByAssetId(assetNumber, true));
    setFetching(false);
    if (data && data.err) {
      return setError(data.err);
    }

    const login = await postLogin({ appId: Apps.COKE_SERVICES_APP, equipmentLookupTypeId: EquipmentLookupTypes.BARCODE_SCAN });
    if (login.res && login.res.login) {
      await setLoginId(login.res?.login?.id);
    }

    if (data && data.account && Object.values(data.account).length === 0) {
      setError({
        name: 'Error',
        message: 'Not Found'
      });
      return setHeading('Not Found');
    }
    // check for equipment types like Tea Urn, Card Reader etc...
    if (data?.equipment && filteredEquipmentOptions.includes(data.equipment.equipmentType)) {
      setAssetNotSupported(true);
      return setHeading('Not Supported');
    }

    setHeading('Results');
    setSelectedEquipment((data && data.equipment) as IEquipmentInner);
    return setResult((data && data.account) as IOutletInner);
  };

  useEffect(() => {
    removeLoginId();
  }, []);

  const { isFrench } = useContext(Language);

  const isNationalAccount =
    result?.marketingId &&
    result?.marketingId?.charAt(0) !== '2' &&
    (selectedEquipment?.equipmentTypeId === EquipmentTypes.Fountain ||
      selectedEquipment?.equipmentTypeId === EquipmentTypes.PostMix ||
      selectedEquipment?.equipmentTypeId === EquipmentTypes.PreMix);

  const { bottlersAccess } = useContext(BottlersAccess);
  const selectedBottler: IBottlerAccess | undefined = bottlersAccess && result ? bottlersAccess.find(access => access.bottlerId === result.bottlerId) : undefined;

  return (
    <>
      {!isMobile && !error && !result && <BarcodeExample />}
      {!isFetching && !result && !error && (
        <ScanWrapper ref={scannerRef}>
          {isCameraAllowed && !isCheckingPermissions && isCameraSupported && (
            <>
              <Box forwardedAs="canvas" className="drawingBuffer" position="absolute" width={{ xs: '100%', md: '640px' }} height={{ xs: '100%', md: '480px' }} />
              <Scanner
                isTorchOn={isTorchOn}
                hasTouchedTorch={hasTouchedTorch}
                scannerRef={scannerRef}
                onDetected={(scanResult: string) => {
                  handleSearch(scanResult);
                }}
              />
              {isShowingTroubleShoot && (
                <>
                  <Box backgroundColor="white" opacity="0.8" position="absolute" height="100%" width="100%" zIndex={2} />
                  <Box position="absolute" height="100%" marginRight={{ xs: '0', md: 'm' }} zIndex={2} padding={{ xs: '4rem 1rem', md: '4rem 0 0 0' }}>
                    {isMobile && (
                      <>
                        <Text textAlign="center" color="primary" fontSize="xxxl" marginTop="m">
                          Where is the Barcode?
                        </Text>
                        <BarcodeExample />
                      </>
                    )}
                    <Text textAlign="center" color="primary" fontSize="xxxl">
                      {isFrench ? 'Dépannage' : 'Troubleshooting'}
                    </Text>
                    <StyledList>
                      {isMobile && <li>To focus camera hold your finger on the area to focus on.</li>}
                      {deviceFeatures && deviceFeatures.torch && <li>For better lighting use the light button in the bottom right of the Camera view.</li>}
                      {isFrench ? (
                        <li>
                          Si vous ne parvenez pas à capturer le code-barre, essayez d'entrer manuellement le <Link to="/equipment-asset-number">numéro d'équipement</Link>
                        </li>
                      ) : (
                        <li>
                          If you are unable to capture the barcode, try to manually enter the <Link to="/equipment-asset-number">asset number</Link>
                        </li>
                      )}
                    </StyledList>
                  </Box>
                </>
              )}
              <StyledIconButton
                position="absolute"
                backgroundColor={isShowingTroubleShoot ? 'primary' : 'white'}
                width="x3"
                height="x3"
                borderRadius="50%"
                right={{ xs: '1rem', md: '2rem' }}
                top={{ xs: '1rem', md: '2rem' }}
                onClick={() => showTroubleShoot(!isShowingTroubleShoot)}
                zIndex={2}
                isShowingTroubleShoot={isShowingTroubleShoot}
                iconProps={{
                  iconName: isShowingTroubleShoot ? 'Cancel' : 'StatusCircleQuestionMark',
                  styles: {
                    root: {
                      fontSize: isShowingTroubleShoot ? '1.8rem' : '2.3rem',
                      color: isShowingTroubleShoot ? 'white' : '#e01b26'
                    }
                  }
                }}
              />
              {deviceFeatures && deviceFeatures.torch && (
                <StyledIconButton
                  position="absolute"
                  backgroundColor={isTorchOn ? 'primary' : 'white'}
                  width="x3"
                  height="x3"
                  borderRadius="50%"
                  right={{ xs: '1rem', md: '2rem' }}
                  bottom={{ xs: '1rem', md: '2rem' }}
                  zIndex={1}
                  onClick={() => {
                    turnOnTorch(!isTorchOn);
                    touchedTorch(true);
                  }}
                  isTorchOn={isTorchOn}
                  id="torch-button"
                  iconProps={{
                    iconName: 'Flashlight',
                    styles: {
                      root: {
                        transform: 'rotate(270deg)',
                        fontSize: '1.5rem',
                        color: isTorchOn ? 'white' : '#e01b26'
                      }
                    }
                  }}
                />
              )}
            </>
          )}
        </ScanWrapper>
      )}
      {result && (
        <Box padding={{ xs: '0 1rem', md: '0 1rem 0 0' }}>
          <AccountInformation accountInfo={result} type="scanned barcode" />
          <ButtonLink
            paddingTop="s"
            paddingBottom="s"
            display="block"
            textAlign="center"
            to={selectedBottler && selectedBottler.isActive && !isNationalAccount ? '/submit-request' : ''}
            color="white"
            marginTop="m"
            marginBottom="m"
            width="100%"
            backgroundColor="primary"
            onClick={(e: React.MouseEvent) => {
              if (selectedBottler && !selectedBottler.isActive) {
                e.preventDefault();
                return showDisabledAppModal(result);
              }
              if (isNationalAccount) {
                e.preventDefault();
                return setMessageModal(true);
              }
              setAccount(result as IOutletDynamic);
              setEquipment({
                ...selectedEquipment
              } as IEquipmentInner);
            }}
          >
            {isFrench ? 'Confirmer - Compte correct' : 'Confirm - Account Correct'}
          </ButtonLink>
          <ButtonLink
            to="/equipment-account-number"
            width="100%"
            backgroundColor="grayLighter"
            marginBottom="m"
            paddingTop="s"
            paddingBottom="s"
            display="block"
            textAlign="center"
            color="black"
          >
            {isFrench ? 'Compte incorrect - Recherche par numéro de compte' : 'Incorrect Account - Search By Account Number'}
          </ButtonLink>
        </Box>
      )}
      {(isCheckingPermissions || isFetching) && (
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" width="100%" height="x16">
          <Loader />
        </Box>
      )}
      {!isFetching && error && (
        <Box padding="0 1rem">
          <ErrorMessage height="x12">
            {isFrench
              ? 'Le code-barres qui a été scanné n’a pas été trouvé dans notre système. S’il vous plaît essayer'
              : 'The barcode that was scanned was not found in our system. Please try'}{' '}
            <TryAgain
              backgroundColor="white"
              color="primary"
              onClick={() => {
                setResult(null);
                setError(null);
                setHeading('');
              }}
            >
              {isFrench ? 'scanner à nouveau le code-barres' : 'scanning the barcode again'}
            </TryAgain>
            , {isFrench ? 'ou' : 'or'} <Link to="/">{isFrench ? 'sélectionnez une autre option' : 'select a different option'}</Link>{' '}
            {isFrench ? 'pour localiser l’équipement.' : 'to locate the equipment.'}
          </ErrorMessage>
          <Text textAlign="center" color="primary" fontSize="xxxl" marginBottom="m" marginTop="m">
            {isFrench ? 'Où se trouve le code-barres?' : 'Where is the Barcode?'}
          </Text>
          <BarcodeExample isColumn />
        </Box>
      )}
      {!isCameraSupported && !isCheckingPermissions && !isCameraAllowed && (
        <Box padding={{ xs: 'm' }}>
          {isFrench ? (
            <ErrorMessage title={isFrench ? 'L’accès à la caméra n’est pas autorisé' : 'Camera access is not granted'}>
              L'appareil ou le navigateur que vous utilisez n'a pas autorisé, l'application de service Coke, l'accès à l’utilisation de la caméra. L'accès à la caméra est requis
              pour la lecture de codes à barres. Veuillez mettre à jour les paramètres de l'appareil pour autoriser l'accès à la caméra ou{' '}
              <Link to="/">sélectionner une autre option</Link>
              pour localiser l'équipement.
            </ErrorMessage>
          ) : (
            <ErrorMessage title={isFrench ? 'L’accès à la caméra n’est pas autorisé' : 'Camera access is not granted'}>
              The device or browser you are using has not granted the Coke Service App access to use the camera. Camera access is required for barcode scanning. Please update
              device settings to allow camera access, or <Link to="/">select a different option</Link> to locate the equipment.
            </ErrorMessage>
          )}
        </Box>
      )}
      {!isCameraAllowed && !isCheckingPermissions && isCameraSupported && (
        <Box padding={{ xs: 'm' }}>
          <ErrorMessage title={isFrench ? 'Autorisations de caméra refusées' : 'Camera Permissions Denied'} iconName="Blocked">
            {isFrench
              ? 'Vous avez refusé l’accès à votre appareil photo. Veuillez mettre à jour vos paramètres pour autoriser l’accès à l’appareil photo, ou '
              : 'You declined access to your camera. Please update your settings to allow access to the camera, or'}{' '}
            <Link to="/">{isFrench ? 'sélectionner une autre option' : 'select a different option'}</Link> {isFrench ? 'pour localiser l’équipement.' : 'to locate the equipment.'}
          </ErrorMessage>
        </Box>
      )}
      {!isFetching && isAssetNotSupported && (
        <ErrorMessage>
          {isFrench
            ? 'Désolé, le numéro de ressource analysé n’est actuellement pas pris en charge par cette application.'
            : 'Sorry, the scanned asset number is not currently supported by this application.'}
        </ErrorMessage>
      )}
      {isCameraAllowed && !isCheckingPermissions && isCameraSupported && (
        <Modal showCloseButton={false} isBlocking={true} isOpen={isShowingMessage} onDismiss={() => showMessage(false)}>
          <Centered width={{ xs: 'auto', md: '18rem' }} padding="2rem 1rem" flexDirection="column" textAlign="center">
            <Text>
              {isFrench
                ? "Afin de vous assurer que la demande de service est traitée correctement, assurez-vous  de scanner uniquement l'équipement qui a besoin d'être réparé."
                : 'In order to ensure the service request is processed correctly, be sure to only scan the piece of equipment that is needing repair.'}
            </Text>
            <Button minWidth="x4" marginTop="m" backgroundColor="primary" color="white" onClick={() => showMessage(false)}>
              OK
            </Button>
          </Centered>
        </Modal>
      )}
      <NationalAccountModal isOpen={showMessageModal} handleClose={() => setMessageModal(false)} />
      <DisabledAppModal isOpen={!!selectedOutlet} selectedBottler={selectedBottler} selectedOutlet={selectedOutlet} handleClose={() => showDisabledAppModal(null)} />
    </>
  );
};

const ScanWrapper = styled.div(
  breakpoints({
    xs: css`
      position: relative;
      overflow: hidden;
      video {
        width: auto;
        height: 40rem;
      }
    `,
    lg: css`
      video {
        width: 600px;
        height: auto;
        margin-left: 0;
      }
    `
  })
);

const StyledIconButton = styled(IconButton)`
  &:hover {
    background-color: ${(p: any) => (p.isTorchOn || p.isShowingTroubleShoot ? p.theme.colors.primary : 'white')};
  }
`;

const TryAgain = styled(Button)`
  padding: 0;

  &:hover {
    background-color: white;
    text-decoration: underline;
  }
`;

const StyledList = styled.ul(
  breakpoints({
    xs: css`
      padding-inline-start: 20px;
    `,
    md: css`
      padding-inline-start: 40px;
    `
  })
);
