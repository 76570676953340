import React, { FC, useState } from 'react';
import styled, { Box } from '@xstyled/styled-components';
import { useMedia } from 'react-use';
import { Panel } from '@fluentui/react';
// components
import {
  ButtonExample,
  CheckboxExample,
  DropdownExample,
  IconExample,
  ImageExample,
  LabelExample,
  ListExample,
  TextExample,
  LinkExample,
  TextFieldExample
} from '../components/kitchen-sink';
import { Text, ExternalLink, Icon } from '../components/common';
import { Nav } from '../components/skeleton/Nav';
import { Page } from '../components/skeleton/Page';

export const KitchenSink: FC = () => {
  const [type, setType] = useState<string>('All');
  const [isOpen, togglePanel] = useState<boolean>(false);
  const isTabletOrDesktop = useMedia('(min-width: 768px)');
  const renderComponentName = (name: string) => ({
    key: name,
    name: name,
    url: '',
    isSelected: name === type,
    onClick: () => {
      setType(name);
      isOpen && togglePanel(false);
    }
  });
  const components = [
    renderComponentName('All'),
    renderComponentName('Button'),
    renderComponentName('Checkbox'),
    renderComponentName('Dropdown'),
    renderComponentName('Icon'),
    renderComponentName('Image'),
    renderComponentName('Label'),
    renderComponentName('Link'),
    renderComponentName('Text'),
    renderComponentName('TextField')
  ];
  return (
    <>
      <Page
        firstCol={
          <>
            {isTabletOrDesktop ? (
              <Nav
                ariaLabel="Navigation"
                groups={[
                  {
                    links: components
                  }
                ]}
              />
            ) : null}
          </>
        }
        secCol={
          <Box padding={{ xs: 'm', md: 'x2' }} paddingLeft={{ xs: 's', md: 'm' }}>
            <Box display="flex" justifyContent={{ xs: 'space-between', md: 'flex-start' }} alignItems="center">
              <Text forwardedAs="h1" marginBottom="m">
                Kitchen Sink
              </Text>
              {!isTabletOrDesktop && (
                <>
                  <IconButton onClick={() => togglePanel(!isOpen)} alignSelf="flex-start">
                    <Icon iconName="BulletedListBulletMirrored" fontSize="xxxl" fontWeight="bold" />
                  </IconButton>
                </>
              )}
            </Box>
            <Text marginBottom="xs">UI React Components</Text>
            <Text>
              Components are built on top of the <ExternalLink href="https://developer.microsoft.com/en-us/fabric#/controls/web">Mircosoft Office UI Framework</ExternalLink>
            </Text>
            {type === 'All' && (
              <>
                <ButtonExample />
                <CheckboxExample />
                <DropdownExample />
                <IconExample />
                <ImageExample />
                <LabelExample />
                <LinkExample />
                <ListExample />
                <TextExample />
                <TextFieldExample />
              </>
            )}
            {type === 'Button' && <ButtonExample />}
            {type === 'Checkbox' && <CheckboxExample />}
            {type === 'Dropdown' && <DropdownExample />}
            {type === 'Icon' && <IconExample />}
            {type === 'Image' && <ImageExample />}
            {type === 'Label' && <LabelExample />}
            {type === 'Link' && <LinkExample />}
            {type === 'List' && <ListExample />}
            {type === 'Text' && <TextExample />}
            {type === 'TextField' && <TextFieldExample />}
          </Box>
        }
      />
      <Panel isLightDismiss isOpen={isOpen} onDismiss={() => togglePanel(false)} closeButtonAriaLabel="Close" styles={{ main: { width: '300px' } }}>
        <Nav
          ariaLabel="Navigation"
          groups={[
            {
              links: components
            }
          ]}
        />
      </Panel>
    </>
  );
};

const IconButton = styled.buttonBox`
  border: 0;
  background: 0;
  outline: 0;
  cursor: pointer;
`;
