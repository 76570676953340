import React, { FC, useState, HTMLAttributes, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { addMonths } from 'date-fns';
import { breakpoints } from '@xstyled/system';
import { darken } from 'polished';
// @ts-ignore
import styled, { css } from '@xstyled/styled-components';
// components
import { Text, Button } from '../common';
import { PrivacyPolicyModal } from '../privacy-policy/PrivacyPolicyModal';
import { Language } from '../../context/language';

interface IPrivacyBannerProps extends HTMLAttributes<HTMLDivElement> {}

export const PrivacyBanner: FC<IPrivacyBannerProps> = ({ id }) => {
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const [showPrivacyModal, setPrivacyModal] = useState<boolean>(false);
  const [, setCookie] = useCookies();
  const { isFrench } = useContext(Language);
  return (
    <>
      {showBanner && (
        <Wrapper>
          <Text flex={1} marginBottom={{ xs: 's', sm: 0 }}>
            {isFrench
              ? 'Nous utilisons des cookies sur ce site pour améliorer votre expérience. Pour en savoir plus, consultez notre'
              : 'We use cookies on this site to improve your experience. To find out more, review our'}{' '}
            <PrivacyButton onClick={() => setPrivacyModal(true)}>{isFrench ? 'Politique de confidentialité' : 'Privacy Policy'}</PrivacyButton>.
          </Text>
          <Button
            backgroundColor="primary"
            color="white"
            onClick={() => {
              setCookie('PrivacyBanner', 'true', { expires: addMonths(new Date(), 1) });
              setShowBanner(false);
            }}
            id="accept-privacy"
            width={{ xs: '100%', sm: 'auto' }}
          >
            {isFrench ? 'Accepter' : 'Accept'}
          </Button>
        </Wrapper>
      )}
      <PrivacyPolicyModal isOpen={showPrivacyModal} handleClose={() => setPrivacyModal(false)} />
    </>
  );
};

const Wrapper = styled.box(
  breakpoints({
    xs: css`
      display: flex;
      align-items: center;
      background-color: white;
      position: fixed;
      flex-direction: column;
      text-align: center;
      bottom: 0;
      z-index: 1000;
      max-width: 1000px;
      width: calc(100% - 60px);
      left: 50%;
      margin: 0 0 16px;
      transform: translateX(-50%);
      padding: m;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06);
      border: basicLight;
      border-radius: m;
    `,
    sm: css`
      flex-direction: row;
      text-align: left;
      margin: 0 0 70px;
    `
  })
);

const PrivacyButton = styled(Button)`
  padding: 0;
  color: primary;
  :hover {
    background-color: transparent;
    color: ${(p: any) => darken(0.05, p.theme.colors.primary)};
  }
`;
