import React, { FC, useContext, useState } from 'react';
// components
import { LookupContent } from '../components/lookup-service-request';
import { Page, PageTitle } from '../components/skeleton';
import { Box } from '@xstyled/styled-components';
// hoc
import { withTracker } from '../services/ga';
import { Language } from '../context/language';

const LookupServiceRequest: FC = () => {
  const [heading, setHeading] = useState<string>('');
  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  return (
    <Page
      secCol={
        <Box padding={{ xs: 'm', md: '0' }} paddingTop="0" paddingBottom="0">
          <PageTitle>
            {isFrench ? 'Rechercher une demande ' : 'Lookup Request '} {heading ? `- ${heading}` : ''}
          </PageTitle>
          <LookupContent setHeading={setHeading} />
        </Box>
      }
    />
  );
};

export default withTracker(LookupServiceRequest);
