import React, { FC, Fragment } from 'react';
import { useMedia } from 'react-use';
// components
import { Button, Text, Centered, Modal } from '../common';
import { Box } from '@xstyled/styled-components';
// models
import { IOutletInner, IBottlerAccess } from '../../models';

interface IDisabledAppModal {
  isOpen: boolean;
  handleClose: () => void;
  selectedBottler: IBottlerAccess | undefined;
  selectedOutlet: IOutletInner | null;
}

export const DisabledAppModal: FC<IDisabledAppModal> = ({ isOpen, handleClose, selectedBottler, selectedOutlet }) => {
  const isDesktop = useMedia('(min-width: 500px)');
  const PhoneNumberWrapper = isDesktop ? Fragment : 'a';
  const phoneNumber = selectedBottler?.bottler.phoneNumber ? selectedBottler.bottler.phoneNumber : selectedOutlet?.phoneNumber ? selectedOutlet.phoneNumber : null;
  return (
    <Modal isOpen={isOpen} onDismiss={handleClose} containerStyles={{ width: '23rem' }}>
      <Centered flexDirection="column" padding="x1" paddingTop="x3" paddingBottom="x3">
        <Text>
          This account is not currently supported by this application. Please call your local customer service{' '}
          {phoneNumber && (
            <>
              at <PhoneNumberWrapper {...(!isDesktop ? { href: `tel:${phoneNumber}` } : {})}>{phoneNumber}</PhoneNumberWrapper>,{' '}
            </>
          )}
          for further support.
        </Text>
        <Box marginTop="x2" display="flex" alignItems="center" justifyContent="center">
          <Button marginLeft="m" onClick={handleClose} backgroundColor="primary" color="white">
            Ok
          </Button>
        </Box>
      </Centered>
    </Modal>
  );
};
