import React, { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import to from 'await-to-js';
// components
import { Loader } from '../components/common';
import { Page, PageTitle } from '../components/skeleton';
import { Box } from '@xstyled/styled-components';
import { AccountInformation } from '../components/account-number';
import {
  EquipmentInformation,
  AdditionalInformation,
  ContactInformation,
  AvailableServiceTimes,
  SubmitRequestConfirmation,
  SubmitRequestErrorModal
} from '../components/submit-request';
// hooks
import { useGlobalState } from '../stateManagement';
import { EquipmentTypes, IEquipmentInner, ISubmitServiceRequest } from '../models';
// helpers
import { states, phoneRegExp, isCanadaProv } from '../helpers';
// fetch
import { createServiceRequest } from '../fetch';
import { getLoginId, removeLoginId, withTracker } from '../services';
import { putLogin } from '../fetch/login';
// models
import { ContactRoles } from '../models';
import { Language } from '../context/language';

export interface IEquipmentFormValues {
  locationName: string | null;
  street: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface IAddtionalInfoFormValues {
  info: string;
  problem: string;
}

export interface IContactInfoFormValues {
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  contactRole: string;
  contactNotifications: string[];
  employeeName: string;
  employeeEmail: string;
  employeePhone: string;
  employeeNotifications: string[];
}

export interface IAvailableServiceTimesFormValues {
  days: string[];
  fromTime: string;
  toTime: string;
  toTimeFrame: string;
  fromTimeFrame: string;
  anyHour: boolean;
  selectedServiceOption: string;
  useTs4?: boolean;
}

export type ISubmitRequestFormValues = IAddtionalInfoFormValues & IEquipmentFormValues & IContactInfoFormValues & IAvailableServiceTimesFormValues;

const schema = {
  street: Yup.string()
    .max(255, 'Max 255 characters')
    .required('Required'),
  state: Yup.string()
    .max(255, 'Max 255 characters')
    .required('Required'),
  city: Yup.string()
    .max(255, 'Max 255 characters')
    .required('Required'),
  zipCode: Yup.string()
    .required('Required')
    .when('state', {
      is: val => isCanadaProv(val),
      // matches N9B 3P4
      then: Yup.string().matches(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i, 'Invalid'),
      otherwise: Yup.string().matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Invalid')
    }),
  problem: Yup.string().required('Required'),
  info: Yup.string()
    .max(255, 'Max 255 characters')
    .required('Required'),
  employeeName: Yup.mixed().when('contactRole', {
    is: (val?: string) => val && val === ContactRoles.EMPLOYEE,
    then: Yup.string()
      .max(255, 'Max 255 characters')
      .required('Required'),
    otherwise: Yup.string().notRequired()
  }),
  employeeEmail: Yup.mixed().when('contactRole', {
    is: (val?: string) => val && val === ContactRoles.EMPLOYEE,
    then: Yup.string()
      .required('Required')
      .email('Invalid'),
    otherwise: Yup.string().email('Invalid')
  }),
  employeePhone: Yup.mixed().when('contactRole', {
    is: (val?: string) => val && val === ContactRoles.EMPLOYEE,
    then: Yup.string()
      .matches(phoneRegExp, 'Invalid')
      .required('Required'),
    otherwise: Yup.string().notRequired()
  }),
  employeeNotifications: Yup.mixed().when('contactRole', {
    is: (val?: string) => val && val === ContactRoles.EMPLOYEE,
    then: Yup.array()
      .of(Yup.string())
      .required('Required'),
    otherwise: Yup.array()
  }),
  contactFirstName: Yup.string()
    .max(255, 'Max 255 characters')
    .required('Required'),
  contactLastName: Yup.string()
    .max(255, 'Max 255 characters')
    .required('Required'),
  contactEmail: Yup.mixed().when('contactNotifications', {
    is: (val: string[]) => val.includes('Email'),
    then: Yup.string()
      .required('Required')
      .email('Invalid'),
    otherwise: Yup.string().email('Invalid')
  }),
  contactPhone: Yup.string()
    .matches(phoneRegExp, 'Invalid')
    .required('Required'),
  contactRole: Yup.string().required('Required'),
  contactNotifications: Yup.mixed().when('contactRole', {
    is: (val?: string) => val && val === ContactRoles.EMPLOYEE,
    then: Yup.array(),
    otherwise: Yup.array()
      .of(Yup.string())
      .required('Required')
  })
};

const submitRequestSchemaWithLocationName = Yup.object().shape({
  locationName: Yup.string()
    .max(255, 'Max 255 characters')
    .required('Required'),
  ...schema
});

const submitRequestSchema = Yup.object().shape(schema);

const SubmitRequest: FC = () => {
  // hooks
  const { account, equipment, isAccountFlow } = useGlobalState();
  const selectedState = states.find((state: { key: string; text: string }) => state.key === account?.state);
  const isFLO = (account?.subTradeChannel && account?.subTradeChannel.toLowerCase() === 'full line operator') || false;
  const equipmentInitialFormValues = {
    locationName: '',
    street: account?.street || '',
    city: account?.city || '',
    state: selectedState?.key || '',
    zipCode: account?.postalCode || ''
  };
  // state
  const [isEquipmentConfirmed, setEquipmentConfirmed] = useState<boolean>(isAccountFlow ? true : false);
  const [isAdditionalInfoConfirmed, setAdditionalInfoConfirmed] = useState<boolean>(false);
  const [isContactInfoConfirmed, setContactInfoConfirmed] = useState<boolean>(false);
  const [isEquipmentForm, setEquipmentForm] = useState<boolean>(isFLO || false);
  // hooks
  const history = useHistory();

  const { isFrench } = useContext(Language);

  if (!account) {
    history.replace('/');
    return null;
  }
  return (
    <Page
      secCol={
        <Box padding={{ xs: 'm', md: '0' }} paddingTop="0" paddingBottom="0">
          <Formik
            initialValues={{
              info: '',
              problem: '',
              employeeName: '',
              employeeEmail: '',
              employeePhone: '',
              employeeNotifications: [],
              contactEmail: '',
              contactFirstName: '',
              contactLastName: '',
              contactPhone: '',
              contactRole: '',
              contactNotifications: [],
              days: [],
              fromTime: '',
              toTime: '',
              toTimeFrame: 'AM',
              fromTimeFrame: 'AM',
              anyHour: false,
              selectedServiceOption: 'Mon-Fri',
              ...equipmentInitialFormValues
            }}
            onSubmit={async (values: ISubmitRequestFormValues, actions: FormikHelpers<ISubmitRequestFormValues | any>): Promise<void> => {
              actions.setSubmitting(true);

              const request: ISubmitServiceRequest = {
                additionalChargeConsent: true,
                description: values.info,
                availabilityDays: values.days.length > 0 ? values.days.join(', ') : 'Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday',
                availabilityHoursEnd: values.toTime ? `${values.toTime}${values.toTimeFrame.toLowerCase()}` : '12:00am',
                availabilityHoursStart: values.fromTime ? `${values.fromTime}${values.fromTimeFrame.toLowerCase()}` : '11:59pm',
                available24x7: values.anyHour,
                equipmentId: equipment?.equipmentId,
                equipmentProblemId: Number(values.problem),
                equipmentTypeId: equipment?.equipmentTypeId || EquipmentTypes.YourAccount,
                outletId: account.id,
                language: isFrench ? 'French' : 'English',
                requestContact: {
                  email: values.contactEmail,
                  firstName: values.contactFirstName,
                  lastName: values.contactLastName,
                  emailNotification: values.contactNotifications.includes('Email') || false,
                  textNotification: values.contactNotifications.includes('Text') || false,
                  phoneNotification: false,
                  contactRoleId: Number(values.contactRole),
                  phone: values.contactPhone,
                  communicationConsent: true
                },
                requestEmployee:
                  values.contactRole === ContactRoles.EMPLOYEE
                    ? {
                        communicationConsent: true,
                        employeeEmail: values.employeeEmail,
                        employeeName: values.employeeName,
                        emailNotification: values.employeeNotifications.includes('Email') || false,
                        textNotification: values.employeeNotifications.includes('Text') || false,
                        employeePhone: values.employeePhone
                      }
                    : null,
                requestEquipmentLocation: {
                  city: values.city,
                  postalCode: values.zipCode,
                  state: values.state,
                  street: values.street,
                  locationName: values.locationName
                }
              };

              const [, data] = await to(createServiceRequest(request));

              actions.setSubmitting(false);

              if (data && data.err) {
                const message = data.err?.message.includes('409')
                  ? isFrench
                    ? 'Une demande pour cette pièce d’équipement a déjà été soumise au cours des dernières 24 heures.'
                    : 'A request for this piece of equipment has already been submitted within the last 24 hours.' // NOTE: we look for "24 hours" text on SubmitRequestErrorModal component
                  : isFrench
                  ? 'Une erreur s’est produite lors de la tentative d’envoi de votre demande de service.'
                  : 'An error was encountered when trying to submit your service request.';

                actions.setStatus({
                  type: 'error',
                  message
                });
              }

              if (data && data.res && data.res.equipmentRepairRequest) {
                const loginId = await getLoginId();
                if (loginId) {
                  putLogin(loginId as number, { bottlerId: account.bottlerId, requestId: data.res.equipmentRepairRequest.id });
                  removeLoginId();
                }

                actions.setStatus({
                  type: 'success',
                  message: data.res.equipmentRepairRequest.equipmentRepairTicketNumber,
                  bottler: data.res.equipmentRepairRequest.bottler,
                  sla: data.res.equipmentRepairRequest.sla
                });
              }
            }}
            validationSchema={isFLO && !isAccountFlow ? submitRequestSchemaWithLocationName : submitRequestSchema}
          >
            {({ setFieldValue, values, errors, resetForm, status, setStatus, isSubmitting }) => {
              return (
                <>
                  <Box position="relative">
                    {isSubmitting && (
                      <>
                        <Box position="absolute" top="0" height="100%" width="100%" zIndex="1" background="white" opacity="0.6" />
                        <Loader position="absolute" zIndex="2" top="25%" left="45%" />
                      </>
                    )}
                    <PageTitle>
                      {status && status.type === 'success' ? (isFrench ? 'Demande soumise' : 'Request Submitted') : isFrench ? 'Soumettre une demande' : 'Submit Request'}
                    </PageTitle>
                    <Box marginRight={{ xs: '0', md: '1.25rem' }}>
                      {(!status || (status && status.type === 'error')) && (
                        <>
                          <AccountInformation accountInfo={account} withConfirmation={false} />
                          <Form>
                            {!isAccountFlow && (
                              <EquipmentInformation
                                handleToggle={() => setEquipmentForm(true)}
                                equipment={equipment as IEquipmentInner}
                                isEquipmentConfirmed={isEquipmentConfirmed}
                                setEquipmentConfirmed={() => {
                                  if (!values.locationName) {
                                    setFieldValue('locationName', null);
                                  }
                                  setEquipmentConfirmed(true);
                                }}
                                setEquipmentForm={(val: boolean) => setEquipmentForm(val)}
                                isFLO={isFLO}
                                isEquipmentForm={isEquipmentForm}
                                errors={{
                                  locationName: errors.locationName,
                                  street: errors.street,
                                  city: errors.city,
                                  state: errors.state,
                                  zipCode: errors.zipCode
                                }}
                                setFieldValue={setFieldValue}
                                values={values}
                                resetForm={resetForm}
                                equipmentInitialFormValues={equipmentInitialFormValues}
                              />
                            )}
                            {isEquipmentConfirmed && (
                              <ContactInformation
                                isContactInfoConfirmed={isContactInfoConfirmed}
                                setContactInfoConfirmed={() => setContactInfoConfirmed(true)}
                                errors={
                                  isEquipmentConfirmed
                                    ? ({
                                        street: errors.street,
                                        city: errors.city,
                                        state: errors.state,
                                        zipCode: errors.zipCode,
                                        contactEmail: errors.contactEmail,
                                        contactFirstName: errors.contactFirstName,
                                        contactLastName: errors.contactLastName,
                                        contactPhone: errors.contactPhone,
                                        contactRole: errors.contactRole,
                                        contactNotifications: errors.contactNotifications,
                                        employeeName: errors.employeeName,
                                        employeeEmail: errors.employeeEmail,
                                        employeePhone: errors.employeePhone,
                                        employeeNotifications: errors.employeeNotifications
                                      } as any)
                                    : errors
                                }
                                currentFormErrors={
                                  {
                                    contactEmail: errors.contactEmail,
                                    contactFirstName: errors.contactFirstName,
                                    contactLastName: errors.contactLastName,
                                    contactPhone: errors.contactPhone,
                                    contactRole: errors.contactRole,
                                    contactNotifications: errors.contactNotifications,
                                    employeeName: errors.employeeName,
                                    employeeEmail: errors.employeeEmail,
                                    employeePhone: errors.employeePhone,
                                    employeeNotifications: errors.employeeNotifications
                                  } as any
                                }
                                isEmployeeRole={values.contactRole === ContactRoles.EMPLOYEE}
                                values={values}
                                setEmployeeEmail={(val: string) => setFieldValue('employeeEmail', val)}
                                setContactRole={(val: string) => setFieldValue('contactRole', val)}
                              />
                            )}
                            {isEquipmentConfirmed && isContactInfoConfirmed && (
                              <AdditionalInformation
                                isAdditionalInfoConfirmed={isAdditionalInfoConfirmed}
                                setAdditionalInfoConfirmed={() => setAdditionalInfoConfirmed(true)}
                                values={values}
                                isEmployeeRole={values.contactRole === ContactRoles.EMPLOYEE}
                                errors={
                                  isEquipmentConfirmed && isContactInfoConfirmed
                                    ? {
                                        street: errors.street,
                                        city: errors.city,
                                        state: errors.state,
                                        zipCode: errors.zipCode,
                                        info: errors.info,
                                        problem: errors.problem,
                                        contactEmail: errors.contactEmail,
                                        contactFirstName: errors.contactFirstName,
                                        contactLastName: errors.contactLastName,
                                        contactPhone: errors.contactPhone,
                                        contactRole: errors.contactRole,
                                        contactNotifications: errors.contactNotifications,
                                        employeeName: errors.employeeName,
                                        employeeEmail: errors.employeeEmail,
                                        employeePhone: errors.employeePhone,
                                        employeeNotifications: errors.employeeNotifications
                                      }
                                    : errors
                                }
                                currentFormErrors={{
                                  info: errors.info,
                                  problem: errors.problem
                                }}
                              />
                            )}
                            {isEquipmentConfirmed && isAdditionalInfoConfirmed && isContactInfoConfirmed && (
                              <AvailableServiceTimes errors={errors} values={values} setFieldValue={setFieldValue} />
                            )}
                          </Form>
                        </>
                      )}
                      {status && status.type === 'success' && <SubmitRequestConfirmation ticketNumber={status.message} bottler={status.bottler} sla={status.sla} />}
                      {status && status.type === 'error' && <SubmitRequestErrorModal isOpen={true} handleClose={() => setStatus(null)} message={status.message} />}
                    </Box>
                  </Box>
                </>
              );
            }}
          </Formik>
        </Box>
      }
    />
  );
};

export default withTracker(SubmitRequest);
