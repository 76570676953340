import React, { FC, useContext, useEffect } from 'react';
import { isMobileSafari, isIOS, isIPad13, isIOS13, isIPhone13, isIPod13 } from 'react-device-detect';
import ReactGA from 'react-ga';
import { differenceInMinutes } from 'date-fns';
// components
import { Text, IconMessage, ButtonLink, Centered, Link, Icon } from '../common';
// models
import { ISubmitServiceRequestBottler } from '../../models';
// services
import { TIMER_KEY, getLocalForageItem, logEvent } from '../../services';
import { Language } from '../../context/language';

interface ISubmitRequestConfirmationProps {
  ticketNumber: string | null;
  bottler: ISubmitServiceRequestBottler;
  sla: number;
}

export const SubmitRequestConfirmation: FC<ISubmitRequestConfirmationProps> = ({ ticketNumber, bottler, sla }) => {
  const sendEvents = async () => {
    try {
      const startTime = await getLocalForageItem(TIMER_KEY);
      if (startTime) {
        const timeToComplete = differenceInMinutes(new Date(), startTime as Date);
        ReactGA.event({
          category: 'Equipment Request',
          action: 'Submitted',
          label: `Bottler Name: ${bottler.name} / Time to Complete: ${timeToComplete}min`
        });
      }
    } catch (error) {
      logEvent({ event: error, location: 'Error sending google event' });
    }
  };

  useEffect(() => {
    sendEvents();
    // eslint-disable-next-line
  }, []);

  // isMobileSafari doesn't show on iPad's during testing on an iPad simulator (which is a requirement)
  // in order to show the "Add To Home Screen" we'll check for anything that's an iOS-type device
  // react-device-detect has a lot of checks for this
  const isIOSDevice = isMobileSafari || isIOS || isIPad13 || isIOS13 || isIPhone13 || isIPod13;

  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  return (
    <>
      <IconMessage iconName="SkypeCircleCheck" height={isIOSDevice ? '28rem' : '20rem'}>
        <Text forwardedAs="span" textAlign="center" display="block" marginTop="m">
          {isFrench
            ? 'Votre demande a été soumise avec succès. Si vous avez sélectionné le texto ou le courriel comme paramètre de notification, vous recevrez un message de confirmation sous peu.'
            : 'Your request was submitted successfully. If you selected text or email as a notification setting, then you will receive a confirmation message shortly.'}
        </Text>

        {isFrench ? (
          <Text forwardedAs="span" textAlign="center" display="block" marginTop="m">
            {ticketNumber && (
              <>
                Votre numéro de billet est le <Link to={`/lookup-service-request?ticketNumber=${ticketNumber}`}>{ticketNumber}</Link>.{' '}
              </>
            )}
            . Vous devriez recevoir votre service ou serez contacté au sujet de votre demande dans les {sla} heures.
          </Text>
        ) : (
          <Text forwardedAs="span" textAlign="center" display="block" marginTop="m">
            {ticketNumber && (
              <>
                Your ticket number is <Link to={`/lookup-service-request?ticketNumber=${ticketNumber}`}>{ticketNumber}</Link>.{' '}
              </>
            )}
            You should receive service, or will be contacted regarding your request, within {sla} hours.
          </Text>
        )}

        {isIOSDevice && (
          <Text forwardedAs="span" textAlign="center" display="block" marginTop="m">
            Add a shortcut to this application on your home screen for quick and easy access in the future. Just tap{' '}
            <Icon fontSize="xl" color="#147EFB" iconName="ShareiOS" marginLeft="xxs" marginRight="xxs" marginTop={-5} /> then &ldquo;Add to Home Screen&rdquo;.
          </Text>
        )}
      </IconMessage>
      <Centered>
        <ButtonLink
          paddingTop="s"
          paddingBottom="s"
          width="x12"
          display="block"
          textAlign="center"
          color="white"
          marginTop={{ xs: 'm', md: 's' }}
          marginBottom="m"
          backgroundColor="primary"
          to="/"
        >
          {isFrench ? 'Soumettre une autre demande' : 'Submit Another'}
        </ButtonLink>
      </Centered>
    </>
  );
};
