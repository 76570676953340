import React from 'react';
import { Box } from '@xstyled/styled-components';
// components
import { Dropdown, Text } from '../common';
import { CopyExample } from '../kitchen-sink/Copy';

export const DropdownExample = () => {
  return (
    <Box marginTop="x1" marginBottom="x1">
      <hr />
      <Text forwardedAs="h2" marginBottom="s">
        Dropdown
      </Text>
      <Dropdown
        placeholder="Select a role"
        label="Role"
        required
        marginTop="s"
        options={[
          {
            key: 'test',
            text: 'Manager'
          },
          {
            key: 'test2',
            text: 'Manager2'
          },
          {
            key: 'test3',
            text: 'Manager3'
          }
        ]}
      />
      <CopyExample>
        {`
                <Dropdown
                  placeholder="Select a role"
                  label="Role"
                  required
                  marginTop="s"
                  options={[
                    {
                      key: 'test',
                      text: 'Manager'
                    },
                    {
                      key: 'test2',
                      text: 'Manager2'
                    },
                    {
                      key: 'test3',
                      text: 'Manager3'
                    }
                  ]}
                />
                `}
      </CopyExample>
    </Box>
  );
};
