import React, { FC, useContext } from 'react';
// @ts-ignore
import styled, { css, Box } from '@xstyled/styled-components';
import { breakpoints } from '@xstyled/system';
import { Link } from '../common';
import { Language } from '../../context/language';

export const Footer: FC = () => {
  const { isFrench } = useContext(Language);

  return (
    <Box forwardedAs="footer" position="relative" width="100%" bottom={{ xs: '', md: '0' }} backgroundColor="white">
      <Wrapper>&copy; {new Date().getFullYear()} The Coca-Cola Company.</Wrapper>
      <LinksWrapper>
        <Link to="/equipment-request" marginRight="xs">
          {isFrench ? 'Demande d’équipement' : 'Equipment Request'}
        </Link>
        <span> | </span>
        <Link to="/account-request" marginLeft="xs" marginRight="xs">
          {isFrench ? 'Demande de compte' : 'Account Request'}
        </Link>
        <span> | </span>
        <Link to="/lookup-service-request" marginLeft="xs">
          {isFrench ? 'Rechercher une demande' : 'Lookup Request'}
        </Link>
      </LinksWrapper>
    </Box>
  );
};

const Wrapper = styled.div(
  breakpoints({
    xs: css`
      position: absolute;
      bottom: 0;
      padding: m m;
      font-size: xs;
    `,
    md: css`
      padding: x1;
    `
  })
);

const LinksWrapper = styled.div(
  breakpoints({
    xs: css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: x1 0;
      margin-bottom: x1;
      font-size: s;
    `,
    md: css`
      width: 100%;
      flex-direction: row;
      margin-bottom: 0;
      font-size: m;
    `
  })
);
