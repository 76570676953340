import React from 'react';
import { Box } from '@xstyled/styled-components';
// components
import { Text } from '../common';
import { CopyExample } from '../kitchen-sink/Copy';

export const TextExample = () => {
  return (
    <Box marginTop="x1" marginBottom="x1">
      <hr />
      <Text forwardedAs="h2" marginBottom="s">
        Text
      </Text>
      <Text forwardedAs="h1">Heading 1</Text>
      <CopyExample>{`<Text forwardedAs="h1">Heading 1</Text>`}</CopyExample>
      <Text forwardedAs="h2">Heading 2</Text>
      <CopyExample>{`<Text forwardedAs="h2">Heading 2</Text>`}</CopyExample>
      <Text forwardedAs="h3">Heading 3</Text>
      <CopyExample>{`<Text forwardedAs="h3">Heading 3</Text>`}</CopyExample>
      <Text forwardedAs="h4">Heading 4</Text>
      <CopyExample>{`<Text forwardedAs="h4">Heading 4</Text>`}</CopyExample>
      <Text forwardedAs="h5">Heading 5</Text>
      <CopyExample>{`<Text forwardedAs="h5">Heading 5</Text>`}</CopyExample>
      <Text forwardedAs="h6">Heading 6</Text>
      <CopyExample>{`<Text forwardedAs="h6">Heading 6</Text>`}</CopyExample>
      <Text>Paragraph</Text>
      <CopyExample>{`<Text>Paragraph</Text>`}</CopyExample>
    </Box>
  );
};
