import React, { FC, useContext } from 'react';
import { Box } from '@xstyled/styled-components';
import { isEqual } from 'lodash';

import { Expand, Text, Label } from '../common';
import { ILookupServiceRequest } from '../../models';
import { formatAccountNumber } from '../../helpers';
import { Language } from '../../context/language';

interface ILookupInformation {
  info: ILookupServiceRequest;
  isExpanded: boolean;
}

export const LookupInformation: FC<ILookupInformation> = ({ info, isExpanded }) => {
  const MonToFri = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

  const { isFrench } = useContext(Language);
  const isAccountFlow = info.equipmentType === 'Your Account';

  return (
    <>
      <Expand isExpanded={isExpanded} showExpandButton={true}>
        <Text forwardedAs="h3" marginBottom="m">
          {isFrench ? 'Information du billet' : 'Ticket Information'}
        </Text>
        <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '15rem' }}>
            <Label>{isFrench ? 'NUMÉRO DU BILLET' : 'Ticket Number'}</Label>
            <Text fontSize="s">{info.equipmentRepairTicketNumber}</Text>
          </Box>
          {info.ticketStatusDescription && (
            <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '9rem' }}>
              <Label>{isFrench ? 'STATUT DU BILLET' : 'Ticket Status'}</Label>
              <Text fontSize="s">
                {info.ticketStatusDescription}
                {info.ticketStatusCode && (info.ticketStatusCode === 'SCH' || info.ticketStatusCode === 'HOLD') ? '*' : ''}
              </Text>
            </Box>
          )}
        </Box>
        {info.ticketStatusCode && (info.ticketStatusCode === 'SCH' || info.ticketStatusCode === 'HOLD') ? (
          <Text marginTop="s" fontSize="xs">
            *You will be notified once the technician is en route to the equipment location.
          </Text>
        ) : (
          ''
        )}
      </Expand>
      <Expand isExpanded={isExpanded} showExpandButton={true}>
        <Text forwardedAs="h3" marginBottom="m">
          {isFrench ? 'Information du compte' : 'Account Information'}
        </Text>
        <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '15rem' }}>
            <Label>{isFrench ? 'Nom' : 'Name'}</Label>
            <Text fontSize="s">{info.outletName}</Text>
          </Box>
          <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '9rem' }}>
            <Label>{isFrench ? '#Compte' : 'Account#'}</Label>
            <Text fontSize="s">{formatAccountNumber(info.outletNumber)}</Text>
          </Box>
          {info.phoneNumber && (
            <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '7rem' }}>
              <Label>{isFrench ? '#TÉLÉPHONE' : 'Phone#'}</Label>
              <Text fontSize="s">{info.phoneNumber}</Text>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box marginBottom={{ xs: 's', md: '0' }} width={{ xs: 'auto', md: '14rem' }}>
            <Label>{isFrench ? 'Rue' : 'Street'}</Label>
            <Text fontSize="s">{info.street}</Text>
          </Box>
          <Box marginBottom={{ xs: 's', md: '0' }} width={{ xs: 'auto', md: '7rem' }}>
            <Label>{isFrench ? 'Ville' : 'City'}</Label>
            <Text fontSize="s">{info.city}</Text>
          </Box>
          <Box marginBottom={{ xs: 's', md: '0' }} width={{ xs: 'auto', md: '7rem' }}>
            <Label>{isFrench ? 'Province' : 'State/Prov'}</Label>
            <Text fontSize="s">{info.state}</Text>
          </Box>
          <Box marginBottom={{ xs: 's', md: '0' }} width={{ xs: 'auto', md: '8rem' }}>
            <Label>{isFrench ? 'Code Postal' : 'ZIP/Postal Code'}</Label>
            <Text fontSize="s">{info.postalCode}</Text>
          </Box>
        </Box>
      </Expand>
      {!isAccountFlow && (
        <Expand isExpanded={isExpanded} showExpandButton={true}>
          <Text forwardedAs="h3" marginBottom="m">
            {isFrench ? 'Information de l’équipement' : 'Equipment Information'}
          </Text>
          {info.requestEquipmentLocationName && (
            <Box marginBottom={{ xs: 's', md: 'm' }} width="100%">
              <Label>{isFrench ? 'nom de l’emplacement' : 'Location Name'}</Label>
              <Text fontSize="s">{info.requestEquipmentLocationName}</Text>
            </Box>
          )}
          <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
            <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '15rem' }}>
              <Label>{isFrench ? 'TYPE D’ÉQUIPEMENT DISTRIBUTRICE' : 'Equipment Type'}</Label>
              <Text fontSize="s">{info.equipmentType}</Text>
            </Box>
            <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '12rem' }}>
              <Label>{isFrench ? 'NUMÉRO DE L’ÉQUIPEMENT' : 'Asset #'}</Label>
              <Text fontSize="s">{info.serialNumber || 'N/A'}</Text>
            </Box>
          </Box>
          <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
            <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '15rem' }}>
              <Label>{isFrench ? 'Rue' : 'Street'}</Label>
              <Text fontSize="s">{info.requestEquipmentLocationStreet || info.street}</Text>
            </Box>
            <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '9rem' }}>
              <Label>{isFrench ? 'Ville' : 'City'}</Label>
              <Text fontSize="s">{info.requestEquipmentLocationCity || info.city}</Text>
            </Box>
          </Box>
          <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
            <Box marginBottom={{ xs: 's', md: '0' }} width={{ xs: 'auto', md: '15rem' }}>
              <Label>{isFrench ? 'Province' : 'State/Prov'}</Label>
              <Text fontSize="s">{info.requestEquipmentLocationState || info.state}</Text>
            </Box>
            <Box marginBottom={{ xs: 's', md: '0' }} width={{ xs: 'auto', md: '9rem' }}>
              <Label>{isFrench ? 'Code Postal' : 'ZIP/Postal Code'}</Label>
              <Text fontSize="s">{info.requestEquipmentLocationPostalCode || info.postalCode}</Text>
            </Box>
          </Box>
        </Expand>
      )}
      <Expand isExpanded={isExpanded} showExpandButton={true}>
        <Text forwardedAs="h3" marginBottom="m">
          {isFrench ? 'Information du contact' : 'Contact Information'}
        </Text>
        {info.employeeName && (
          <>
            <Text forwardedAs="h4" marginBottom="m">
              {isFrench ? 'Information de l’employé contact' : 'Employee Contact Information'}
            </Text>
            <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
              <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '15rem' }}>
                <Label>{isFrench ? 'Nom de l’employé' : 'Employee Name'}</Label>
                <Text fontSize="s">{info.employeeName}</Text>
              </Box>
            </Box>
            <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
              <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '15rem' }}>
                <Label>{isFrench ? 'Courriel de l’employé' : 'Employee Email'}</Label>
                <Text fontSize="s">{info.employeeEmail}</Text>
              </Box>
              <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: isFrench ? '20rem' : '17rem' }}>
                <Label>{isFrench ? 'Numéro de tél. de l’employé contact' : 'Employee Contact Phone Number'}</Label>
                <Text fontSize="s">{info.employeePhone}</Text>
              </Box>
            </Box>
          </>
        )}
        {info.employeeName && (
          <Text forwardedAs="h4" marginBottom="m" marginTop="s">
            {isFrench ? 'Information du contact client' : 'Customer Contact Information'}
          </Text>
        )}
        <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '15rem' }}>
            <Label>{info.employeeName ? (isFrench ? 'Prénom du client' : 'Customer First Name') : isFrench ? 'Prénom du contact' : 'Contact First Name'}</Label>
            <Text fontSize="s">{info.contactFirstName}</Text>
          </Box>
          <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '15rem' }}>
            <Label>{info.employeeName ? (isFrench ? 'Nom du client' : 'Customer Last Name') : isFrench ? 'Nom du contact' : 'Contact Last Name'} </Label>
            <Text fontSize="s">{info.contactLastName}</Text>
          </Box>
        </Box>
        <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          {info.contactEmail && (
            <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '15rem' }}>
              <Label>{info.employeeName ? (isFrench ? 'Courriel du client' : 'Customer Email') : isFrench ? 'Courriel du contact' : 'Contact Email'}</Label>
              <Text fontSize="s">{info.contactEmail}</Text>
            </Box>
          )}
          <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '15rem' }}>
            <Label>{info.employeeName ? (isFrench ? 'Téléphone du client' : 'Customer Phone') : isFrench ? 'Téléphone du contact' : 'Contact Phone'}</Label>
            <Text fontSize="s">{info.contactPhone}</Text>
          </Box>
        </Box>
        <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box marginBottom={{ xs: 's', md: 'm' }}>
            <Label>{isFrench ? 'Soumis par' : 'Submitted By'}</Label>
            <Text fontSize="s">{info.contactRole}</Text>
          </Box>
        </Box>
      </Expand>
      <Expand isExpanded={isExpanded} showExpandButton={true}>
        <Text forwardedAs="h3" marginBottom="m">
          {isFrench ? 'Information additionnelle' : 'Additional Information'}
        </Text>
        <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box marginBottom={{ xs: 's', md: 'x2' }}>
            <Label>{isAccountFlow ? 'Problem Reported' : isFrench ? "Problème rencontré par l'équipement" : 'Problem Equipment is Experiencing'}</Label>
            <Text fontSize="s">{info.equipmentProblemDescription}</Text>
          </Box>
        </Box>
        <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box marginBottom={{ xs: 's', md: 'x2' }}>
            <Label>{isFrench ? 'Informations additionnelle' : 'Additional Information'}</Label>
            <Text fontSize="s">{info.requestDescription}</Text>
          </Box>
        </Box>
      </Expand>
      <Expand isExpanded={isExpanded} showExpandButton={true}>
        <Text forwardedAs="h3" marginBottom="m">
          {isAccountFlow ? (isFrench ? 'Disponibilités' : 'Available Contact Times') : isFrench ? 'Horaires de service disponibles' : 'Available Service Times'}
        </Text>
        <Box display="flex" alignItems={{ xs: 'flex-start' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box marginBottom={{ xs: 's', md: '0' }} width={{ xs: 'auto', md: '15rem' }}>
            <Label>{isFrench ? 'Jours' : 'Days'}</Label>
            <Text fontSize="s">
              {isEqual(
                info.availableDays
                  .replace(/ /g, '')
                  .split(',')
                  .sort(),
                MonToFri.sort()
              )
                ? isFrench
                  ? 'Lun-Ven'
                  : 'Mon-Fri'
                : info.availableDays}
            </Text>
          </Box>
          <Box marginBottom={{ xs: 's', md: '0' }} width={{ xs: 'auto', md: '9rem' }}>
            <Label>{isFrench ? 'Heures' : 'Time'}</Label>
            {info.availabilityHoursStart !== '11:59pm' && info.availabilityHoursEnd !== '12:00am' ? (
              <Text fontSize="s">
                {info.availabilityHoursStart} - {info.availabilityHoursEnd}
              </Text>
            ) : (
              <Text fontSize="s">24x7</Text>
            )}
          </Box>
        </Box>
      </Expand>
    </>
  );
};
