import React from 'react';
import { ErrorMessage } from './ErrorMessage';

interface IIconMessageProps {
  children: any;
  iconName?: string;
  title?: string;
  height?: string;
}

export const IconMessage = (props: IIconMessageProps) => <ErrorMessage {...props} />;
