import React, { FC, useState, useEffect, useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { to } from 'await-to-js';
import styled, { Box } from '@xstyled/styled-components';
import { darken } from 'polished';
// components
import { Loader, ErrorMessage } from '../common';
// fetch
import { fetchPrivacyPolicy } from '../../fetch';
// models
import { IPrivacyPolicyInner } from '../../models/privacy-policy';
import { Language } from '../../context/language';

interface IPrivacyPolicyContent {
  htmlStylesWrapper?: any;
}

export const PrivacyPolicyContent: FC<IPrivacyPolicyContent> = ({ htmlStylesWrapper }) => {
  const [policy, setPolicy] = useState<IPrivacyPolicyInner | null>(null);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const { isFrench } = useContext(Language);

  useEffect(() => {
    const getPrivacyPolicy = async () => {
      setFetching(true);
      setPolicy(null);
      const [, data] = await to(fetchPrivacyPolicy());
      setFetching(false);

      if (data && data.err) {
        return setError(data.err);
      }
      if (data) {
        return setPolicy(data.res);
      }
    };
    getPrivacyPolicy();
  }, []);

  return (
    <>
      {!isFetching && policy && !isFrench && Object.values(policy).length > 0 && <Policy styles={htmlStylesWrapper}>{ReactHtmlParser(policy.html)}</Policy>}
      {!isFetching && policy && isFrench && Object.values(policy).length > 0 && <Policy styles={htmlStylesWrapper}>{ReactHtmlParser(policy.frenchHtml)}</Policy>}
      {isFetching && (
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" width="100%" height="x16">
          <Loader />
        </Box>
      )}
      {!policy && !isFetching && error && error.message.includes('500') && (
        <ErrorMessage>
          {isFrench
            ? 'Désolé erreur de chargement de la politique de confidentialité, veuillez actualiser et réessayer.'
            : 'Sorry error loading the privacy policy, please refresh and try again.'}
        </ErrorMessage>
      )}
    </>
  );
};

const Policy = styled.div`
  word-break: break-word;
  ${(props: any) => props.styles};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    + * {
      margin-top: 1rem;
    }
  }

  a {
    color: primary;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    ${(p: any) =>
      p.backgroundColor === 'primary'
        ? `
      &:focus {
        outline-color: ${darken(0.25, p.theme.colors[p.backgroundColor])};
      }
      `
        : `
      &:focus {
        outline-color: ${p.theme.colors.primary};
      }
      `}
  }

  ul,
  ol {
    padding-left: 2rem;
    margin: 0;
  }

  li {
    margin: 0.25rem 0;
  }
`;
