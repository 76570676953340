export interface IContactRoleInner {
  id: number;
  name: string;
  frenchName: string;
  description: string;
  frenchDescription: string;
  displayOrder: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
}

export interface IContactRoleBase {
  message: string;
  statusCode: number;
  contactRoles: IContactRoleInner[];
}

export enum ContactRoles {
  CUSTOMER = '1',
  CONSUMER = '2',
  EMPLOYEE = '3'
}
