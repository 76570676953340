import { createContext } from 'react';

export const Language = createContext<{
  language?: string;
  setLanguage?: any;
  isFrench: boolean;
}>({
  language: 'en-us',
  setLanguage: () => {},
  isFrench: false
});
