import React, { FC, useContext, useState } from 'react';
import { IDropdownOption } from '@fluentui/react';
import { Field, FieldProps } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { darken } from 'polished';
// @ts-ignore
import styled from '@xstyled/styled-components';
// components
import { Text, Expand, Button, Dropdown, Checkbox, Centered } from '../common';
import { Box } from '@xstyled/styled-components';
import { PrivacyPolicyModal } from '../privacy-policy';
// helpers
import { hours, days } from '../../helpers';
import { IAvailableServiceTimesFormValues } from '../../pages/SubmitRequest';
import { Language } from '../../context/language';
import { useGlobalState } from '../../stateManagement';

interface IAvailableServiceTimesProps {
  values: IAvailableServiceTimesFormValues;
  setFieldValue: (field: any, val: any) => void;
  errors: {
    street?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    info?: string;
    problem?: string;
    contactFirstName?: string;
    contactLastName?: string;
    contactEmail?: string;
    contactPhone?: string;
    contactRole?: string;
    contactNotications?: string | null;
  };
}

export const AvailableServiceTimes: FC<IAvailableServiceTimesProps> = ({ errors, values, setFieldValue }) => {
  const hasValidPickDaysOptions = values.selectedServiceOption === 'Pick Days' && (values.anyHour || (values.days.length > 0 && values.fromTime && values.toTime)) ? true : false;
  const hasValidTimeOptions =
    (values.selectedServiceOption === 'Mon-Fri' || values.selectedServiceOption === 'All') && (values.anyHour || (values.fromTime && values.toTime)) ? true : false;
  const isFormValid = (errors && Object.values(errors).filter(Boolean).length === 0) || !errors;
  // state
  const [isNotRobot, setNotRobot] = useState<boolean>(false);
  const [showPrivacyModal, setPrivacyModal] = useState<boolean>(false);
  const { isFrench } = useContext(Language);
  const { isAccountFlow } = useGlobalState();
  return (
    <>
      <Expand isExpanded={true} showExpandButton={false}>
        <Text forwardedAs="h3" marginBottom="m">
          {isAccountFlow ? (isFrench ? 'Heures de contact disponibles' : 'Available Contact Times') : isFrench ? 'Horaires de service disponibles' : 'Available Service Times'}
        </Text>
        <Text>
          {isAccountFlow
            ? isFrench
              ? 'Veuillez sélectionner les heures disponibles aux heures auxquels vous pouvez être contacté.'
              : 'Please select available times when you are able to be contacted.'
            : isFrench
            ? `Veuillez sélectionner les heures disponibles lorsque l’établissement, où se trouve l'équipement, est ouvert et l'équipement est accessible.`
            : 'Please select available times when the facility, where the equipment is located, is open and the equipment is accessible.'}
        </Text>
        <Box display="flex" width="100%" marginTop="m" flexDirection={{ xs: 'column', sm: 'row' }}>
          <Button
            backgroundColor={values.selectedServiceOption === 'Mon-Fri' ? 'primary' : 'white'}
            color={values.selectedServiceOption === 'Mon-Fri' ? 'white' : 'primary'}
            border="basicPrimary"
            flex="1"
            type="button"
            onClick={() => setFieldValue('selectedServiceOption', 'Mon-Fri')}
          >
            {isFrench ? 'Lun-Ven' : 'Mon-Fri'}
          </Button>
          <Button
            backgroundColor={values.selectedServiceOption === 'All' ? 'primary' : 'white'}
            color={values.selectedServiceOption === 'All' ? 'white' : 'primary'}
            border="basicPrimary"
            flex="1"
            type="button"
            onClick={() => setFieldValue('selectedServiceOption', 'All')}
          >
            {isFrench ? 'Tout' : 'All'}
          </Button>
          <Button
            backgroundColor={values.selectedServiceOption === 'Pick Days' ? 'primary' : 'white'}
            color={values.selectedServiceOption === 'Pick Days' ? 'white' : 'primary'}
            border="basicPrimary"
            flex="1"
            type="button"
            onClick={() => {
              setFieldValue('selectedServiceOption', 'Pick Days');
            }}
          >
            {isFrench ? 'Choisir jours' : 'Pick Days'}
          </Button>
        </Box>
        {values.selectedServiceOption === 'Pick Days' && (
          <Box display="flex" marginTop="x1" marginBottom="x1" marginRight={{ xs: '0', md: 'x2' }} marginLeft={{ xs: '0', md: 'x2' }}>
            <Field name="day">
              {({ form }: FieldProps) => {
                return (
                  <>
                    {days.map(day => {
                      const isSelected = form.values.days.includes(day.key);
                      return (
                        <Button
                          key={day.key}
                          backgroundColor={form.values.days.includes(day.key) ? 'primary' : 'white'}
                          color={form.values.days.includes(day.key) ? 'white' : 'primary'}
                          border="basicPrimary"
                          flex="1"
                          padding="0"
                          fontSize="s"
                          height="32px"
                          type="button"
                          onClick={() =>
                            form.setFieldValue('days', isSelected ? form.values.days.filter((formDay: string) => formDay !== day.key) : [...form.values.days, day.key])
                          }
                        >
                          {isFrench ? day.frenchText : day.text}
                        </Button>
                      );
                    })}
                  </>
                );
              }}
            </Field>
          </Box>
        )}
        <Field name="anyHour">
          {({ form }: FieldProps) => {
            return (
              <Checkbox
                marginTop="m"
                marginBottom="m"
                label={isFrench ? 'N’importe quel heure' : 'Any hour of the day'}
                onChange={() => form.setFieldValue('anyHour', !form.values.anyHour)}
              />
            );
          }}
        </Field>
        {!values.anyHour && (
          <>
            <Box display="flex" marginBottom="m">
              <Field name="fromTime">
                {({ form }: FieldProps) => {
                  return (
                    <Dropdown
                      width="11.5rem"
                      marginRight="m"
                      selectedKey={form.values.fromTime || null}
                      onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
                        form.setFieldValue('fromTime', (option?.key || form.values.fromTime) as string);
                      }}
                      placeholder={isFrench ? 'De' : 'From'}
                      options={hours}
                    />
                  );
                }}
              </Field>
              <Box display="flex" width="x8" alignItems="center">
                <Field name="fromTimeFrame">
                  {({ form }: FieldProps) => {
                    return (
                      <>
                        <Button
                          backgroundColor={form.values.fromTimeFrame === 'AM' ? 'primary' : 'white'}
                          color={form.values.fromTimeFrame === 'AM' ? 'white' : 'primary'}
                          border="basicPrimary"
                          flex="1"
                          padding="0"
                          fontSize="s"
                          height="32px"
                          type="button"
                          onClick={() => form.setFieldValue('fromTimeFrame', 'AM')}
                        >
                          AM
                        </Button>
                        <Button
                          backgroundColor={form.values.fromTimeFrame === 'PM' ? 'primary' : 'white'}
                          color={form.values.fromTimeFrame === 'PM' ? 'white' : 'primary'}
                          border="basicPrimary"
                          flex="1"
                          padding="0"
                          fontSize="s"
                          height="32px"
                          type="button"
                          onClick={() => form.setFieldValue('fromTimeFrame', 'PM')}
                        >
                          PM
                        </Button>
                      </>
                    );
                  }}
                </Field>
              </Box>
            </Box>
            <Box display="flex">
              <Field name="toTime">
                {({ form }: FieldProps) => {
                  return (
                    <Dropdown
                      width="11.5rem"
                      marginRight="m"
                      selectedKey={form.values.toTime || null}
                      onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
                        form.setFieldValue('toTime', (option?.key || form.values.toTime) as string);
                      }}
                      placeholder={isFrench ? 'À' : 'To'}
                      options={hours}
                    />
                  );
                }}
              </Field>
              <Box display="flex" width="x8" alignItems="center">
                <Field name="toTimeFrame">
                  {({ form }: FieldProps) => {
                    return (
                      <>
                        <Button
                          backgroundColor={form.values.toTimeFrame === 'AM' ? 'primary' : 'white'}
                          color={form.values.toTimeFrame === 'AM' ? 'white' : 'primary'}
                          border="basicPrimary"
                          flex="1"
                          padding="0"
                          fontSize="s"
                          height="32px"
                          type="button"
                          onClick={() => form.setFieldValue('toTimeFrame', 'AM')}
                        >
                          AM
                        </Button>
                        <Button
                          backgroundColor={form.values.toTimeFrame === 'PM' ? 'primary' : 'white'}
                          color={form.values.toTimeFrame === 'PM' ? 'white' : 'primary'}
                          border="basicPrimary"
                          flex="1"
                          padding="0"
                          fontSize="s"
                          height="32px"
                          type="button"
                          onClick={() => form.setFieldValue('toTimeFrame', 'PM')}
                        >
                          PM
                        </Button>
                      </>
                    );
                  }}
                </Field>
              </Box>
            </Box>
          </>
        )}
      </Expand>
      <Centered marginTop="x1" marginBottom="m" flexDirection="column">
        <ReCAPTCHA
          hl={isFrench ? 'fr-ca' : 'en-us'}
          onChange={response => {
            if (response) {
              setNotRobot(true);
            }
          }}
          sitekey="6Lekg9IUAAAAAGbHm-8SuTTrZuPXPii2MYaLzgly"
        />
        {isFrench ? (
          <Text fontSize="s" marginTop="m" marginBottom="m" maxWidth="21rem">
            En cliquant sur ''Soumettre une demande de service'', j'accepte la{' '}
            <PrivacyButton type="button" onClick={() => setPrivacyModal(true)}>
              politique de confidentialité
            </PrivacyButton>
            . Veuillez imprimer pour vos dossiers.
          </Text>
        ) : (
          <Text fontSize="s" marginTop="m" marginBottom="m" maxWidth="21rem">
            By clicking on "Submit Request", I agree to the{' '}
            <PrivacyButton type="button" onClick={() => setPrivacyModal(true)}>
              Privacy Policy
            </PrivacyButton>
            . Please print for your records.
          </Text>
        )}
        <Button
          type="submit"
          color="white"
          disabled={!isFormValid || (!hasValidPickDaysOptions && !hasValidTimeOptions) || !isNotRobot}
          backgroundColor={isNotRobot && isFormValid && (hasValidPickDaysOptions || hasValidTimeOptions) ? 'primary' : 'grayLight'}
          marginTop="m"
        >
          {isFrench ? 'Soumettre une demande de service' : 'Submit Request'}
        </Button>
      </Centered>
      <PrivacyPolicyModal isOpen={showPrivacyModal} handleClose={() => setPrivacyModal(false)} />
    </>
  );
};

const PrivacyButton = styled(Button)`
  padding: 0;
  color: primary;
  :hover {
    background-color: transparent;
    color: ${(p: any) => darken(0.05, p.theme.colors.primary)};
  }
`;
