import React, { FC, useContext } from 'react';
import { useMedia } from 'react-use';
import { Box } from '@xstyled/styled-components';
import { Language } from '../../context/language';
import { Button, Centered, Modal, Text } from '../common';

interface IConsentModal {
  isOpen: boolean;
  message: string;
  handleClose: (type: string) => void;
}

export const ConsentModal: FC<IConsentModal> = ({ isOpen, message, handleClose }) => {
  const isDesktop = useMedia('(min-width: 500px)');
  const { isFrench } = useContext(Language);

  return (
    <Modal isOpen={isOpen} onDismiss={() => handleClose('Cancel')} containerStyles={{ width: isDesktop ? '30%' : '90%' }}>
      <Centered padding="x1" paddingLeft="m" paddingRight="m" textAlign="center" flexDirection="column">
        <Text forwardedAs="h2" marginBottom="x1" width="80%" fontWeight="300">
          {message}
        </Text>

        <Box display="flex" justifyContent="center" marginTop="x2" width={{ xs: '100%', md: '80%' }}>
          <Button backgroundColor="primary" color="white" marginRight="s" onClick={() => handleClose('Consent')}>
            {isFrench ? 'Consentement' : 'Consent'}
          </Button>
          <Button
            backgroundColor="grayLighter"
            color="black"
            onClick={() => {
              handleClose('Cancel');
            }}
          >
            {isFrench ? 'Annulation' : 'Cancel'}
          </Button>
        </Box>
      </Centered>
    </Modal>
  );
};
