import React, { FC } from 'react';
import styled from '@xstyled/styled-components';
import { system, SystemProps } from '@xstyled/system';
import { css } from 'styled-components';
import { Modal as OfficeModal, IModalProps } from '@fluentui/react';
import { IconButton } from './IconButton';

type Props = SystemProps & IModalProps & IStyledModalProps;

interface IModal extends Props {
  children: any;
  showCloseButton?: boolean;
}

export const Modal: FC<IModal> = ({ children, showCloseButton = true, ...props }) => {
  return (
    <StyledModal {...props}>
      {showCloseButton && <StyledIconButton iconProps={{ iconName: 'Cancel' }} ariaLabel="Close popup modal" onClick={props.onDismiss as any} />}
      {children}
    </StyledModal>
  );
};

interface IStyledModalProps extends SystemProps, IModalProps {
  containerStyles?: { [key: string]: string | number };
}

const StyledModal: FC<Props> = styled(OfficeModal)`
  .ms-Dialog-main {
    display: flex;
    align-items: stretch;
    flex-flow: column nowrap;
  }

  ${(props: Props) => {
    if (props.containerStyles) {
      return `
        .ms-Dialog-main {
          ${css(props.containerStyles)}
        }
      `;
    }
  }}
  ${system}
`;

const StyledIconButton = styled(IconButton)`
  color: black;
  position: absolute;
  right: 10px;
  top: 10px;
`;
