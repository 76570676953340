import React, { FC } from 'react';
import styled from '@xstyled/styled-components';
import { system, SystemProps } from '@xstyled/system';
import { TextField as OfficeTextField, ITextFieldProps, MaskedTextField } from '@fluentui/react';

export const TextField: FC<SystemProps & ITextFieldProps> = styled((props: SystemProps & ITextFieldProps) => {
  return props.mask ? (
    <MaskedTextField {...props} styles={{ field: { fontSize: '16px' }, errorMessage: { color: '#d0021b' } }} />
  ) : (
    <OfficeTextField {...props} styles={{ field: { fontSize: '16px' }, errorMessage: { color: '#d0021b' } }} />
  );
})`
  ${system}
`;
