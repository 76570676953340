import React, { FC, useContext } from 'react';
import styled from '@xstyled/styled-components';
import { Button } from '../common';
import { useGlobalState } from '../../stateManagement';
import { Language } from '../../context/language';

interface IZipCodeLookupToggle {
  text?: string;
  onClick?: () => void;
}

export const ZipCodeLookupToggle: FC<IZipCodeLookupToggle> = ({ text = 'click here', onClick }) => {
  const { showZipLookup } = useGlobalState();
  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  return (
    <StyledButton
      color="primary"
      onClick={() => {
        showZipLookup();
        if (onClick) {
          onClick();
        }
      }}
    >
      {isFrench ? 'cliquer ici' : text}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  display: contents;
  padding: 0;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;
