import React, { FC, useContext, useEffect, useState } from 'react';
import { IDropdownOption } from '@fluentui/react';
import to from 'await-to-js';
import { Field, FieldProps } from 'formik';
// components
import { Text, Expand, Button, TextField, Dropdown, ErrorMessage } from '../common';
import { Box } from '@xstyled/styled-components';
// helpers
import { IAddtionalInfoFormValues, IEquipmentFormValues, IContactInfoFormValues } from '../../pages/SubmitRequest';
// fetch
import { getEquipmentProblems } from '../../fetch';
import { IEquipmentProblemsInner, EquipmentTypes } from '../../models';
// hooks
import { useGlobalState } from '../../stateManagement';
// models
import { ContactRoles } from '../../models';
import { Language } from '../../context/language';

interface IAdditionalInformationProps {
  setAdditionalInfoConfirmed: () => void;
  isAdditionalInfoConfirmed: boolean;
  values: IAddtionalInfoFormValues & IEquipmentFormValues & IContactInfoFormValues;
  errors: any;
  currentFormErrors: {
    info?: string;
    problem?: string;
  };
  isEmployeeRole: boolean;
}

export const AdditionalInformation: FC<IAdditionalInformationProps> = ({
  isAdditionalInfoConfirmed,
  setAdditionalInfoConfirmed,
  values,
  errors,
  currentFormErrors,
  isEmployeeRole
}) => {
  const { isFrench } = useContext(Language);
  const { account, equipment, isAccountFlow } = useGlobalState();

  const isValidCustomer =
    !!values.contactFirstName && !!values.contactLastName && !!values.contactPhone && values.contactNotifications.includes('Email') ? !!values.contactEmail : true;
  const isValidEmployee = isEmployeeRole && !!values.employeeEmail && !!values.employeeName && !!values.employeePhone && values.employeeNotifications.length > 0 && isValidCustomer;
  const isValid =
    (isValidEmployee || isValidCustomer) &&
    values.street &&
    values.city &&
    values.zipCode &&
    values.state &&
    values.info &&
    values.problem &&
    Object.values(errors).filter(Boolean).length === 0;
  const [problemCodes, setProblemCodes] = useState<
    | {
        key: string;
        frenchDescription: string;
        description: string;
      }[]
    | undefined
  >([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchEquipmentProblems = async () => {
      // contact role id for coke employee
      let currentProblemCodes: IEquipmentProblemsInner[] = [];
      if (values.contactRole === ContactRoles.EMPLOYEE && account) {
        // make additional request to get bottler problem codes
        const [, data] = await to(getEquipmentProblems(`${isAccountFlow ? EquipmentTypes.YourAccount : equipment?.equipmentTypeId}`, account?.bottlerId));
        if (data && data.res) {
          currentProblemCodes = data.res;
        }
      }
      const [, data] = await to(getEquipmentProblems(`${isAccountFlow ? EquipmentTypes.YourAccount : equipment?.equipmentTypeId}`));
      if (data && data.res) {
        if (currentProblemCodes.length > 0) {
          const custProblemCodes = data.res.map(code => code.problemCode);
          // need to loop through the bottler problem codes and remove any duplicates that already exist
          // in the coke service problem codes request for bottlerId=0
          const uniqueProblemCodes = currentProblemCodes
            .map(code => {
              if (custProblemCodes.includes(code.problemCode)) {
                return null;
              }
              return code;
            })
            .filter(Boolean) as IEquipmentProblemsInner[];
          currentProblemCodes = [...data.res, ...uniqueProblemCodes];
        } else {
          currentProblemCodes = data.res;
        }
      }
      if (data && data.err) {
        return setError(data.err);
      }

      // If Contact Role is set to Employee, filter out specific problem codes
      // - "I need the equipment to be removed" = CST084
      // - "I need the equipment to be moved" = CST048
      // - "I'd like to swap the equipment" = CST091

      if (isEmployeeRole) {
        currentProblemCodes = currentProblemCodes.filter(problem => problem.problemCode !== 'CST048' && problem.problemCode !== 'CST084' && problem.problemCode !== 'CST091');
      }

      /**
       * If the equipment type is Vendor (or one of the other names we use for Vendor), then only display the Fill Problem code in the drop-down if all of the conditions below are true:
          - Asset Number is populated on the equipment
          - The Sub-trade channel on the account/outlet is equal to "FSV"(this stands for Full Service Vendor).
       */
      const isVendor =
        equipment?.equipmentTypeId === EquipmentTypes.VenderGlassFront ||
        equipment?.equipmentTypeId === EquipmentTypes.Vending ||
        equipment?.equipmentTypeId === EquipmentTypes.VendingMachine;
      const hasAssetNumber = equipment?.serialNumber;
      // https://dev.azure.com/mwks/internal/_workitems/edit/145408
      const isFSV = (account?.distributionChannel && account?.distributionChannel?.code === 'Z3') || false;

      if (isVendor && !(hasAssetNumber && isFSV)) {
        // Remove problem with code CST032
        currentProblemCodes = currentProblemCodes.filter(problem => problem.problemCode !== 'CST032');
      }

      return setProblemCodes(
        currentProblemCodes.map((problem: IEquipmentProblemsInner) => ({
          key: `${problem.id}`,
          frenchDescription: problem.frenchDescription,
          description: problem.description
        }))
      );
    };

    fetchEquipmentProblems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.contactRole]);

  return (
    <>
      <Expand isExpanded={!isAdditionalInfoConfirmed} showExpandButton={isAdditionalInfoConfirmed && Object.values(currentFormErrors).filter(Boolean).length === 0}>
        <Text forwardedAs="h3" marginBottom="m">
          {isFrench ? 'Informations supplémentaires' : 'Additional Information'}
        </Text>
        {!error && (
          <>
            <Field name="problem">
              {({ form, meta }: FieldProps) => {
                return (
                  <Dropdown
                    selectedKey={form.values.problem || null}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
                      form.setFieldValue('problem', (option?.key || form.values.problem) as string);
                    }}
                    placeholder={isFrench ? 'Sélectionner une option' : 'Select an option'}
                    width={{ xs: '100%' }}
                    label={
                      isAccountFlow
                        ? isFrench
                          ? 'Quel type de problème rencontrez-vous?'
                          : 'What type of problem are you experiencing?'
                        : isFrench
                        ? "Quel type de problème l'équipement rencontre-t-il?"
                        : 'What type of problem is the equipment experiencing?'
                    }
                    options={
                      problemCodes?.map(code => ({
                        key: code.key,
                        text: isFrench ? code.frenchDescription : code.description
                      })) as IDropdownOption[]
                    }
                    marginBottom={{ xs: 'm' }}
                    errorMessage={meta.touched && meta.error && isFrench ? 'Requis' : ((meta.touched && meta.error) as string)}
                    required={true}
                  />
                );
              }}
            </Field>
            <Field name="info">
              {({ field, meta }: FieldProps) => {
                return (
                  <TextField
                    required={true}
                    width={{ xs: '100%' } as any}
                    errorMessage={meta.touched && meta.error && isFrench ? 'Requis' : ((meta.touched && meta.error) as string)}
                    placeholder={
                      isAccountFlow
                        ? isFrench
                          ? 'Entrez des détails supplémentaires liés à votre demande.'
                          : 'Enter additional details related to your request.'
                        : isFrench
                        ? "Entrez des informations supplémentaires sur cet équipement, y compris plus de détails sur l'emplacement de l'équipement et l'assistance nécessaire."
                        : 'Enter additional information about this equipment, including more details about where the equipment is located and what assistance is needed.'
                    }
                    label={isFrench ? 'Informations supplémentaires' : 'Additional Information'}
                    multiline
                    autoAdjustHeight
                    {...field}
                  />
                );
              }}
            </Field>
          </>
        )}
        {error && (
          <ErrorMessage>
            {isFrench ? 'Désolé, erreur affichant les codes de problème, veuillez actualiser et réessayer.' : 'Sorry, error showing problem codes, please refresh and try again.'}
          </ErrorMessage>
        )}
      </Expand>
      {!isAdditionalInfoConfirmed && !error && (
        <Box display="flex" marginTop="x1" alignItems="center" justifyContent={{ xs: 'center' }} marginBottom="m">
          <Button onClick={setAdditionalInfoConfirmed} disabled={!isValid} color="white" width="50%" backgroundColor={isValid ? 'primary' : 'grayLight'}>
            {isFrench ? 'Poursuivre' : 'Continue'}
          </Button>
        </Box>
      )}
    </>
  );
};
