import React, { FC, useState, useEffect, useContext } from 'react';
import to from 'await-to-js';
import { useMedia } from 'react-use';
// components
import { SearchInput, Button, Text, ButtonLink, Loader, NoResult, ErrorMessage } from '../common';
import { DisabledAppModal } from '../skeleton';
import { ZipCodeLookupToggle } from '../zip-code';
import { Box } from '@xstyled/styled-components';
import { AccountInformation } from './AccountInformation';
// models
import { IOutletBase, IBottlerAccess, IOutletInner } from '../../models';
// fetch
import { fetchAccount } from '../../fetch';
// hooks
import { useGlobalState } from '../../stateManagement';
import { postLogin } from '../../fetch/login';
import { Apps } from '../../models/app';
import { EquipmentLookupTypes } from '../../models/equipment-lookup-type';
import { setLoginId, removeLoginId } from '../../services';
import { BottlersAccess } from '../../context/bottlersAccess';
// helpers
import { appendZeroToAccountNumber } from '../../helpers';
import { Language } from '../../context/language';

interface IAccountContent {
  setHeading: (val: string) => void;
  heading: string;
  hasResults?: (hasResults: boolean) => void;
  hasError?: (error: boolean) => void;
  setAccountNumber?: (accountNumber: string) => void;
}

export const AccountContent: FC<IAccountContent> = ({ setHeading, heading, hasResults, hasError, setAccountNumber }) => {
  const [value, setValue] = useState<string>('');
  const [result, setResult] = useState<IOutletBase | null>(null);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const isTabletOrDesktop = useMedia('(min-width: 768px)');
  const [selectedOutlet, showDisabledAppModal] = useState<IOutletInner | null>(null);
  // hooks
  const { setAccount, isAccountFlow } = useGlobalState();

  useEffect(() => {
    removeLoginId();
  }, []);

  const handleSearch = async () => {
    setFetching(true);
    hasResults && hasResults(true);
    setHeading('');
    setResult(null);
    setAccountNumber && setAccountNumber(value);
    const [, data] = await to(fetchAccount(appendZeroToAccountNumber(value)));

    if (data && data.err) {
      setFetching(false);
      hasResults && hasResults(false);
      return setError(data.err);
    }
    if (data) {
      const login = await postLogin({ appId: Apps.COKE_SERVICES_APP, equipmentLookupTypeId: EquipmentLookupTypes.OUTLET_NUMBER });
      if (login.res && login.res.login) {
        await setLoginId(login.res?.login?.id);
      }
      setFetching(false);
      return setResult(data.res);
    }
  };

  const { language } = useContext(Language);

  const isFrench = language === 'fr-ca';

  const { bottlersAccess } = useContext(BottlersAccess);
  const selectedBottler: IBottlerAccess | undefined =
    bottlersAccess && result && result.outlet ? bottlersAccess.find(access => access.bottlerId === result.outlet.bottlerId) : undefined;

  return (
    <>
      <Box display="flex" width="100%">
        <SearchInput
          placeholder={isFrench ? 'Entrer un numéro de compte' : 'Enter an account number...'}
          width={isTabletOrDesktop ? '50%' : 'auto'}
          minWidth="1px"
          flex={isTabletOrDesktop ? '0 0 auto' : '1'}
          value={value}
          disabled={isFetching}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === 13) {
              handleSearch();
            }
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (error) {
              setError(null);
            }
            setValue(e.target.value);
          }}
        />
        <Button
          backgroundColor={value && !isFetching ? 'primary' : 'grayLight'}
          color="white"
          disabled={!value || isFetching}
          onClick={handleSearch}
          width={{ xs: 'auto', sm: '47%' }}
        >
          {}
          {isTabletOrDesktop ? (isFrench ? 'Rechercher le numéro de compte' : 'Search Account Number') : isFrench ? 'Rechercher' : 'Search'}
        </Button>
      </Box>
      {isFrench ? (
        <Text marginTop="m" marginBottom="m">
          Vous ne connaissez pas votre numéro de compte? <ZipCodeLookupToggle text="Cliquez ici" /> pour obtenir de l’aide.
        </Text>
      ) : (
        <Text marginTop="m" marginBottom="m">
          Don't know your account number? <ZipCodeLookupToggle text="Click here" /> for assistance.
        </Text>
      )}
      {!isFetching && result && Object.values(result).length > 0 && (
        <Box marginRight={{ xs: '0', md: '1.25rem' }}>
          <AccountInformation accountInfo={result.outlet} type="account number" />
          <ButtonLink
            paddingTop="s"
            paddingBottom="s"
            display="block"
            textAlign="center"
            to={isAccountFlow ? '/submit-request' : selectedBottler && selectedBottler.isActive ? '/select-equipment' : ''}
            color="white"
            marginTop="m"
            marginBottom="m"
            width="100%"
            backgroundColor="primary"
            onClick={(e: React.MouseEvent) => {
              if (selectedBottler && !selectedBottler.isActive) {
                e.preventDefault();
                return showDisabledAppModal(result.outlet);
              }
              setAccount(result.outlet);
            }}
          >
            {isFrench ? 'Confirmer - Compte correct' : 'Confirm - Account Correct'}
          </ButtonLink>
          <Button
            onClick={() => {
              setHeading(isFrench ? 'Compte incorrect' : 'Incorrect Account');
              setResult(null);
              setValue('');
              hasResults && hasResults(true);
            }}
            width="100%"
            backgroundColor="grayLighter"
            marginBottom="m"
          >
            {isFrench ? 'Compte incorrect' : 'Incorrect Account'}
          </Button>
        </Box>
      )}
      {heading &&
        !isAccountFlow &&
        (isFrench ? (
          <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" width="100%" height="x16">
            <Text maxWidth="x24" textAlign="center" lineHeight="1.5">
              Veuillez réessayer de rechercher pour un numéro de compte différent. Veuillez <ZipCodeLookupToggle /> si vous ne trouvez pas votre compte.
            </Text>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" width="100%" height="x16">
            <Text maxWidth="x24" textAlign="center" lineHeight="1.5">
              Please try searching again for a different account number. Please <ZipCodeLookupToggle /> if you are unable to find your account.
            </Text>
          </Box>
        ))}
      {isFetching && (
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" width="100%" height="x16">
          <Loader />
        </Box>
      )}
      {!result && !isFetching && error && error.message.includes('404') && isAccountFlow && hasError && hasError(true)}
      {!result &&
        !isFetching &&
        error &&
        error.message.includes('404') &&
        !isAccountFlow &&
        (isFrench ? (
          <NoResult>
            Aucun résultat trouvé pour le numéro de compte {value}. Veuillez réessayer de rechercher pour un numéro de compte différent. Veuillez <ZipCodeLookupToggle /> si vous ne
            trouvez pas votre compte.
          </NoResult>
        ) : (
          <NoResult>
            No result found for Account Number {value}. Please try searching again for a different account number. Please <ZipCodeLookupToggle /> if you are unable to find your
            account.
          </NoResult>
        ))}
      {!result && !isFetching && error && error.message.includes('500') && (
        <ErrorMessage>
          {isFrench
            ? 'Désolé erreur dans la recherche de votre compte, veuillez actualiser et réessayer.'
            : 'Sorry error searching for your account, please refresh and try again.'}
        </ErrorMessage>
      )}
      <DisabledAppModal isOpen={selectedOutlet ? true : false} selectedBottler={selectedBottler} selectedOutlet={selectedOutlet} handleClose={() => showDisabledAppModal(null)} />
    </>
  );
};
