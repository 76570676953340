import React, { FC } from 'react';
import { useMedia } from 'react-use';
// @ts-ignore
import styled, { css, Box } from '@xstyled/styled-components';
import { breakpoints } from '@xstyled/system';
// components
import { Footer, Header } from '../skeleton';
import { Navigation } from './Navigation';

interface IPageProps {
  firstCol?: JSX.Element | null | undefined;
  secCol?: JSX.Element | null | undefined;
}

export const Page: FC<IPageProps> = ({ firstCol, secCol }) => {
  const isTabletOrDesktop = useMedia('(min-width: 768px)');

  return (
    <>
      <Header />
      <Content>
        <Wrapper>
          <Card>
            <Box mx={8}>
              <Box row mx={-8}>
                <Box col={{ xs: 1, md: 9 / 32 }}>{firstCol || <Navigation showNavigation={isTabletOrDesktop} />}</Box>
                {secCol && (
                  <>
                    <Box col={{ xs: 1, md: 1 / 32 }}>
                      <Divider />
                    </Box>
                    <Box col={{ xs: 1, md: 22 / 32 }}>{secCol}</Box>
                  </>
                )}
              </Box>
            </Box>
          </Card>
        </Wrapper>
      </Content>
      <Footer />
    </>
  );
};

const Content = styled.div(
  breakpoints({
    xs: css`
      margin: 0 auto 1rem auto;
      max-width: x56;
      flex: 1 0 auto;
      width: 100%;
    `,
    md: css`
      margin: 2rem auto 8rem;
    `
  })
);

const Wrapper = styled.div(
  breakpoints({
    xs: css`
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    `
  })
);

const Divider = styled.div(
  breakpoints({
    xs: css`
      display: none;
    `,
    md: css`
      width: 1px;
      background-color: grayLight;
      height: 100%;
      min-height: 40rem;
      display: block;
    `
  })
);

const Card = styled.div(
  breakpoints({
    xs: css`
      background-color: white;
      margin: 0 m;
      width: 100%;
      min-height: x40;
    `,
    sm: css`
      width: 100%;
    `,
    md: css`
      min-height: 40rem;
      width: 100%;
    `,
    lg: css`
      border: basicLight;
    `
  })
);
