import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import config from '../buildSettings.json';

export const initializeLogRocket = () => {
  // https://docs.logrocket.com/docs/development#section-disabling-log-rocket-in-development
  if (process.env.NODE_ENV === 'production') {
    LogRocket.init(config.REACT_APP_LOGROCKET_ID as string);
    setupLogRocketReact(LogRocket);
  }
};

// TODO: change the type once we have user logins/signup/cookies setup
export const logRocketIdentify = (user: any) => {
  if (process.env.NODE_ENV === 'production') {
    // TODO: implement how we identify the user in logrocket
    // example below

    // LogRocket.identify(user.id, {
    //   name: 'James Morrison',
    //   email: 'jamesmorrison@example.com',
    //   // Add your own custom user variables here, ie:
    //   subscriptionType: 'pro'
    // });
    LogRocket.identify(user.id, user);
  }
};

export const logRocketInfo = (message: string, tags?: any, extra?: any) => {
  if (process.env.NODE_ENV === 'production') {
    LogRocket.captureMessage(message, {
      // additional data to be grouped as "tags"
      tags,
      // additional arbitrary data associated with the event
      extra
    });
  }
};

export const logRocketError = (err: Error, tags?: any, extra?: any) => {
  if (process.env.NODE_ENV === 'production') {
    LogRocket.captureException(err, {
      // additional data to be grouped as "tags"
      tags,
      // additional arbitrary data associated with the event
      extra
    });
  }
};

export const logRocketEvent = (event: string) => {
  if (process.env.NODE_ENV === 'production') {
    LogRocket.track(event);
  }
};
