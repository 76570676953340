export interface IEquipmentInner {
  equipmentId: number;
  serialNumber: string;
  equipmentNumber: string;
  equipmentType: string;
  equipmentTypeDescription: string;
  equipmentTypeCode: string;
  equipmentTypeId: number;
  bottlerId: number;
  programId: number;
  outletId: string;
  outletNumber: string;
  outletName: string;
  outletStreet: string;
  outletCity: string;
  outletState: string;
  outletPostalCode: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  outletPhoneNumber: string;
  outletContactName: null | string;
  noResults?: boolean;
  serviceCodeDescription: string;
  serviceCodeFrenchDescription: string;
}

export interface IEquipmentType {
  id: number;
  code: string;
  frenchDescription: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface IEquipmentProblemsInner {
  bottlerId: number;
  description: string;
  frenchDescription: string;
  equipmentTypeId: number;
  id: number;
  priorityId: number;
  problemCode: string;
  equipmentTypeCode: string;
  equipmentTypeDescription: string;
  equipmentType: string;
}

export interface IEquipmentProblemsBase {
  statusCode: number;
  message: string;
  equipmentProblems: IEquipmentProblemsInner[];
}

export interface IEquipmentRepairRequest {
  id: number;
  equipmentId: number;
  serialNumber: string;
  equipmentNumber: string;
  equipmentType: string;
  equipmentTypeDescription: string;
  equipmentTypeCode: string;
  equipmentTypeId: number;
  programId: number;
  outletId: string;
  outletNumber: string;
  outletName: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  outletContactPhone: string;
  outletContactName: null | string;
  equipmentRepairTicketNumber: string;
  status: string;
  requestDescription: string;
  requestSummary: string;
  availableDays: string;
  availabilityHoursStart: string;
  availabilityHoursEnd: string;
  equipmentProblemDescription: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  contactRole: string;
  language: string;
}

export enum EquipmentTypes {
  Freestyle = 1,
  Fountain = 2,
  Bargun = 3,
  JuiceDispenser = 4,
  Vending = 5,
  Cooler = 6,
  TeaUrn = 7,
  Project = 8,
  PostMix = 9,
  PreMix = 10,
  VenderGlassFront = 11,
  IceMachine = 13,
  VendingMachine = 14,
  YourAccount = 17
}
