import React, { FC, useContext, useState, useEffect } from 'react';
// components
import { AssetContent } from '../components/asset-number';
import { Page, PageTitle } from '../components/skeleton';
import { Box } from '@xstyled/styled-components';
// hoc
import { withTracker } from '../services/ga';
import { Language } from '../context/language';
import { useGlobalState } from '../stateManagement';

const AssetNumber: FC = () => {
  const [subHeading, setSubHeading] = useState<string>('');
  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  const { setAccount, setEquipment } = useGlobalState();

  useEffect(() => {
    // if account is set when you come to the page, reset it on load
    setAccount(undefined);
    // reset equipment
    setEquipment(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      secCol={
        <Box padding={{ xs: 'm', md: '0' }} paddingTop="0" paddingBottom="0">
          <PageTitle>
            {isFrench ? 'Par numéro d’équipement ' : 'By Asset Number '}
            {subHeading && `- ${subHeading}`}
          </PageTitle>
          <AssetContent setSubHeading={setSubHeading} />
        </Box>
      }
    />
  );
};

export default withTracker(AssetNumber);
