import React from 'react';
import { Box } from '@xstyled/styled-components';
// components
import { TextField, Text } from '../common';
import { CopyExample } from '../kitchen-sink/Copy';

export const TextFieldExample = () => {
  return (
    <Box marginTop="x1" marginBottom="x1">
      <hr />
      <Text forwardedAs="h2" marginBottom="s">
        Text Field
      </Text>
      <TextField label="Phone Number" required marginTop="s" />
      <CopyExample>{`<TextField label="Phone Number" required marginTop="s" />`}</CopyExample>
    </Box>
  );
};
