import { FC } from 'react';
import styled from '@xstyled/styled-components';
import { darken } from 'polished';
import { SystemProps } from '@xstyled/system';
import { LinkProps } from 'react-router-dom';
import { Link } from './Link';

interface IButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: any;
  type?: string;
  forwardedAs?: string;
  disabled?: boolean;
  id?: string;
}

export const Button: FC<SystemProps & IButtonProps> = styled.buttonBox`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: s;
  ${(p: any) =>
    p.disabled
      ? `
    background-color: ${p.theme.colors.grayLight};
    pointer-events: none;
    transition: all 100ms linear;
  `
      : ``}
  &:hover {
    background-color: ${(p: any) => darken(0.05, p.theme.colors[p.backgroundColor] || p.theme.colors.primary)};
    transition: all 100ms linear;
  }
  ${(p: any) =>
    p.backgroundColor === 'primary'
      ? `
    &:focus {
      outline-color: ${darken(0.25, p.theme.colors[p.backgroundColor])};
    }
    `
      : `
    &:focus {
      outline-color: ${p.theme.colors.primary};
    }
    `}
`;

export const ButtonLink: FC<SystemProps & IButtonProps & LinkProps> = styled(Link)`
  ${(p: any) =>
      p.disabled
        ? `
    background-color: ${p.theme.colors.grayLight};
    pointer-events: none;
    transition: all 100ms linear;
  `
        : ``}
    :hover {
    text-decoration: none;
    background-color: ${(p: any) => darken(0.05, p.theme.colors[p.backgroundColor] || p.theme.colors.primary)};
  }
`;

export const ClickableSpan = styled.spanBox`
  cursor: pointer;
`;
