import React, { FC, useContext, useEffect } from 'react';
import { IDropdownOption } from '@fluentui/react';
import { useMedia } from 'react-use';
// components
import { Text, Expand, ItemValue, Button, TextField, Dropdown } from '../common';
import { Box } from '@xstyled/styled-components';
import { IEquipmentInner } from '../../models';
// helpers
import { states } from '../../helpers';
import { IEquipmentFormValues, IAddtionalInfoFormValues } from '../../pages/SubmitRequest';
import { Language } from '../../context/language';
import { useGlobalState } from '../../stateManagement';

interface IEquipmentInformationProps {
  equipment: IEquipmentInner;
  isFLO?: boolean;
  isEquipmentConfirmed: boolean;
  setEquipmentConfirmed: () => void;
  isEquipmentForm: boolean;
  setEquipmentForm: (val: boolean) => void;
  handleToggle: () => void;
  values: IAddtionalInfoFormValues & IEquipmentFormValues;
  errors: {
    locationName?: string;
    street?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
  setFieldValue: (field: any, val: any) => void;
  equipmentInitialFormValues: IEquipmentFormValues;
  resetForm: () => void;
}

export const EquipmentInformation: FC<IEquipmentInformationProps> = ({
  equipment,
  isFLO,
  isEquipmentConfirmed,
  setEquipmentConfirmed,
  isEquipmentForm,
  setEquipmentForm,
  handleToggle,
  values,
  errors,
  setFieldValue,
  resetForm
}) => {
  const isMobile = useMedia('(max-width: 768px)');
  const hasDefaultValues = values.street && values.city && values.state && values.zipCode && Object.values(errors).filter(Boolean).length === 0;
  const isValid = isFLO ? hasDefaultValues && values.locationName : hasDefaultValues;
  const { isFrench } = useContext(Language);
  const { isAccountFlow } = useGlobalState();

  useEffect(() => {
    if (isAccountFlow) {
      setEquipmentConfirmed();
      setEquipmentForm(true);
    }
  }, [isAccountFlow, setEquipmentConfirmed, setEquipmentForm]);

  return (
    <>
      <Expand isExpanded={!isEquipmentConfirmed} showExpandButton={isEquipmentConfirmed && !!isValid} handleToggle={handleToggle}>
        <Text forwardedAs="h3" marginBottom="m">
          {isFrench ? 'Information de l’équipement' : 'Equipment Information'}
        </Text>
        <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <ItemValue
            systemProps={{
              width: { xs: 'auto', md: '14rem' },
              color: 'black',
              marginBottom: { xs: 'm', md: '0' }
            }}
            title={isFrench ? 'Type d’’équipement' : 'Equipment Type'}
            value={equipment?.equipmentType}
          />
          <ItemValue title={isFrench ? '#Équipement' : 'Asset#'} value={equipment?.serialNumber} />
        </Box>
        {!isEquipmentConfirmed && (
          <ItemValue
            systemProps={{ marginBottom: 'm', marginTop: 'm', color: 'black' }}
            title={isFrench ? 'EMPLACEMENT DE L’ÉQUIPEMENT' : 'Equipment Location'}
            value={
              <Text color="primary" forwardedAs="span">
                {isEquipmentForm
                  ? isFrench
                    ? "Veuillez mettre à jour l'adresse de l'emplacement  de l'équipement"
                    : 'Please update the equipment address'
                  : isFrench
                  ? `Veuillez confirmer qu'il s'agit de la bonne adresse où se trouve l'équipement`
                  : 'Please confirm if this is the correct address where the equipment is located'}
              </Text>
            }
          />
        )}
        {!isEquipmentForm ? (
          <>
            <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }} marginTop="m">
              <ItemValue systemProps={{ width: { xs: 'auto', md: '14rem' }, marginBottom: { xs: 'm', md: '0' } }} title={isFrench ? 'Rue' : 'Street'} value={values.street} />
              <ItemValue systemProps={{ width: { xs: 'auto', md: '7rem' }, marginBottom: { xs: 'm', md: '0' } }} title={isFrench ? 'Ville' : 'City'} value={values.city} />
              <ItemValue
                systemProps={{ width: { xs: 'auto', md: '6rem' }, marginBottom: { xs: 'm', md: '0' } }}
                title={isFrench ? 'Province' : 'State/Prov'}
                value={values.state}
              />
              <ItemValue systemProps={{ width: { xs: 'auto', md: '8rem' } }} title={isFrench ? 'Code Postal' : 'ZIP/Postal Code'} value={values.zipCode} />
            </Box>
          </>
        ) : (
          <>
            {values.locationName !== null && (
              <TextField
                required={true}
                width="100%"
                errorMessage={errors && errors.locationName && isFrench ? 'Requis' : ((errors && errors.locationName && 'Required') as string)}
                marginBottom={{ xs: 's', md: '0' }}
                marginTop="s"
                onChange={(e: any) => {
                  setFieldValue('locationName', e.target.value);
                }}
                autoComplete="nope"
                label={isFrench ? 'Nom de l’emplacement' : 'Location Name'}
                value={values.locationName}
              />
            )}
            <Box display="flex" justifyContent={{ xs: 'center', md: 'space-between' }} flexDirection={{ xs: 'column', md: 'row' }} marginTop="m" width="100%">
              <TextField
                required={true}
                width={{ xs: '100%', md: '48%' } as any}
                errorMessage={errors && errors.street && isFrench ? 'Requis' : ((errors && errors.street && 'Required') as string)}
                marginBottom={{ xs: 's', md: '0' }}
                onChange={(e: any) => {
                  setFieldValue('street', e.target.value);
                }}
                label={isFrench ? 'Rue' : 'Street'}
                autoComplete="nope"
                value={values.street}
              />
              <TextField
                required={true}
                width={{ xs: '100%', md: '48%' } as any}
                errorMessage={errors && errors.city && isFrench ? 'Requis' : ((errors && errors.city && 'Required') as string)}
                marginBottom={{ xs: 's', md: '0' }}
                onChange={(e: any) => {
                  setFieldValue('city', e.target.value);
                }}
                label={isFrench ? 'Ville' : 'City'}
                autoComplete="nope"
                value={values.city}
              />
            </Box>
            <Box display="flex" justifyContent={{ xs: 'center', md: 'space-between' }} flexDirection={{ xs: 'column', md: 'row' }} marginTop={{ xs: '0', md: 'm' }} width="100%">
              <Dropdown
                selectedKey={values.state || null}
                onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
                  setFieldValue('state', option?.key || values.state);
                }}
                placeholder="Select State"
                width={{ xs: '100%', md: '48%' }}
                label={isFrench ? 'Province' : 'State/Prov'}
                options={states}
                marginBottom={{ xs: 's', md: '0' }}
                errorMessage={errors && errors.state && isFrench ? 'Requis' : ((errors && errors.state && 'Required') as string)}
                required={true}
              />
              <TextField
                required={true}
                width={{ xs: '100%', md: '48%' } as any}
                errorMessage={
                  errors && errors.zipCode && errors.zipCode.includes('Invalid') && isFrench
                    ? 'Non valide'
                    : errors && errors.zipCode && isFrench
                    ? 'Requis'
                    : ((errors && errors.zipCode) as string)
                }
                onChange={(e: any) => {
                  setFieldValue('zipCode', e.target.value);
                }}
                label={isFrench ? 'Code Postal ' : 'ZIP/Postal Code'}
                autoComplete="nope"
                value={values.zipCode}
              />
            </Box>
          </>
        )}
      </Expand>
      {!isEquipmentConfirmed && !isEquipmentForm && (
        <Box display="flex" marginTop="x1" alignItems="center" justifyContent={{ xs: 'space-between' }} marginBottom="m">
          <Button color="white" width="48%" backgroundColor="primary" onClick={setEquipmentConfirmed}>
            {isMobile ? (isFrench ? 'Confirmation' : 'Confirm') : isFrench ? 'Confirmation – Adresse de l’équipement' : 'Confirm - Equipment Address'}
          </Button>
          <Button color="black" width="48%" backgroundColor="grayLight" onClick={() => setEquipmentForm(true)}>
            {isMobile ? (isFrench ? 'incorrecte' : 'Incorrect') : isFrench ? 'Adresse de l’équipement incorrecte' : 'Equipment Address Incorrect'}
          </Button>
        </Box>
      )}
      {isEquipmentForm && !isEquipmentConfirmed && (
        <Box display="flex" marginTop="x1" alignItems="center" justifyContent={{ xs: 'space-between' }} marginBottom="m">
          <Button
            onClick={setEquipmentConfirmed}
            disabled={!isValid}
            color="white"
            width={isFLO ? '100%' : '48%'}
            backgroundColor={isValid && values.locationName ? 'primary' : 'grayLight'}
          >
            {isFrench ? 'Poursuivre' : 'Continue'}
          </Button>
          {!isFLO && (
            <Button
              color="black"
              width="48%"
              backgroundColor="grayLight"
              type="button"
              onClick={() => {
                setEquipmentForm(false);
                resetForm();
              }}
            >
              {isFrench ? 'Annuler' : 'Cancel'}
            </Button>
          )}
        </Box>
      )}
    </>
  );
};
