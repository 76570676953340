import React, { FC, useContext, useEffect, useState } from 'react';
// components
import { PhoneContent } from '../components/phone-number';
import { Box } from '@xstyled/styled-components';
import { withTracker } from '../services';
import { Language } from '../context/language';
import { useGlobalState } from '../stateManagement';
import { PageTitle, Page } from '../components/skeleton';

const PhoneNumber: FC = () => {
  const [heading, setHeading] = useState<string>('');
  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  const { setAccountFlow, setAccount, setEquipment } = useGlobalState();

  useEffect(() => {
    // if account is set when you come to the page, reset it on load
    setAccount(undefined);
    setAccountFlow(false);
    // reset equipment
    setEquipment(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      secCol={
        <Box padding={{ xs: 'm', md: '0' }} paddingTop="0" paddingBottom="0">
          <PageTitle>
            {isFrench ? 'Par numéro de téléphone' : 'By Phone Number'} {heading ? `- ${heading}` : ''}
          </PageTitle>
          <PhoneContent setHeading={setHeading} />
        </Box>
      }
    />
  );
};

export default withTracker(PhoneNumber);
