import React, { FC, useContext, useState, useEffect } from 'react';
import { useMedia } from 'react-use';
// components
import { Page, PageTitle } from '../components/skeleton';
import { Box } from '@xstyled/styled-components';
import { ScanBarcodeContent } from '../components/scan-barcode';
import { withTracker } from '../services';
import { Language } from '../context/language';
import { useGlobalState } from '../stateManagement';

const ScanBarcode: FC = () => {
  const isTabletOrDesktop = useMedia('(min-width: 768px)');
  const [heading, setHeading] = useState<string>('');
  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  const { setAccountFlow, setAccount, setEquipment } = useGlobalState();

  useEffect(() => {
    // if account is set when you come to the page, reset it on load
    setAccount(undefined);
    setAccountFlow(false);
    setEquipment(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      secCol={
        <>
          {(isTabletOrDesktop || heading) && (
            <Box padding={{ xs: 'x1', md: '0' }} paddingTop="0" paddingBottom="0">
              <PageTitle>
                {isFrench ? 'Scanner le code à barres' : 'Scan Barcode'} {heading ? `- ${heading}` : ''}
              </PageTitle>
            </Box>
          )}
          <ScanBarcodeContent setHeading={setHeading} />
        </>
      }
    />
  );
};

export default withTracker(ScanBarcode);
