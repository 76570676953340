import React, { FC } from 'react';
import { Text } from '../common';

interface IPageTitle {
  children: any;
}

export const PageTitle: FC<IPageTitle> = ({ children }) => {
  return (
    <Text forwardedAs="h1" marginTop="m" color="primary" fontWeight="200" marginBottom="m" fontSize={{ xs: '1.5rem', md: '2.25rem' }}>
      {children}
    </Text>
  );
};
