// alot of this was taken from here
// https://github.com/ericblade/quagga2-react-example/blob/master/src/components/Scanner.js
// Another helpful article, https://www.oberhofer.co/mediastreamtrack-and-its-capabilities/
import { useCallback, useEffect } from 'react';
import Quagga from '@ericblade/quagga2';

function getMedian(arr: any) {
  arr.sort((a: any, b: any) => a - b);
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2 === 1) {
    return arr[half];
  }
  return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes: any) {
  const errors = decodedCodes.filter((x: any) => x.error !== undefined).map((x: any) => x.error);
  return getMedian(errors);
}

interface IScannerProps {
  onDetected: (val: string) => void;
  scannerRef: any;
  isTorchOn: boolean;
  hasTouchedTorch: boolean;
}

export const Scanner = ({ onDetected, scannerRef, isTorchOn, hasTouchedTorch }: IScannerProps) => {
  //eslint-disable-next-line
  const sanityCheck = (codeResult:string) => codeResult.match(/^[A-Za-z0-9]+$/);

  const errorCheck = useCallback(
    result => {
      if (!onDetected) {
        return;
      }
      const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
      // if Quagga is at least 75% certain that it read correctly and the code is in
      // the expected alpha-numeric format, then accept the code.
      if (err < 0.25 && sanityCheck(result.codeResult.code)) {
        onDetected(result.codeResult.code);
      }
    },
    [onDetected]
  );

  const handleProcessed = (result: any) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    drawingCtx.font = '24px Arial';
    drawingCtx.fillStyle = 'green';

    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width') as any), parseInt(drawingCanvas.getAttribute('height') as any));
        result.boxes
          .filter((box: any) => box !== result.box)
          .forEach((box: any) => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: 'purple', lineWidth: 2 });
          });
      }
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: 'blue', lineWidth: 2 });
      }
      if (result.codeResult && result.codeResult.code) {
        const validated = !!result.codeResult.code;
        Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: validated ? 'green' : 'red', lineWidth: 3 });
        drawingCtx.font = '24px Arial';
        drawingCtx.fillStyle = validated ? 'green' : 'red';
        drawingCtx.fillText(`${result.codeResult.code} valid: ${validated}`, 10, 50);
        drawingCtx.fillText(result.codeResult.code, 10, 20);
      }
    }
  };

  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          constraints: {
            width: {
              min: 640
            },
            height: { min: 480 },
            facingMode: 'environment',
            aspectRatio: { min: 1, max: 2 }
          },
          target: scannerRef.current
        },
        locator: {
          patchSize: 'medium',
          halfSample: true
        },
        numOfWorkers: navigator.hardwareConcurrency || 0,
        locate: true
      },
      err => {
        Quagga.onProcessed(handleProcessed);

        if (err) {
          return console.log('Error starting Quagga:', err);
        }
        if (scannerRef && scannerRef.current) {
          Quagga.start();
        }
      }
    );
    Quagga.onDetected(errorCheck);
    const toggleTorch = (val: boolean) => {
      // need to set a timeout so that `track` is not null
      setTimeout(() => {
        const track = Quagga.CameraAccess.getActiveTrack();
        if (track && typeof track.getCapabilities === 'function') {
          // @ts-ignore
          track.applyConstraints({ advanced: [{ torch: val }] });
        }
      }, 1000);
    };
    // turn torch on
    if (hasTouchedTorch && isTorchOn) {
      toggleTorch(true);
    }
    // turn torch off
    if (hasTouchedTorch && !isTorchOn) {
      toggleTorch(false);
    }
    return () => {
      Quagga.offDetected(errorCheck);
      Quagga.offProcessed(handleProcessed);
      Quagga.stop();
    };
  }, [errorCheck, scannerRef, isTorchOn, hasTouchedTorch]);
  return null;
};
