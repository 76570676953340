import React, { FC, useContext, useEffect, useState } from 'react';
// components
import { NoResult, Text } from '../components/common';
import { Page, PageTitle } from '../components/skeleton';
import { Box } from '@xstyled/styled-components';
// hoc
import { withTracker } from '../services/ga';
import { AccountContent } from '../components/account-number';
import { Language } from '../context/language';
import { ZipCodeLookupToggle } from '../components/zip-code';
import { useGlobalState } from '../stateManagement';

const NonEquipAccountSearch: FC = () => {
  const [heading, setHeading] = useState<string>('');
  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  const [accountNumberError, setAccountNumberError] = useState<any>(null);
  const [accountNumber, setAccountNumber] = useState('');

  const { isAccountFlow, setAccountFlow, setAccount, setEquipment } = useGlobalState();

  useEffect(() => {
    // if account is set when you come to the page, reset it on load
    setAccount(undefined);
    // reset equipment
    setEquipment(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAccountFlow(true);
    setAccountNumberError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber]);

  return (
    <Page
      secCol={
        <>
          <Box padding={{ xs: 'm', md: '0' }} paddingTop="0" paddingBottom="0" marginLeft={{ xs: 0, md: '-10px' }}>
            <PageTitle>{isFrench ? 'Trouver votre compte' : 'Find your Account'}</PageTitle>
            <>
              <Text
                forwardedAs={isAccountFlow ? 'h5' : 'h1'}
                marginTop="m"
                color="primary"
                fontWeight={isAccountFlow ? '200' : '300'}
                marginBottom="m"
                fontSize={{ xs: '1.25rem', md: 'xxl' }}
              >
                {isFrench ? 'Par numéro de compte ' : 'By Account Number '}
              </Text>
              <AccountContent setHeading={setHeading} heading={heading} hasError={setAccountNumberError} setAccountNumber={setAccountNumber} />
            </>
          </Box>
          {accountNumberError &&
            (isFrench ? (
              <NoResult>
                Aucun résultat trouvé pour le numéro de compte {accountNumber}. Veuillez réessayer de rechercher pour un numéro de compte différent. Veuillez{' '}
                <ZipCodeLookupToggle /> si vous ne trouvez pas votre compte.
              </NoResult>
            ) : (
              <NoResult>
                No result found for Account Number {accountNumber}. Please try searching again for a different account number. Please <ZipCodeLookupToggle /> if you are unable to
                find your account.
              </NoResult>
            ))}
        </>
      }
    />
  );
};

export default withTracker(NonEquipAccountSearch);
