import React, { FC, useState, useEffect, useContext } from 'react';
import to from 'await-to-js';
import { useMedia } from 'react-use';
import { useHistory } from 'react-router-dom';
import styled, { Box } from '@xstyled/styled-components';
// components
import { TextField, Button, Text, Link, Loader, NoResult, Centered, ErrorMessage } from '../common';
import { DisabledAppModal } from '../skeleton';
import { AccountListPhone, NotListedAccountModal } from '../account-number';
import { IOutletsBase, IOutletInner, IBottlerAccess } from '../../models';
import { fetchAccounts } from '../../fetch';
import { useGlobalState } from '../../stateManagement';
import { formatInputPhoneNumber, DEFAULT_OFFSET, DEFAULT_LIMIT } from '../../helpers';
import { EquipmentLookupTypes } from '../../models/equipment-lookup-type';
import { Apps } from '../../models/app';
import { postLogin } from '../../fetch/login';
import { setLoginId, removeLoginId } from '../../services';
import { BottlersAccess } from '../../context/bottlersAccess';
import { Language } from '../../context/language';

interface IPhoneContent {
  setHeading: (val: string) => void;
  hasResults?: (hasResults: boolean) => void;
  hasError?: (error: boolean) => void;
  setPhoneNumber?: (number: string) => void;
}

export const PhoneContent: FC<IPhoneContent> = ({ setHeading, hasResults, hasError, setPhoneNumber }) => {
  const [value, setValue] = useState<string>('');
  const [result, setResult] = useState<IOutletsBase | null>(null);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [isFetchingMore, setFetchingMore] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [isShowingAlert, showAlertModal] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [selectedOutlet, showDisabledAppModal] = useState<IOutletInner | null>(null);
  // hooks
  const { setAccount, isAccountFlow } = useGlobalState();
  const isTabletOrDesktop = useMedia('(min-width: 768px)');
  const history = useHistory();

  const handleSearch = async (_offset?: number) => {
    hasResults && hasResults(true);
    setPhoneNumber && setPhoneNumber(value);
    if (_offset) {
      setFetchingMore(true);
      const [, data] = await to(fetchAccounts(`phoneNumber=${value}&offset=${_offset}`));
      setFetchingMore(false);
      if (data && data.err) {
        hasResults && hasResults(false);
        return setError(data.err);
      }
      if (data) {
        const login = await postLogin({ appId: Apps.COKE_SERVICES_APP, equipmentLookupTypeId: EquipmentLookupTypes.OUTLET_PHONE });
        if (login.res && login.res.login) {
          await setLoginId(login.res?.login?.id);
        }

        if (result && result.outlets && data.res && data.res.outlets) {
          return setResult({
            ...data.res,
            outlets: [...result.outlets, ...data.res.outlets]
          });
        }
      }
    }

    setResult(null);
    setFetching(true);
    setHeading('');
    // remove dashes from the phone number and just send the numbers i.e. 555-555-5555 -> 5555555555
    const [, data] = await to(fetchAccounts(`phoneNumber=${value.replace(/-/g, '')}`));
    setFetching(false);
    if (data && data.err) {
      hasResults && hasResults(false);
      return setError(data.err);
    }
    if (data) {
      const login = await postLogin({ appId: Apps.COKE_SERVICES_APP, equipmentLookupTypeId: EquipmentLookupTypes.OUTLET_PHONE });
      if (login.res && login.res.login) {
        await setLoginId(login.res?.login?.id);
      }
      if (data.res.outlets.length === 0) {
        hasResults && hasResults(false);
        setHeading('No Results');
      } else {
        setHeading(isFrench ? 'Résultats de recherche' : 'Search Results');
      }
      return setResult(data.res);
    }
  };

  useEffect(() => {
    removeLoginId();
  }, []);

  // length of the numbers plus the dashes in the string
  const isSearchingDisabled = !value || value.length !== 12 || isFetching;

  const { bottlersAccess } = useContext(BottlersAccess);
  const tempSelectedAccount: IOutletInner | undefined = result ? result.outlets.find(outlet => `${outlet.id}` === selectedAccount) : undefined;
  const selectedBottler: IBottlerAccess | undefined = bottlersAccess && tempSelectedAccount && bottlersAccess.find(access => access.bottlerId === tempSelectedAccount.bottlerId);

  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  return (
    <>
      <Box display="flex" width="100%">
        <SearchInput
          placeholder={isFrench ? 'Entrer un numéro de téléphone…' : 'Enter a phone number...'}
          width={isTabletOrDesktop ? '50%' : 'auto'}
          minWidth="1px"
          flex={isTabletOrDesktop ? '0 0 auto' : '1'}
          value={value}
          type="tel"
          disabled={isFetching}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === 13 && !isSearchingDisabled) {
              handleSearch();
            }
          }}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
            if (error) {
              setError(null);
            }
            if (result && result.outlets.length === 0) {
              setResult(null);
            }
            setValue(formatInputPhoneNumber(newValue) as string);
          }}
        />
        <Button
          backgroundColor={!isSearchingDisabled ? 'primary' : 'grayLight'}
          color="white"
          disabled={isSearchingDisabled}
          onClick={() => {
            setOffset(DEFAULT_OFFSET);
            handleSearch(0);
          }}
          width={{ xs: 'auto', sm: '50%', md: '47%' }}
        >
          {isFrench && (isTabletOrDesktop && isFrench ? `Rechercher le numéro de téléphone` : 'Rechercher')}
          {!isFrench && (isTabletOrDesktop && !isFrench ? 'Search Phone Number' : 'Search')}
        </Button>
      </Box>
      {!isFetching && result && result.outlets && result.outlets.length > 0 && (
        <Box marginRight={{ xs: '0', md: '1.25rem' }}>
          <Text marginTop="m" marginBottom="m">
            {isFrench ? 'Bonne nouvelle! Le numéro de téléphone que vous avez entré a été trouvé.' : `Good News! The phone number you entered was found.`}
          </Text>
          <Text marginBottom="m">
            {isFrench
              ? 'Veuillez confirmer que les informations de compte suivantes sont correctes:'
              : 'Please select the correct account below and click, then click on the continue button:'}
          </Text>
          <AccountListPhone
            outlets={result.outlets}
            handleSelected={val => {
              setSelectedAccount(val);
            }}
          />
          {result.total > DEFAULT_LIMIT && result.total > result.outlets.length && (
            <Centered>
              <Button
                padding="xs"
                display="inline-block"
                textAlign="center"
                disabled={isFetchingMore}
                onClick={async () => {
                  // because useState is async and we aren't calling handleSearch within a useEffect
                  // we can't rely on the offset state above having been changed in time for handleSearch
                  // so we need to pass the new offset directly in to handleSearch
                  const _offset = offset + DEFAULT_LIMIT;
                  setOffset(_offset);
                  await handleSearch(_offset);
                }}
                color={isFetchingMore ? 'white' : 'primary'}
                marginTop="m"
                marginBottom="m"
                backgroundColor={isFetchingMore ? 'grayLight' : 'white'}
                border={isFetchingMore ? '0' : 'basic'}
                borderColor={isFetchingMore ? '0' : 'primary'}
              >
                {isFrench && (isFetchingMore ? 'Téléchargement en cours...' : 'Télécharger plus')}
                {!isFrench && (isFetchingMore ? 'Loading...' : 'Load More')}
              </Button>
            </Centered>
          )}
          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={!selectedAccount}
              padding="xs"
              display="inline-block"
              textAlign="center"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                if (selectedBottler && !selectedBottler.isActive && tempSelectedAccount) {
                  return showDisabledAppModal(tempSelectedAccount);
                }
                if (selectedAccount !== '0' && tempSelectedAccount) {
                  setAccount(tempSelectedAccount);
                  if (isAccountFlow) {
                    return history.push('/submit-request');
                  }
                  return history.push('/select-equipment');
                }
                return showAlertModal(true);
              }}
              color="white"
              marginTop="m"
              marginBottom="m"
              backgroundColor={selectedAccount || selectedAccount === '0' ? 'primary' : 'grayLight'}
            >
              {isFrench ? 'Poursuivre' : 'Continue'}
            </Button>
          </Box>
        </Box>
      )}
      {isFetching && (
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" width="100%" height="x16">
          <Loader />
        </Box>
      )}
      {result && !isFetching && result.outlets && result.outlets.length === 0 && isAccountFlow && hasError && hasError(true)}
      {result && !isFetching && !isAccountFlow && result.outlets && result.outlets.length === 0 && (
        <>
          {isFrench && (
            <NoResult>
              Aucun résultat trouvé pour {value}. Veuillez réessayer de rechercher un autre numéro de téléphone, ou <Link to="/">sélectionnez une autre option</Link> si vous ne
              parvenez pas à trouver votre compte.
            </NoResult>
          )}
          {!isFrench && (
            <NoResult>
              No results found for {value}. Please try searching again for a different phone number, or <Link to="/">select a different option</Link> if you are unable to find your
              account.
            </NoResult>
          )}
        </>
      )}
      {!result && !isFetching && error && !isAccountFlow && error.message.includes('500') && (
        <ErrorMessage>
          {isFrench
            ? 'Désolé erreur de recherche par numéro de téléphone, veuillez actualiser et réessayer.'
            : 'Sorry error searching by phone number, please refresh and try again.'}
        </ErrorMessage>
      )}
      <NotListedAccountModal isOpen={isShowingAlert} handleClose={() => showAlertModal(false)} />
      <DisabledAppModal isOpen={selectedOutlet ? true : false} selectedBottler={selectedBottler} selectedOutlet={selectedOutlet} handleClose={() => showDisabledAppModal(null)} />
    </>
  );
};

const SearchInput = styled(TextField)`
  && .ms-TextField-fieldGroup {
    height: 3rem;
  }
`;
