import React, { FC, useState, useEffect, useContext } from 'react';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import { ResponsiveMode, IDropdownOption } from '@fluentui/react';
import { useMedia } from 'react-use';
import styled, { Box } from '@xstyled/styled-components';
// components
import { Text, Modal, Centered, Dropdown, Button, RadioList, Label, Loader } from '../components/common';
import { Page, PageTitle } from '../components/skeleton';
import { NationalAccountModal } from '../components/account-number';
import { ConsentInfoModal, ConsentModal } from '../components/select-equipment';
// fetch
import { getEquipmentTypes, getEquipmentInOutlet } from '../fetch';
// models
import { IEquipmentType, IEquipmentInner, EquipmentTypes } from '../models';
// hooks
import { useGlobalState } from '../stateManagement';
// helpers
import { DEFAULT_LIMIT, DEFAULT_OFFSET, validEquipmentTypeIds } from '../helpers';
// services
import { withTracker } from '../services';
import { Language } from '../context/language';

const SelectEquipment: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [equipmentList, setEquipmentList] = useState<IEquipmentInner[]>([]);
  const [equipmentTypes, setEquipmentTypes] = useState<IEquipmentType[]>([]);
  const [shownEquipmentTypes, setShownEquipmentTypes] = useState<IEquipmentType[]>([]);
  const [showMessageModal, setMessageModal] = useState<boolean>(false);
  const [selectedEquipment, setSelectedEquipment] = useState<string>('');
  const [selectedEquipmentType, setSelectedEquipmentType] = useState<{
    id: string;
    description: string;
  }>({
    id: '',
    description: ''
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showConsentInfoModal, setConsentInfoModal] = useState<boolean>(false);
  const [showConsentModal, setConsentModal] = useState<boolean>(false);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(DEFAULT_OFFSET);
  const [isFetchingMore, setFetchingMore] = useState(false);
  // hooks
  const history = useHistory();
  const { account, setEquipment } = useGlobalState();
  const isMobile = useMedia('(min-width: 500px)');

  // for pagination, try account number 0600974722 (~1769) or 0500335524 (~43)
  const fetchEquipmentAndTypes = async () => {
    if (account) {
      const [, equipmentData] = await to(getEquipmentInOutlet(account.id, DEFAULT_LIMIT, offset, validEquipmentTypeIds));
      if (equipmentData && equipmentData.res) {
        if (isFetchingMore) {
          setCount(equipmentData.res.count);
          setEquipmentList([...equipmentList, ...equipmentData.res.equipment]);
        } else {
          setCount(equipmentData.res.count);
          setEquipmentList(equipmentData.res.equipment);
        }
      } else {
        setCount(0);
        setEquipmentList([]);
      }

      setIsLoading(false);

      // only fetch equipment types once on page load
      if (!isFetchingMore) {
        const [, data] = await to(getEquipmentTypes());
        if (data) {
          setEquipmentTypes(data.res);
          setShownEquipmentTypes(data.res);
        }
      }

      setFetchingMore(false);
    } else {
      history.replace('/');
    }
  };

  useEffect(() => {
    fetchEquipmentAndTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  const handleSelected = (key: string) => {
    // if "The equipment I'm looking for is not listed" is selected we only show bargun, cooler, fountain, or vending equipment types
    if (key && key === '0') {
      const _equipmentTypes = equipmentTypes.filter(
        equipmentType =>
          equipmentType.id === EquipmentTypes.Bargun ||
          equipmentType.id === EquipmentTypes.Cooler ||
          equipmentType.id === EquipmentTypes.Fountain ||
          equipmentType.id === EquipmentTypes.Vending
      );
      setShownEquipmentTypes(_equipmentTypes);
    } else {
      // the user selected a known equipment, so we put back all equipment types
      setShownEquipmentTypes(equipmentTypes);
    }
  };

  const handleEquipmentTypeChange = (_: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) => {
    if (option && option.key && shownEquipmentTypes && shownEquipmentTypes.length > 0) {
      setSelectedEquipmentType({
        id: option.key as string,
        description: option.text
      });
    }
  };
  const equipment = equipmentList.find(equip => `${equip.equipmentId}` === selectedEquipment);

  const { isFrench } = useContext(Language);

  return (
    <Page
      secCol={
        <>
          <Box padding={{ xs: 'm', md: '0' }} paddingTop="0" paddingBottom="0">
            <Box marginRight={{ xs: '0', md: 'm' }}>
              <PageTitle>
                {isFrench ? `Équipement chez ` : `Equipment in `}
                {account && account.outletName}
              </PageTitle>

              {isLoading ? (
                <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" width="100%" height="x16">
                  <Loader />
                </Box>
              ) : (
                <>
                  <RadioList
                    options={[
                      ...equipmentList.map(equipment => ({
                        key: `${equipment.equipmentId}`,
                        text: '',
                        styles: {
                          choiceFieldWrapper: {
                            width: '100%'
                          }
                        },
                        onRenderField: (props: any, render: any) => {
                          return (
                            <Box
                              position="relative"
                              display="flex"
                              padding={{ xs: 'xs', md: 'm' }}
                              height={{ xs: '3rem', md: '3rem' }}
                              overflow={'hidden'}
                              paddingTop="m"
                              paddingBottom="x1"
                            >
                              <Box marginTop="m" marginRight="m">
                                {render!(props)}
                              </Box>
                              <Box width={{ xs: '6rem', md: '12rem' }}>
                                <Label fontSize={{ xs: 'xs', md: 's' }}>{isFrench ? '#ÉQUIPEMENT' : 'Asset#'}</Label>
                                <Text fontSize={{ xs: 'xs', md: 's' }} fontWeight="bold">
                                  {equipment.serialNumber}
                                </Text>
                              </Box>
                              <Box>
                                <Label fontSize={{ xs: 'xs', md: 's' }}>{isFrench ? 'TYPE D’ÉQUIPEMENT' : 'Equipment Type'}</Label>
                                <Text fontSize={{ xs: 'xs', md: 's' }} fontWeight="bold">
                                  {equipment.equipmentType}
                                </Text>
                              </Box>
                            </Box>
                          );
                        }
                      })),
                      isFrench
                        ? {
                            key: '0',
                            text: `L’équipement que je recherche n'est pas répertorié`,
                            styles: {
                              choiceFieldWrapper: {
                                width: '100%'
                              }
                            },
                            onRenderField: (props: any, render: any) => {
                              return (
                                <NotListWrapper padding={{ xs: 'xs', md: 'm' }} display="flex" paddingTop="m" alignItems="flex-start">
                                  {render!(props)}
                                </NotListWrapper>
                              );
                            }
                          }
                        : {
                            key: '0',
                            text: `The equipment I'm looking for is not listed`,
                            styles: {
                              choiceFieldWrapper: {
                                width: '100%'
                              }
                            },
                            onRenderField: (props: any, render: any) => {
                              return (
                                <NotListWrapper padding={{ xs: 'xs', md: 'm' }} display="flex" paddingTop="m" alignItems="flex-start">
                                  {render!(props)}
                                </NotListWrapper>
                              );
                            }
                          }
                    ]}
                    selectedKey={selectedEquipment}
                    onChange={(_, option) => {
                      if (option) {
                        setSelectedEquipment(option.key);
                        handleSelected(option.key);
                      }
                    }}
                  />
                  {count > DEFAULT_LIMIT && equipmentList.length < count && (
                    <Centered>
                      <Button
                        padding="xs"
                        display="inline-block"
                        textAlign="center"
                        disabled={isFetchingMore}
                        onClick={async () => {
                          setFetchingMore(true);

                          const _offset = offset + DEFAULT_LIMIT;
                          setOffset(_offset);
                        }}
                        color={isFetchingMore ? 'white' : 'primary'}
                        marginTop="m"
                        marginBottom="m"
                        backgroundColor={isFetchingMore ? 'grayLight' : 'white'}
                        border={isFetchingMore ? '0' : 'basic'}
                        borderColor={isFetchingMore ? '0' : 'primary'}
                      >
                        {isFrench && (isFetchingMore ? 'Téléchargement en cours...' : 'Télécharger plus')}
                        {!isFrench && (isFetchingMore ? 'Loading...' : 'Load More')}
                      </Button>
                    </Centered>
                  )}
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      disabled={!selectedEquipment}
                      padding="xs"
                      display="inline-block"
                      textAlign="center"
                      onClick={(e: any) => {
                        e.preventDefault();
                        if (selectedEquipment !== '0') {
                          const isNationalAccount =
                            account?.marketingId &&
                            account?.marketingId?.charAt(0) !== '2' &&
                            (equipment?.equipmentTypeId === EquipmentTypes.Fountain ||
                              equipment?.equipmentTypeId === EquipmentTypes.PostMix ||
                              equipment?.equipmentTypeId === EquipmentTypes.PreMix);

                          if (isNationalAccount) {
                            return setMessageModal(true);
                          }
                          const isCharged = equipment?.serviceCodeDescription;
                          if (isCharged) {
                            return setConsentModal(true);
                          }
                          setEquipment({
                            ...equipment
                          } as IEquipmentInner);
                          history.push('/submit-request');
                        } else {
                          setIsOpen(true);
                        }
                      }}
                      color="white"
                      marginTop="m"
                      marginBottom="m"
                      backgroundColor={selectedEquipment || selectedEquipment === '0' ? 'primary' : 'grayLight'}
                    >
                      {isFrench ? 'Poursuivre' : 'Continue'}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)} containerStyles={{ width: isMobile ? '25%' : '90%', marginTop: '-10%' }}>
            <Centered padding="x1" paddingLeft="m" paddingRight="m" textAlign="center" flexDirection="column">
              <Text marginBottom="x1" fontSize="m" width="80%">
                {isFrench
                  ? `Veuillez sélectionner un type d'équipement afin de créer votre demande de service.`
                  : `Please select an equipment type in order to create your service request.`}
              </Text>
              <Dropdown
                placeholder={isFrench ? 'Sélectionner un type d’équipement' : 'Select Equipment Type'}
                responsiveMode={ResponsiveMode.large}
                marginTop="s"
                minWidth="180px"
                selectedKey={selectedEquipmentType.id}
                options={shownEquipmentTypes.map((type, i) => ({ key: `${type.id}`, text: isFrench ? type.frenchDescription : type.description }))}
                onChange={handleEquipmentTypeChange}
              />
              <Box display="flex" justifyContent="center" marginTop="x2" width={{ xs: '100%', md: '80%' }}>
                <Button
                  color="white"
                  width="30%"
                  marginRight="s"
                  backgroundColor={selectedEquipmentType.id ? 'primary' : 'grayLight'}
                  onClick={() => {
                    const isNationalAccount =
                      account?.marketingId &&
                      account?.marketingId?.charAt(0) !== '2' &&
                      (Number(selectedEquipmentType.id) === EquipmentTypes.Fountain ||
                        Number(selectedEquipmentType.id) === EquipmentTypes.PostMix ||
                        Number(selectedEquipmentType.id) === EquipmentTypes.PreMix);
                    if (isNationalAccount) {
                      return setMessageModal(true);
                    }
                    // only show for canada accounts
                    if (account?.bottlerId === 6) {
                      return setConsentModal(true);
                    }
                    setEquipment({
                      ...equipment,
                      equipmentType: selectedEquipmentType.description,
                      equipmentTypeId: Number(selectedEquipmentType.id)
                    } as IEquipmentInner);
                    history.push('/submit-request');
                  }}
                  disabled={!selectedEquipmentType.id}
                >
                  Ok
                </Button>
                <Button
                  backgroundColor="grayLighter"
                  color="black"
                  onClick={() => {
                    setIsOpen(false);
                    setSelectedEquipmentType({
                      id: '',
                      description: ''
                    });
                  }}
                >
                  {isFrench ? 'Annuler' : 'Cancel'}
                </Button>
              </Box>
            </Centered>
          </Modal>
          <NationalAccountModal isOpen={showMessageModal} handleClose={() => setMessageModal(false)} />
          <ConsentInfoModal
            isOpen={showConsentInfoModal}
            handleClose={() => {
              history.push('/');
              setConsentInfoModal(false);
            }}
          />
          <ConsentModal
            message={
              equipment
                ? isFrench
                  ? equipment.serviceCodeFrenchDescription
                  : equipment.serviceCodeDescription
                : isFrench
                ? 'Veuillez noter que notre politique de service a changé, ce qui pourrait entraîner des frais pour cette activité de service. En sélectionnant « Consentement » ci-dessous, vous acceptez les frais potentiels, le cas échéant.'
                : 'Please be advised that our service policy has changed that may result in a charge for this service activity.  By selecting “Consent” below, you are agreeing to potential charge(s), if applicable, by the service technician.'
            }
            isOpen={showConsentModal}
            handleClose={type => {
              if (type === 'Cancel') {
                setConsentModal(false);
                return setConsentInfoModal(true);
              } else {
                if (selectedEquipmentType.description) {
                  setIsOpen(false);
                  setEquipment({
                    ...equipment,
                    equipmentType: selectedEquipmentType.description,
                    equipmentTypeId: Number(selectedEquipmentType.id)
                  } as IEquipmentInner);
                } else {
                  setEquipment({
                    ...equipment
                  } as IEquipmentInner);
                }
              }
              history.push('/submit-request');
              setConsentModal(false);
            }}
          />
        </>
      }
    />
  );
};

const NotListWrapper = styled(Box)`
  span {
    margin-left: m;
    font-weight: bold;
  }
`;

export default withTracker(SelectEquipment);
