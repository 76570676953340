import React, { FC, useContext, useState } from 'react';
import { useMedia } from 'react-use';
// components
import { Text, RadioList, Label, IconButton } from '../common';
import styled, { Box } from '@xstyled/styled-components';
// models
import { IOutletInner } from '../../models';
// helpers
import { formatAccountNumber } from '../../helpers';
import { Language } from '../../context/language';

interface IAccountList {
  outlets: IOutletInner[];
  handleSelected: (id: string) => void;
}

const ListPhoneItem = ({ value, title, width }: { value: string; title: string; width?: any }) => {
  return (
    <Box marginLeft={{ xs: 'xs', md: 'm' }} width={width || 'auto'}>
      <Label fontSize={{ xs: 'xs', md: 's' }}>{title}</Label>
      <Text fontSize={{ xs: 'xs', md: 's' }} fontWeight="bold">
        {value}
      </Text>
    </Box>
  );
};

const ListPhoneSubItem = ({ title, value, fontSize, marginLeft = '0' }: { value: string; title: string; fontSize: string; marginLeft?: string }) => {
  return (
    <Box marginLeft={marginLeft}>
      <Label fontSize={fontSize}>{title}</Label>
      <Text fontSize={fontSize} fontWeight="bold">
        {value}
      </Text>
    </Box>
  );
};

export const AccountListPhone: FC<IAccountList> = ({ outlets, handleSelected }) => {
  const [isToggled, toggleMore] = useState<{ [id: string]: boolean }>({});
  const [selectedAccount, setSelectedAccount] = useState<string>('');

  const isTabletOrDesktop = useMedia('(min-width: 768px)');

  const { isFrench } = useContext(Language);

  return (
    <RadioList
      options={[
        ...outlets.map(outlet => ({
          key: `${outlet.id}`,
          text: '',
          styles: {
            choiceFieldWrapper: {
              width: '100%'
            }
          },
          onRenderField: (props: any, render: any) => {
            return (
              <Box
                position="relative"
                padding={{ xs: 'xs', md: 'm' }}
                height={isToggled[outlet.id] ? { xs: '13rem', md: '10rem' } : { xs: '3rem', md: '3rem' }}
                overflow={isToggled[outlet.id] ? 'visible' : 'hidden'}
                paddingTop="m"
                paddingBottom="x1"
              >
                <Box display="flex" alignItems="flex-start">
                  <Box marginTop="m">{render!(props)}</Box>
                  {outlet.phoneNumber && <ListPhoneItem width="x6" title={isFrench ? '#TÉLÉPHONE' : 'Phone#'} value={outlet.phoneNumber} />}
                  <ListPhoneItem title={isFrench ? 'Nom' : 'Name'} value={outlet.outletName} width={{ xs: '9rem', sm: 'auto' }} />
                </Box>
                <Box marginTop="m" marginLeft={{ xs: '2rem', md: '2.5rem' }} display={isToggled[outlet.id] ? 'block' : 'none'}>
                  {isTabletOrDesktop ? (
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                      <ListPhoneSubItem fontSize="s" title={isFrench ? 'Rue' : 'Street'} value={outlet.street} />
                      <ListPhoneSubItem fontSize="s" title={isFrench ? 'Ville' : 'City'} value={outlet.city} />
                      <ListPhoneSubItem fontSize="s" title={isFrench ? 'Province' : 'State/Prov'} value={outlet.state} />
                      <ListPhoneSubItem fontSize="s" title={isFrench ? 'Code Postal ' : 'ZIP/Postal Code'} value={outlet.postalCode} />
                    </Box>
                  ) : (
                    <>
                      <Box display="flex" alignItems="flex-start">
                        <ListPhoneSubItem fontSize="xs" title={isFrench ? 'Rue' : 'Street'} value={outlet.street} />
                        <ListPhoneSubItem marginLeft="xs" fontSize="xs" title={isFrench ? 'Ville' : 'City'} value={outlet.city} />
                      </Box>
                      <Box display="flex" alignItems="flex-end" marginTop="m">
                        <ListPhoneSubItem fontSize="xs" title={isFrench ? 'Province' : 'State/Prov'} value={outlet.state} />
                        <ListPhoneSubItem marginLeft="xs" fontSize="xs" title={isFrench ? 'Code Postal ' : 'ZIP/Postal Code'} value={outlet.postalCode} />
                      </Box>
                    </>
                  )}
                  <Box marginTop={{ xs: 'xs', md: 'm' }}>
                    <Label fontSize={{ xs: 'xs', md: 's' }}>{isFrench ? '#Compte' : 'Account#'}</Label>
                    <Text fontSize={{ xs: 'xs', md: 's' }} fontWeight="bold">
                      {formatAccountNumber(outlet.outletNumber)}
                    </Text>
                  </Box>
                </Box>
                <IconButton
                  backgroundColor="transparent"
                  color="primary"
                  position="absolute"
                  right="5px"
                  top="5px"
                  padding="0"
                  iconProps={{
                    iconName: isToggled[outlet.id] ? 'ChevronDown' : 'ChevronUp'
                  }}
                  onClick={() => toggleMore({ ...isToggled, [outlet.id]: !isToggled[outlet.id] })}
                />
              </Box>
            );
          }
        })),
        {
          key: '0',
          text: isFrench ? "Le compte que je recherche, n'est pas répertorié" : `The account I'm looking for is not listed`,
          styles: {
            choiceFieldWrapper: {
              width: '100%'
            }
          },
          onRenderField: (props: any, render: any) => {
            return (
              <NotListWrapper padding={{ xs: 'xs', md: 'm' }} display="flex" paddingTop="m" alignItems="flex-start">
                {render!(props)}
              </NotListWrapper>
            );
          }
        }
      ]}
      selectedKey={selectedAccount}
      onChange={(e, option) => {
        if (option) {
          setSelectedAccount(option.key);
          handleSelected(option.key);
        }
      }}
    />
  );
};

export const AccountListAddress: FC<IAccountList> = ({ outlets, handleSelected }) => {
  const [isToggled, toggleMore] = useState<{ [id: string]: boolean }>({});
  const [selectedAccount, setSelectedAccount] = useState<string>('');

  const isTabletOrDesktop = useMedia('(min-width: 768px)');

  const { isFrench } = useContext(Language);

  return (
    <RadioList
      options={[
        ...outlets.map(outlet => ({
          key: `${outlet.id}`,
          text: '',
          styles: {
            choiceFieldWrapper: {
              width: '100%'
            }
          },
          onRenderField: (props: any, render: any) => {
            return (
              <Box
                position="relative"
                padding={{ xs: 'xs', md: 'm' }}
                height={isToggled[outlet.id] ? { xs: '10rem', md: '8rem' } : { xs: '2.5rem', md: '3rem' }}
                overflow={isToggled[outlet.id] ? 'visible' : 'hidden'}
                paddingTop="m"
                paddingBottom="m"
              >
                {isTabletOrDesktop ? (
                  <Box display="flex">
                    <Box marginTop="m" marginRight="m">
                      {render!(props)}
                    </Box>
                    <Box display="flex" alignItems="center" width="100%">
                      <Box width={{ xs: 'auto', md: '13rem' }} marginRight="s">
                        <Label fontSize="s">{isFrench ? 'Rue' : 'Street'}</Label>
                        <Text fontSize="s" fontWeight="bold">
                          {outlet.street}
                        </Text>
                      </Box>
                      <Box width="7rem">
                        <Label fontSize="s">{isFrench ? 'Ville' : 'City'}</Label>
                        <Text fontSize="s" fontWeight="bold">
                          {outlet.city}
                        </Text>
                      </Box>
                      <Box width="3.5rem">
                        <Label fontSize="s">{isFrench ? 'Province' : 'State/Prov'}</Label>
                        <Text fontSize="s" fontWeight="bold">
                          {outlet.state}
                        </Text>
                      </Box>
                      <Box marginTop="0">
                        <Label fontSize="s">{isFrench ? 'Code Postal' : 'ZIP/Postal Code'}</Label>
                        <Text fontSize="s" fontWeight="bold">
                          {outlet.postalCode}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex">
                    <Box marginTop="m">{render!(props)}</Box>
                    <Box display="flex" alignItems="flex-start" width={{ xs: 'auto', sm: '100%' }}>
                      <Box width={{ xs: 'auto', sm: '50%' }}>
                        <Label fontSize="xs">{isFrench ? 'Rue' : 'Street'}</Label>
                        <Text fontSize="xs" fontWeight="bold">
                          {outlet.street}
                        </Text>
                      </Box>
                      <Box marginLeft="xs">
                        <Label fontSize="xs">{isFrench ? 'Ville' : 'City'}</Label>
                        <Text fontSize="xs" fontWeight="bold">
                          {outlet.city}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {!isTabletOrDesktop && (
                  <Box display="flex" marginTop="m" marginLeft={{ xs: '1.5rem', md: '2.5rem' }}>
                    <Box width={{ xs: 'auto', sm: '50%', md: 'auto' }}>
                      <Label fontSize="xs">{isFrench ? 'Province' : 'State/Prov'}</Label>
                      <Text fontSize="xs" fontWeight="bold">
                        {outlet.state}
                      </Text>
                    </Box>
                    <Box marginLeft="xs">
                      <Label fontSize="xs">{isFrench ? 'Code Postal' : 'ZIP/Postal Code'}</Label>
                      <Text fontSize="xs" fontWeight="bold">
                        {outlet.postalCode}
                      </Text>
                    </Box>
                  </Box>
                )}
                <Box display="flex" marginTop="m" marginLeft={{ xs: '1.5rem', md: '2.5rem' }}>
                  <Box width={{ xs: 'auto', sm: '50%', md: '13rem' }} marginRight={{ xs: '0', md: 's' }}>
                    <Label fontSize={{ xs: 'xs', md: 's' }}>{isFrench ? 'NOM' : 'Name'}</Label>
                    <Text fontSize={{ xs: 'xs', md: 's' }} fontWeight="bold">
                      {outlet.outletName}
                    </Text>
                  </Box>
                  <Box marginLeft={{ xs: 'xs', md: '0' }}>
                    <Label fontSize={{ xs: 'xs', md: 's' }}>{isFrench ? '#Compte' : 'Account#'}</Label>
                    <Text fontSize={{ xs: 'xs', md: 's' }} fontWeight="bold">
                      {formatAccountNumber(outlet.outletNumber)}
                    </Text>
                  </Box>
                </Box>
                <IconButton
                  backgroundColor="transparent"
                  color="primary"
                  position="absolute"
                  right="5px"
                  top="5px"
                  padding="0"
                  iconProps={{
                    iconName: isToggled[outlet.id] ? 'ChevronDown' : 'ChevronUp'
                  }}
                  onClick={() => toggleMore({ ...isToggled, [outlet.id]: !isToggled[outlet.id] })}
                />
              </Box>
            );
          }
        })),
        {
          key: '0',
          text: isFrench ? "Le compte que je recherche, n'est pas répertorié" : `The account I'm looking for is not listed`,
          styles: {
            choiceFieldWrapper: {
              width: '100%'
            }
          },
          onRenderField: (props: any, render: any) => {
            return (
              <NotListWrapper padding={{ xs: 'xs', md: 'm' }} display="flex" paddingTop="m" alignItems="flex-start">
                {render!(props)}
              </NotListWrapper>
            );
          }
        }
      ]}
      selectedKey={selectedAccount}
      onChange={(e, option) => {
        if (option) {
          setSelectedAccount(option.key);
          handleSelected(option.key);
        }
      }}
    />
  );
};

const NotListWrapper = styled(Box)`
  span {
    margin-left: m;
    font-weight: bold;
  }
`;
