// @ts-ignore
import { ThemeProvider } from '@xstyled/styled-components';
import React, { ReactElement } from 'react';
import { Router } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import { initializeIcons } from '@uifabric/icons';
import { render } from '@testing-library/react';
import theme from '../theme';

interface Props {
  children: React.ReactNode;
}

export const renderWithProviders = (ui: ReactElement<any>) => {
  initializeIcons();
  setWindowToDesktop();
  const Providers = ({ children }: Props) => {
    return (
      <Router history={createMemoryHistory()}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </Router>
    );
  };
  // @ts-ignore
  return render(ui, { wrapper: Providers });
};

export const setWindowToDesktop = () => {
  if (process.env.NODE_ENV === 'test') {
    window.matchMedia = jest.fn().mockImplementation(query => {
      return {
        matches: true,
        media: query,
        onchange: null,
        addListener: jest.fn(), // deprecated
        removeListener: jest.fn(), // deprecated
        addEventListener: jest.fn(),
        removeEventListener: jest.fn(),
        dispatchEvent: jest.fn()
      };
    });
  }
};
