import React, { FC } from 'react';
import styled from '@xstyled/styled-components';
import { system, SystemProps } from '@xstyled/system';
import { Checkbox as OfficeCheckbox, ICheckboxProps } from '@fluentui/react';

export const Checkbox: FC<SystemProps & ICheckboxProps> = styled((props: SystemProps & ICheckboxProps) => {
  return (
    <OfficeCheckbox
      {...props}
      styles={({ checked }) => ({
        root: {
          selectors: {
            '.ms-Checkbox-checkbox': {
              backgroundColor: checked ? props.color || '#e01b26' : 'white',
              borderColor: checked && (props.color || '#e01b26')
            },
            '.ms-Checkbox-checkbox:hover': {
              backgroundColor: checked ? '#c91822' : '',
              borderColor: checked ? '#c91822' : ''
            },
            '&:hover .ms-Checkbox-checkbox': {
              backgroundColor: checked && (props.color || '#e01b26'),
              borderColor: checked && (props.color || '#e01b26')
            }
          }
        }
      })}
    />
  );
})`
  ${system}
`;
