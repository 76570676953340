import React, { FC, useContext } from 'react';
// components
import { Page, PageTitle } from '../components/skeleton';
import { Box } from '@xstyled/styled-components';
// hoc
import { withTracker } from '../services/ga';
import { Language } from '../context/language';

import { HomeButton } from '../components/common/HomeButton';

const Home: FC = () => {
  const { isFrench } = useContext(Language);
  return (
    <Page
      secCol={
        <Box padding={{ xs: 'm', md: '0' }} paddingTop="0" paddingBottom="0" marginLeft={{ xs: 0, md: '-10px' }}>
          <PageTitle>{isFrench ? 'Bonjour. Avec quoi pouvons-nous vous aider?' : 'Hello. What can we help you with?'}</PageTitle>
          <HomeButton
            isAccountFlow={false}
            title={isFrench ? 'Votre équipement' : 'Your Equipment'}
            subtitle={isFrench ? 'Demande de réparation, déplacement, retrait ou remplissage' : 'Request repair, removal, move or fill'}
            iconName="DeveloperTools"
            link="/equipment-request"
          />
          <HomeButton
            isAccountFlow={true}
            title={isFrench ? 'Votre compte' : 'Your Account'}
            subtitle={isFrench ? 'Information concernant la livraison de produits, contrat, prix et factures.' : 'Inquire about product delivery, contract, pricing and invoices.'}
            iconName="Feedback"
            link="/account-request"
          />
        </Box>
      }
    />
  );
};

export default withTracker(Home);
