import React, { useContext, FC } from 'react';
import styled from '@xstyled/styled-components';
import { system, SystemProps } from '@xstyled/system';
import { Spinner as OfficeSpinner, ISpinnerProps, SpinnerSize } from '@fluentui/react';
import { Language } from '../../context/language';

export const Loader: FC<SystemProps & ISpinnerProps> = styled((props: SystemProps & ISpinnerProps) => {
  const { isFrench } = useContext(Language);
  return <OfficeSpinner label={isFrench ? 'Téléchargement en cours...' : 'Loading...'} labelPosition="right" size={SpinnerSize.large} {...props} />;
})`
  ${system}
`;
