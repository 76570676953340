import styled, { Box } from '@xstyled/styled-components';
import React, { FC } from 'react';
// components
import { Modal, Text } from '../common';
import { PrivacyPolicyContent } from './PrivacyPolicyContent';

interface IPrivacyPolicyModal {
  isOpen: boolean;
  handleClose: () => void;
}

export const PrivacyPolicyModal: FC<IPrivacyPolicyModal> = ({ isOpen, handleClose }) => {
  return (
    <Modal containerStyles={{ width: 'calc(100% - 32px)' }} isOpen={isOpen} onDismiss={handleClose}>
      <StyledBox>
        <Text forwardedAs="h2" marginTop="m" color="primary" fontWeight="300" marginBottom="m" fontSize={{ xs: '1.25rem', md: 'xxxl' }}>
          Privacy Policy
        </Text>
        <PrivacyPolicyContent />
      </StyledBox>
    </Modal>
  );
};

const StyledBox = styled(Box)`
  overflow: hidden;
  padding: 1rem 2.5rem 2rem 1rem;
`;
