import React, { FC, useContext } from 'react';
import { useMedia } from 'react-use';
import { Box } from '@xstyled/styled-components';
import { Language } from '../../context/language';
import { Button, Centered, Modal, Text } from '../common';

interface IConsentInfoModal {
  isOpen: boolean;
  handleClose: () => void;
}

export const NonEquipmentEmployeeModal: FC<IConsentInfoModal> = ({ isOpen, handleClose }) => {
  const isDesktop = useMedia('(min-width: 500px)');
  const { isFrench } = useContext(Language);

  return (
    <Modal isOpen={isOpen} onDismiss={handleClose} containerStyles={{ width: isDesktop ? '30%' : '90%' }}>
      <Centered padding="x1" paddingLeft="m" paddingRight="m" textAlign="center" flexDirection="column">
        <Text forwardedAs="h2" marginBottom="x1" width="80%" fontWeight="300">
          {isFrench
            ? 'Les demandes liées au compte sont réservées aux clients. Veuillez suivre votre processus interne pour ce type de demandes.'
            : 'Account related requests are for customers only. Please follow your internal process for these types of requests.'}
        </Text>

        <Box display="flex" justifyContent="center" marginTop="x2" width={{ xs: '100%', md: '80%' }}>
          <Button backgroundColor="primary" color="white" width="30%" marginRight="s" onClick={handleClose}>
            Ok
          </Button>
        </Box>
      </Centered>
    </Modal>
  );
};
