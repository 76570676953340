import React, { FC, useContext } from 'react';
// images
import barCodeImage from '../../static/barcodeExample.png';
import { Box } from '@xstyled/styled-components';
import { Image, Text } from '../common';
import { Language } from '../../context/language';

interface IBarcodeExampleProps {
  isColumn?: boolean;
  message?: string;
}

export const BarcodeExample: FC<IBarcodeExampleProps> = ({
  isColumn,
  message = `The equipment barcode can be located on the front or side of the machine. Please be sure to scan the barcode located to the right of the asset code/equipment number (as highlighted in red in the barcode image sample on this page).`
}) => {
  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  return (
    <Box
      display="flex"
      flexDirection={isColumn ? 'column' : { xs: 'column', md: 'row' }}
      marginBottom="x1"
      position="relative"
      marginTop={{ xs: 'm', md: '0' }}
      marginRight={{ xs: '0', md: 'm' }}
      alignItems="center"
    >
      <Image alt="barcode example" src={barCodeImage} width={{ xs: '16rem', md: '17rem' }} />
      <Text padding="0.25rem" marginLeft={{ xs: '0', md: 'm' }} marginTop={isColumn ? 'm' : { xs: 'm', md: '0' }} lineHeight="1.25" fontSize="s" maxWidth="x24">
        {isFrench
          ? `Le code à barres de l'équipement peut être situé à l'avant ou sur le côté de la machine. Assurez-vous de scanner le code à barres situé à droite du numéro d'équipement (comme surligné en rouge sur l’étiquette en exemple sur cette page)`
          : message}
      </Text>
    </Box>
  );
};
