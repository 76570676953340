import React, { FC, useContext, useEffect, useState } from 'react';
import { IDropdownOption, Label } from '@fluentui/react';
import { Field, FieldProps } from 'formik';
import to from 'await-to-js';
import styled from '@xstyled/styled-components';
import { useHistory } from 'react-router-dom';
// components
import { Text, Expand, Button, TextField, Dropdown, Checkbox, ErrorMessage } from '../common';
import { Box } from '@xstyled/styled-components';
import { EmployeeEmailModal } from './EmployeeEmailModal';
import { NonEquipmentEmployeeModal } from './NonEquipmentEmployeeModal';
// helpers
import { formatInputPhoneNumber } from '../../helpers';
// fetch
import { getContactRoles, fetchValidateEmployeeEmail } from '../../fetch';
// modales
import { ContactRoles, IContactRoleInner } from '../../models';
import { IAddtionalInfoFormValues, IEquipmentFormValues, IContactInfoFormValues } from '../../pages/SubmitRequest';
import { Language } from '../../context/language';

interface IContactInformationProps {
  setContactInfoConfirmed: () => void;
  isContactInfoConfirmed: boolean;
  errors: {
    street?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    contactFirstName?: string;
    contactLastName?: string;
    contactEmail?: string;
    contactPhone?: string;
    contactRole?: string;
    contactNotifications?: string;
    employeeName?: string;
    employeeEmail?: string;
    employeePhone?: string;
    employeeNotifications?: string;
  };
  currentFormErrors: {
    contactFirstName?: string;
    contactLastName?: string;
    contactEmail?: string;
    contactPhone?: string;
    contactRole?: string;
    contactNotifications?: string;
    employeeName?: string;
    employeeEmail?: string;
    employeePhone?: string;
    employeeNotifications?: string;
  };
  isEmployeeRole: boolean;
  values: IAddtionalInfoFormValues & IEquipmentFormValues & IContactInfoFormValues;
  setEmployeeEmail: (val: string) => void;
  setContactRole: (val: string) => void;
}

export const ContactInformation: FC<IContactInformationProps> = ({
  values,
  isContactInfoConfirmed,
  setContactInfoConfirmed,
  currentFormErrors,
  errors,
  isEmployeeRole,
  setEmployeeEmail,
  setContactRole
}) => {
  const isValidCustomer =
    !!values.contactFirstName && !!values.contactLastName && !!values.contactPhone && (values.contactNotifications.includes('Email') ? !!values.contactEmail : true);
  const isValidEmployee = isEmployeeRole && !!values.employeeEmail && !!values.employeeName && !!values.employeePhone && values.employeeNotifications.length > 0 && isValidCustomer;
  const isValid = (isValidCustomer || isValidEmployee) && Object.values(errors).filter(Boolean).length === 0;

  const [contactRoles, setContactRoles] = useState<
    | {
        key: string;
        frenchDescription: string;
        description: string;
      }[]
    | undefined
  >([]);
  const [error, setError] = useState<Error | null>(null);
  const [isShowingEmployeeModal, setShowingEmployeeModal] = useState<boolean>(false);
  const [isShowingNonEquipmentEmployeeModal, setShowingNonEquipmentEmployeeModal] = useState<boolean>(false);
  const [hasValidEmployeeEmail, setHasValidEmployeeEmail] = useState<boolean | undefined>();
  const [isShowingEmployeeError, setIsShowingEmployeeError] = useState<boolean>(false);

  const { isFrench } = useContext(Language);
  const history = useHistory();

  useEffect(() => {
    const fetchContactRoles = async () => {
      const [, data] = await to(getContactRoles());
      if (data && data.err) {
        return setError(data.err);
      }
      return setContactRoles(
        data?.res.map((role: IContactRoleInner) => ({
          key: `${role.id}`,
          frenchDescription: `${role.frenchName} - ${role.frenchDescription}`,
          description: `${role.name} - ${role.description}`
        }))
      );
    };
    fetchContactRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkEmployeeEmail = async () => {
    const [, data] = await to(fetchValidateEmployeeEmail(values.employeeEmail));

    if (data?.res?.length > 0) {
      setHasValidEmployeeEmail(true);
      setIsShowingEmployeeError(false);
    } else {
      setIsShowingEmployeeError(true);
    }
  };

  // Pop open modal if "Coke Employee" role selected
  useEffect(() => {
    if (isEmployeeRole && !hasValidEmployeeEmail) {
      setShowingEmployeeModal(true);
    }
  }, [isEmployeeRole, hasValidEmployeeEmail]);

  return (
    <>
      <Expand isExpanded={!isContactInfoConfirmed} showExpandButton={isContactInfoConfirmed && Object.values(currentFormErrors).filter(Boolean).length === 0}>
        {!error && (
          <>
            <Text forwardedAs="h3" marginBottom="m">
              {isFrench ? 'Information du contact' : 'Contact Information'}
            </Text>
            <Field name="contactRole">
              {({ form, meta }: FieldProps) => {
                return (
                  <Dropdown
                    selectedKey={form.values.contactRole || null}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
                      if (option?.key !== ContactRoles.EMPLOYEE) {
                        // reset these form values if the user changes his mind after entering the fields for employee, but switches to customer
                        form.setFieldValue('employeeName', '');
                        form.setFieldValue('employeeEmail', '');
                        form.setFieldValue('employeePhone', '');
                        form.setFieldValue('employeeNotifications', []);
                        setIsShowingEmployeeError(false);
                      }
                      // reset the contact notifications if the user selects the checkboxes and then decides to switch to coke employee role
                      if (form.values.contactNotifications.includes('Text') && form.values.contactNotifications.includes('Email') && option?.key === ContactRoles.EMPLOYEE) {
                        form.setFieldValue('contactNotifications', ['Email']);
                      }
                      // reset the contact notifications if the user selects the checkboxes and then decides to switch to coke employee role
                      if (form.values.contactNotifications.includes('Text') && !form.values.contactNotifications.includes('Email') && option?.key === ContactRoles.EMPLOYEE) {
                        form.setFieldValue('contactNotifications', []);
                      }
                      form.setFieldValue('contactRole', (option?.key || form.values.contactRole) as string);
                    }}
                    placeholder={isFrench ? 'Sélectionner une option' : 'Select an option'}
                    width={{ xs: '100%' }}
                    label={isFrench ? 'Rôle du contact' : 'Contact Role'}
                    options={
                      contactRoles?.map(role => ({
                        key: role.key,
                        text: isFrench ? role.frenchDescription : role.description
                      })) as IDropdownOption[]
                    }
                    marginBottom={{ xs: 'x1' }}
                    errorMessage={meta.touched && meta.error && isFrench ? 'Requis' : ((meta.touched && meta.error) as string)}
                    required={true}
                  />
                );
              }}
            </Field>
            {isEmployeeRole && hasValidEmployeeEmail && (
              <>
                <Text forwardedAs="h4" marginBottom="m">
                  {isFrench ? 'Information de l’employé contact' : 'Employee Contact Information'}
                </Text>
                <Box
                  display="flex"
                  width="100%"
                  flexDirection={{ xs: 'column', md: 'row' }}
                  justifyContent={{ xs: 'center', md: 'space-between' }}
                  marginBottom={{ xs: '0', md: 's' }}
                >
                  <Field name="employeeName">
                    {({ field, meta }: FieldProps) => {
                      return (
                        <TextField
                          required={true}
                          width={{ xs: '100%', md: '48%' } as any}
                          errorMessage={meta.touched && meta.error && isFrench ? 'Requis' : ((meta.touched && meta.error) as string)}
                          marginBottom={{ xs: 's', md: '0' }}
                          label={isFrench ? 'Nom de l’employé' : 'Employee Name'}
                          {...field}
                        />
                      );
                    }}
                  </Field>
                  <Field name="employeeEmail">
                    {({ field, meta }: FieldProps) => {
                      return (
                        <TextField
                          required={true}
                          width={{ xs: '100%', md: '48%' } as any}
                          errorMessage={
                            isShowingEmployeeError
                              ? 'Please enter your Coca-Cola employee email address.'
                              : meta.touched && meta.error && meta.error.includes('Invalid') && isFrench
                              ? 'Non valide'
                              : meta.touched && meta.error && isFrench
                              ? 'Requis'
                              : ((meta.touched && meta.error) as string)
                          }
                          marginBottom={{ xs: 's', md: '0' }}
                          label={isFrench ? 'Courriel de l’employé' : 'Employee Email'}
                          {...field}
                          onBlur={e => {
                            field.onBlur(e);
                            checkEmployeeEmail();
                          }}
                        />
                      );
                    }}
                  </Field>
                </Box>
                <Box
                  display="flex"
                  width="100%"
                  flexDirection={{ xs: 'column', md: 'row' }}
                  justifyContent={{ xs: 'center', md: 'space-between' }}
                  marginBottom={{ xs: '0', md: 's' }}
                >
                  <Field name="employeePhone">
                    {({ form, meta }: FieldProps) => {
                      return (
                        <TextField
                          required={true}
                          width={{ xs: '100%', md: '48%' } as any}
                          errorMessage={
                            meta.touched && meta.error && meta.error.includes('Invalid') && isFrench
                              ? 'Non valide'
                              : meta.touched && meta.error && isFrench
                              ? 'Requis'
                              : ((meta.touched && meta.error) as string)
                          }
                          marginBottom={{ xs: 's', md: '0' }}
                          label={isFrench ? 'Numéro de tél. de l’employé contact' : 'Employee Contact Phone Number'}
                          type="tel"
                          value={form.values.employeePhone}
                          onBlur={() => {
                            form.setFieldTouched('employeePhone', true);
                          }}
                          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                            form.setFieldValue('employeePhone', formatInputPhoneNumber(newValue));
                          }}
                        />
                      );
                    }}
                  </Field>
                </Box>
                <Box marginBottom="x1">
                  <Label required>Employee Notification Settings</Label>
                  <Box display="flex" width={isFrench ? '10rem' : '9rem'} justifyContent="space-between">
                    <Field name="employeeNotifications">
                      {({ form }: FieldProps) => {
                        return (
                          <>
                            {['Email', 'Text'].map(noteType => {
                              const isSelected = form.values.employeeNotifications?.includes(noteType);
                              return (
                                <Checkbox
                                  key={noteType}
                                  label={isFrench ? (noteType === 'Text' ? 'Texto**' : 'Courriel') : noteType === 'Text' ? 'Text' : noteType}
                                  checked={isSelected}
                                  onChange={() => {
                                    form.setFieldValue(
                                      'employeeNotifications',
                                      isSelected
                                        ? form.values.employeeNotifications.filter((conNote: string) => conNote !== noteType)
                                        : [...(form.values.employeeNotifications || []), noteType]
                                    );
                                  }}
                                />
                              );
                            })}
                          </>
                        );
                      }}
                    </Field>
                  </Box>
                </Box>
              </>
            )}
            {isEmployeeRole && hasValidEmployeeEmail && (
              <Text forwardedAs="h4" marginBottom="m">
                {isFrench ? 'Information du contact client' : 'Customer Contact Information'}
              </Text>
            )}
            <Box display="flex" width="100%" flexDirection={{ xs: 'column', md: 'row' }} justifyContent={{ xs: 'center', md: 'space-between' }} marginBottom={{ xs: '0', md: 's' }}>
              <Field name="contactFirstName">
                {({ field, meta }: FieldProps) => {
                  return (
                    <TextField
                      required={true}
                      width={{ xs: '100%', md: '48%' } as any}
                      errorMessage={meta.touched && meta.error && isFrench ? 'Requis' : ((meta.touched && meta.error) as string)}
                      marginBottom={{ xs: 's', md: '0' }}
                      label={isEmployeeRole ? (isFrench ? 'Prénom du client' : 'Customer First Name') : isFrench ? 'Prénom du contact' : 'Contact First Name'}
                      {...field}
                    />
                  );
                }}
              </Field>

              <Field name="contactLastName">
                {({ field, meta }: FieldProps) => {
                  return (
                    <TextField
                      required={true}
                      width={{ xs: '100%', md: '48%' } as any}
                      errorMessage={meta.touched && meta.error && isFrench ? 'Requis' : ((meta.touched && meta.error) as string)}
                      marginBottom={{ xs: 's', md: '0' }}
                      label={isEmployeeRole ? (isFrench ? 'Nom du client' : 'Customer Last Name') : isFrench ? 'Nom du contact' : 'Contact Last Name'}
                      {...field}
                    />
                  );
                }}
              </Field>
            </Box>
            <Box display="flex" width="100%" flexDirection={{ xs: 'column', md: 'row' }} justifyContent={{ xs: 'center', md: 'space-between' }} marginBottom={{ xs: '0', md: 's' }}>
              <Field name="contactEmail">
                {({ field, meta, form }: FieldProps) => {
                  return (
                    <TextField
                      {...field}
                      width={{ xs: '100%', md: '48%' } as any}
                      errorMessage={
                        meta.touched && meta.error && meta.error.includes('Invalid') && isFrench
                          ? 'Non valide'
                          : meta.touched && meta.error && isFrench
                          ? 'Requis'
                          : ((meta.touched && meta.error) as string)
                      }
                      marginBottom={{ xs: 's', md: '0' }}
                      label={isEmployeeRole ? (isFrench ? 'Courriel du client' : 'Customer Email') : isFrench ? 'Courriel du contact' : 'Contact Email'}
                      required={form.values.contactNotifications.includes('Email') || false}
                      onChange={(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                        // remove spaces in email address field
                        const value = newValue ? newValue.replace(/ /g, '') : '';
                        form.setFieldValue('contactEmail', value);
                      }}
                    />
                  );
                }}
              </Field>
              <Field name="contactPhone">
                {({ meta, form, field }: FieldProps) => {
                  // had to use custom form setField function here instead of `field` props because was getting a weird
                  // `_` value being passed to the form so I need to remove that onChange
                  return (
                    <TextField
                      required={true}
                      width={{ xs: '100%', md: '48%' } as any}
                      errorMessage={
                        meta.touched && meta.error && meta.error.includes('Invalid') && isFrench
                          ? 'Non valide'
                          : meta.touched && meta.error && isFrench
                          ? 'Requis'
                          : ((meta.touched && meta.error) as string)
                      }
                      marginBottom={{ xs: 's', md: '0' }}
                      type="tel"
                      label={isEmployeeRole ? (isFrench ? 'Téléphone du client' : 'Customer Phone') : isFrench ? 'Téléphone du contact' : 'Contact Phone'}
                      value={form.values.contactPhone}
                      onBlur={() => {
                        form.setFieldTouched('contactPhone', true);
                      }}
                      onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                        form.setFieldValue('contactPhone', formatInputPhoneNumber(newValue));
                      }}
                    />
                  );
                }}
              </Field>
            </Box>
            <Box>
              <Label required={!isEmployeeRole}>
                {isEmployeeRole
                  ? isFrench
                    ? 'Paramètres de notification du client'
                    : 'Customer Notification Settings'
                  : isFrench
                  ? 'Paramètres de notification'
                  : 'Notification Settings'}
              </Label>
              <StyledText forwardedAs="p" fontSize="12px" marginBottom="m">
                {isFrench
                  ? "En cochant l'une de ces cases ou les deux, j'autorise Coca-Cola Business Services North America à me contacter par e-mail et texto (SMS). Aucun achat requis. Les tarifs de messagerie texte standard de votre opérateur sans fil peuvent s'appliquer."
                  : `By checking one or both of these boxes, I authorize Coca-Cola Business Services North America to contact me via email and text (SMS). No purchase required. Standard
                text messaging rates from your wireless carrier may apply`}
              </StyledText>
              <Box display="flex" width={isFrench ? '10rem' : '9rem'} justifyContent="space-between">
                <Field name="contactNotifications">
                  {({ form }: FieldProps) => {
                    return (
                      <>
                        {['Email', 'Text'].map(noteType => {
                          const isSelected = form.values.contactNotifications?.includes(noteType);
                          return (
                            <Checkbox
                              key={noteType}
                              label={isFrench ? (noteType === 'Text' ? 'Texto' : 'Courriel') : noteType === 'Text' ? 'Text' : noteType}
                              checked={isSelected}
                              disabled={isEmployeeRole && noteType === 'Text'}
                              onChange={() => {
                                form.setFieldValue(
                                  'contactNotifications',
                                  isSelected
                                    ? form.values.contactNotifications.filter((conNote: string) => conNote !== noteType)
                                    : [...(form.values.contactNotifications || []), noteType]
                                );
                              }}
                            />
                          );
                        })}
                      </>
                    );
                  }}
                </Field>
              </Box>
            </Box>
          </>
        )}
        {error && <ErrorMessage>{isFrench ? 'Erreur' : `Sorry, error showing contact information, please refresh and try again.`}</ErrorMessage>}
      </Expand>
      {!isContactInfoConfirmed && (
        <Box display="flex" marginTop="x1" alignItems="center" justifyContent={{ xs: 'center' }} marginBottom="m">
          <Button
            onClick={setContactInfoConfirmed}
            disabled={!isValid || isShowingEmployeeError}
            color="white"
            width="50%"
            backgroundColor={isValid && !isShowingEmployeeError ? 'primary' : 'grayLight'}
          >
            {isFrench ? 'Poursuivre' : 'Continue'}
          </Button>
        </Box>
      )}

      <EmployeeEmailModal
        isOpen={isShowingEmployeeModal}
        setShowingEmployeeModal={setShowingEmployeeModal}
        setShowingNonEquipmentEmployeeModal={setShowingNonEquipmentEmployeeModal}
        setHasValidEmployeeEmail={setHasValidEmployeeEmail}
        setEmployeeEmail={(val: string) => setEmployeeEmail(val)}
        setContactRole={(val: string) => setContactRole(val)}
      />
      <NonEquipmentEmployeeModal isOpen={isShowingNonEquipmentEmployeeModal} handleClose={() => history.replace('/')} />
    </>
  );
};

const StyledText = styled(Text)`
  font-style: italic;
`;
