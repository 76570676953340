import React, { FC } from 'react';
// components
import { Text, Icon, Link } from '../common';
import { darken } from 'polished';
import styled, { Box } from '@xstyled/styled-components';
import { useGlobalState } from '../../stateManagement';

interface IHomeButton {
  title: string;
  subtitle: string;
  iconName: string;
  link: string;
  isAccountFlow?: boolean;
}

export const HomeButton: FC<IHomeButton> = ({ title, subtitle, iconName, link, isAccountFlow }) => {
  const { setAccountFlow } = useGlobalState();

  return (
    <StyledLink onClick={() => (isAccountFlow ? setAccountFlow(true) : setAccountFlow(false))} to={link}>
      <Wrapper
        padding="m"
        position="relative"
        margin={{ xs: '1rem', sm: '1.5rem' }}
        marginRight={{ xs: '1rem', sm: '2rem' }}
        backgroundColor="primary"
        borderRadius="m"
        display="flex"
        alignItems={{ xs: 'center', sm: 'left' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
        color="white"
      >
        <Box>
          <Icon iconName={iconName} fontSize={{ xs: '4rem', sm: '5rem' }} marginBottom="xs" marginRight={{ xs: '0', sm: '1.2rem' }} color="white" />
        </Box>
        <Wrapper display="flex" flexDirection="column" justifyContent="center" width="100%">
          <Title forwardedAs="h2" fontSize={{ xs: '1rem', sm: '1.2rem' }} marginBottom="xs" textAlign={{ xs: 'center', sm: 'left' }} textTransform="uppercase">
            {title}
          </Title>
          <Text forwardedAs="p" fontSize={{ xs: '.85rem', sm: '1rem' }} textAlign={{ xs: 'center', sm: 'left' }}>
            {subtitle}
          </Text>
        </Wrapper>
      </Wrapper>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const Wrapper = styled(Box)`
  :hover {
    background-color: ${(p: any) => darken(0.05, p.theme.colors[p.backgroundColor] || p.theme.colors.primary)};
  }
`;

const Title = styled(Text)`
  white-space: nowrap;
`;
