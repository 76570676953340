import to from 'await-to-js';
import { axios } from '../helpers';
import { logEvent } from '../services';
import { IEquipmentInner, IEquipmentType, IEquipmentProblemsInner } from '../models';
import { filteredEquipmentTypes } from '../helpers';

export const getEquipmentByAssetId = async (barcodeValue: string, barcodeScan: boolean = false): Promise<{ err: Error | null; res: IEquipmentInner | { noResults: boolean } }> => {
  const apiRoute = `validateSerialNumber?serialNumber=${barcodeValue}&barcodeScan=${barcodeScan}`;
  const [err, res] = await to(axios.get(apiRoute, {}, 'v2'));
  if (err) {
    logEvent({ event: err, location: 'fetch/equipment.ts getEquipmentByAssetId' });
  }
  return { err, res: res && res.data && res.data.length >= 1 ? res.data[0] : { noResults: true } };
};

export const getEquipmentTypes = async (): Promise<{ err: Error | null; res: IEquipmentType[] }> => {
  const [err, res] = await to(axios.get('equipmentTypes'));

  if (err) {
    logEvent({ event: err, location: 'fetch/equipment.ts getEquipmentTypes' });
  }

  return { err, res: res ? res.data.equipmentTypes.filter((type: IEquipmentType) => !filteredEquipmentTypes.includes(type.description)) : null };
};

export const getEquipmentInOutlet = async (
  outletId: string | number,
  limit?: number,
  offset?: number,
  equipmentTypeId?: number | number[]
): Promise<{ err: Error | null; res: { count: number; equipment: IEquipmentInner[] } }> => {
  let url = `equipment?outletId=${outletId}`;
  if (equipmentTypeId !== undefined) {
    if (Array.isArray(equipmentTypeId)) {
      equipmentTypeId.map(id => (url += `&equipmentTypeId=${id}`));
    } else {
      url += `&equipmentTypeId=${equipmentTypeId}`;
    }
  }
  if (limit !== undefined && limit > -1) {
    url += `&limit=${limit}`;
  }
  if (offset !== undefined && offset > -1) {
    url += `&offset=${offset}`;
  }
  const [err, res] = await to(axios.get(url, {}, 'v3'));

  if (err) {
    logEvent({ event: err, location: 'fetch/equipment.ts getEquipmentInOutlet' });
  }

  return { err, res: res ? { count: res.data.count, equipment: res.data.equipment } : { count: 0, equipment: [] } };
};

export const getEquipmentProblems = async (equipmentId: string | number, bottlerId?: number): Promise<{ err: Error | null; res: IEquipmentProblemsInner[] }> => {
  const [err, res] = await to(axios.get(`equipmentProblems?equipmentTypeId=${equipmentId}&bottlerId=${bottlerId || 0}`, {}, 'v2'));

  if (err) {
    logEvent({ event: err, location: 'fetch/equipment.ts getEquipmentProblems' });
  }

  return { err, res: res ? res.data.equipmentProblems : null };
};
