import styled from '@xstyled/styled-components';
import { system, SystemProps } from '@xstyled/system';
import { Label as OfficeLabel, ILabelProps } from '@fluentui/react';
import { FC } from 'react';

export const Label: FC<SystemProps & ILabelProps> = styled(OfficeLabel)`
  ${system}
  font-weight: bold;
  color: grayDark;
  text-transform: uppercase;
`;
