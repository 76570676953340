import React from 'react';
import { Box } from '@xstyled/styled-components';
// components
import { Link, ExternalLink, Text } from '../common';
import { CopyExample } from '../kitchen-sink/Copy';

export const LinkExample = () => {
  return (
    <Box marginTop="x1" marginBottom="x1">
      <hr />
      <Text forwardedAs="h2" marginBottom="s">
        Link
      </Text>
      <Link to="/">Internal Link</Link>
      <CopyExample>{`<Link to='/'>Internal Link</Link>`}</CopyExample>
      <ExternalLink href="https://google.com" display="block">
        External Link
      </ExternalLink>
      <CopyExample>{`<ExternalLink href="https://google.com">External Link</ExternalLink>`}</CopyExample>
    </Box>
  );
};
