export const isNumber = (val: string): boolean => (val.match(/^\d+$/) ? true : false);

// equipment types that don't belong in the PWA, used for the select dropdown
export const filteredEquipmentTypes = ['FREESTYLE', 'PREMIX', 'PROJECT', 'VENDER,GLASS FRONT', 'VENDING MACHINE', 'TEA URN', 'CARD READER'];

// used to filter out these list from the select equipment page, the rest of the options will be mapped to a different equipment
export const filteredEquipmentOptions = ['FREESTYLE', 'PREMIX', 'PROJECT', 'TEA URN', 'CARD READER'];

enum EquipmentTypeIdEnum {
  FREESTYLE = 1,
  FOUNTAIN = 2,
  BARGUN = 3,
  JUICE_DISPENSER = 4,
  VENDING = 5,
  COOLER = 6,
  TEA_URN = 7,
  PROJECT = 8,
  POST_MIX = 9,
  PREMIX = 10,
  VENDER_GLASS_FRONT = 11,
  CARD_READER = 12,
  ICE_MACHINE = 13,
  VENDING_MACHINE = 14,
  YOUR_ACCOUNT = 17
}

// Valid equipmentTypeId's for SelectEquipment.tsx for calling /v3/equipment
export const validEquipmentTypeIds = [
  EquipmentTypeIdEnum.BARGUN,
  EquipmentTypeIdEnum.COOLER,
  EquipmentTypeIdEnum.ICE_MACHINE,
  EquipmentTypeIdEnum.JUICE_DISPENSER,
  EquipmentTypeIdEnum.POST_MIX,
  EquipmentTypeIdEnum.VENDER_GLASS_FRONT,
  EquipmentTypeIdEnum.VENDING,
  EquipmentTypeIdEnum.VENDING_MACHINE,
  EquipmentTypeIdEnum.YOUR_ACCOUNT
];
