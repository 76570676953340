import to from 'await-to-js';
import { axios } from '../helpers';
import { logEvent } from '../services';

export const fetchValidateEmployeeEmail = async (email: string): Promise<{ err: Error | null; res: any }> => {
  const domain = email.substring(email.lastIndexOf('@') + 1).toLowerCase();
  const [err, res] = await to(axios.get(`bottlerDomains?domain=${encodeURIComponent(domain)}`, {}, 'v2'));

  if (err) {
    logEvent({ event: err, location: 'fetch/validateEmployeeEmail.ts fetchValidateEmployeeEmail' });
  }

  return { err, res: res ? res?.data?.bottlerDomains : null };
};
