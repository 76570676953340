// https://www.smooth-code.com/open-source/xstyled/docs/theme-specification/
export default {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  borders: {
    basic: '1px solid black',
    basicLight: '1px solid #ccc',
    basicPrimary: '1px solid #e01b26'
  },
  colors: {
    // these were taken from the native app
    black: '#000000',
    blackTransparent: 'rgba(0, 0, 0, 0.9)',
    white: '#fff',
    gray: '#aaa',
    grayLightest: '#f4f4f4',
    grayLighter: '#eaeaea',
    grayLight: '#ccc',
    grayDark: '#777',
    grayDarker: '#555',
    grayDarkest: '#333',
    confirmation: '#587f48',
    primary: '#e01b26',
    error: '#d0021b',
    success: '#06EA66',
    warning: '#FFBE32',
    info: '#329FFF',
    //Fabric specific variables, based on colors above
    primaryLighterAlt: '#fef5f5',
    primaryLighter: '#fad7d9',
    primaryLight: '#f6b5b8',
    tertiary: '#ed7076',
    secondary: '#e4333c',
    primaryDarkAlt: '#ca1821',
    primaryDark: '#ab141c',
    primaryDarker: '#7e0f15',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e'
  },
  // rpx converts px to rems, https://www.smooth-code.com/open-source/xstyled/docs/px-vs-rem/
  sizes: {
    xxxs: '2rpx',
    xxs: '4rpx',
    xs: '8rpx',
    s: '12rpx',
    m: '16rpx',
    x1: '24rpx',
    x2: '32rpx',
    x3: '48rpx',
    x4: '64rpx',
    x6: '96rpx',
    x8: '128rpx',
    x12: '192rpx',
    x16: '256rpx',
    x20: '320rpx',
    x24: '384rpx',
    x32: '512rpx',
    x40: '640rpx',
    x48: '768rpx',
    x56: '896rpx'
  },
  // rpx converts px to rems, https://www.smooth-code.com/open-source/xstyled/docs/px-vs-rem/
  space: {
    xxxs: '2rpx',
    xxs: '4rpx',
    xs: '8rpx',
    s: '12rpx',
    m: '16rpx',
    x1: '24rpx',
    x2: '32rpx',
    x3: '48rpx',
    x4: '64rpx',
    x6: '96rpx',
    x8: '128rpx',
    x12: '192rpx',
    x16: '256rpx',
    x24: '384rpx',
    x32: '512rpx',
    x40: '640rpx',
    x48: '768rpx',
    x56: '896rpx'
  },
  radii: {
    s: '2rpx',
    m: '4rpx',
    l: '10rpx'
  },
  fonts: {
    secondary: "'Alike', serif;",
    microsoft: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`
  },
  fontSizes: {
    xs: '12rpx',
    s: '14rpx',
    m: '16rpx',
    l: '18rpx',
    xl: '20rpx',
    xxl: '24rpx',
    xxxl: '30rpx',
    xxxxl: '46rpx'
  },
  fontWeights: {
    basic: 400,
    bold: 600
  }
};
