import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Language } from '../../context/language';
import { useGlobalState } from '../../stateManagement';
import { Nav } from './Nav';

interface INavigationProps {
  showNavigation: boolean;
}

export const Navigation: FC<INavigationProps> = ({ showNavigation }) => {
  const { isFrench } = useContext(Language);
  const { setNav, isEquipmentNavOpen, isAccountRequestNavOpen } = useGlobalState();
  const history = useHistory();

  return showNavigation ? (
    <Nav
      ariaLabel="Navigation"
      // click on the actual link to go to the new page
      onLinkClick={(e: any, item) => {
        e && e.preventDefault();
        if (item) {
          setNav({
            isEquipmentNavOpen: item && item.url === '/equipment-request' ? true : isEquipmentNavOpen,
            isAccountRequestNavOpen: item && item.url === '/account-request' ? true : isAccountRequestNavOpen
          });
          history.push(item.url);
        }
      }}
      // click on the chevron to the right of the group link
      onLinkExpandClick={(e: any, item) => {
        e && e.preventDefault();
        if (item) {
          setNav({
            isEquipmentNavOpen: item && item.url === '/equipment-request' ? !isEquipmentNavOpen : isEquipmentNavOpen,
            isAccountRequestNavOpen: item && item.url === '/account-request' ? !isAccountRequestNavOpen : isAccountRequestNavOpen
          });
        }
      }}
      groups={[
        {
          links: [
            {
              name: isFrench ? 'Menu principal' : 'Home',
              url: '/',
              key: 'Home',
              forceAnchor: true
            },
            {
              name: isFrench ? 'Demande d’équipement' : 'Equipment Request',
              expandAriaLabel: 'Expand Equipment Request',
              url: '/equipment-request',
              collapseAriaLabel: 'Collapse Equipment Request',
              isExpanded: isEquipmentNavOpen,
              forceAnchor: true,
              links: [
                {
                  key: 'Scan Barcode',
                  name: isFrench ? 'Scanner le code à barre' : 'Scan Barcode',
                  url: '/equipment-scan-barcode',
                  forceAnchor: true
                },
                {
                  key: 'By Account Number',
                  name: isFrench ? 'Par numéro de compte' : 'By Account Number',
                  url: '/equipment-account-number',
                  forceAnchor: true
                },
                {
                  key: 'By Asset Number',
                  name: isFrench ? 'Par numéro d’équipement' : 'By Asset Number',
                  url: '/equipment-asset-number',
                  forceAnchor: true
                },
                {
                  key: 'By Phone Number',
                  name: isFrench ? 'Par numéro de téléphone' : 'By Phone Number',
                  url: '/equipment-phone-number',
                  forceAnchor: true
                }
                // {
                //   key: 'By Address',
                //   name: 'By Address',
                //   url: '/find-by-address',
                //   forceAnchor: true,
                //   onClick: e => {
                //     e && e.preventDefault();
                //     history.push('/find-by-address');
                //   }
                // }
              ]
            },
            {
              name: isFrench ? 'Demande relative au compte' : 'Account Request',
              expandAriaLabel: 'Expand Account Request',
              url: '/account-request',
              collapseAriaLabel: 'Collapse Account Request',
              isExpanded: isAccountRequestNavOpen,
              forceAnchor: true,
              links: [
                {
                  key: 'By Account Number',
                  name: isFrench ? 'Par numéro de compte' : 'By Account Number',
                  url: '/request-account-number',
                  forceAnchor: true
                },
                {
                  key: 'By Phone Number',
                  name: isFrench ? 'Par numéro de téléphone' : 'By Phone Number',
                  url: '/request-phone-number',
                  forceAnchor: true
                }
              ]
            },
            {
              name: isFrench ? 'Recherche une demande' : 'Lookup Request',
              url: '/lookup-service-request',
              key: 'Lookup Request',
              forceAnchor: true
            },
            {
              name: isFrench ? 'Politique de confidentialité' : 'Privacy Policy',
              url: '/privacy-policy',
              key: 'Privacy Policy',
              forceAnchor: true
            }
          ]
        }
      ]}
    />
  ) : null;
};
