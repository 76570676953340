import React from 'react';
import { Box } from '@xstyled/styled-components';
// components
import { Icon, Text, ExternalLink } from '../common';
import { CopyExample } from '../kitchen-sink/Copy';

export const IconExample = () => {
  return (
    <Box marginTop="x1" marginBottom="x1">
      <hr />
      <Text forwardedAs="h2" marginBottom="s">
        Icon
      </Text>
      <Text marginBottom="s">
        We use <ExternalLink href="https://uifabricicons.azurewebsites.net/">Microsoft Office Fabric Icons</ExternalLink>
      </Text>
      <Icon iconName="GlobalNavButton" />
      <CopyExample>{`<Icon iconName="GlobalNavButton" />`}</CopyExample>
    </Box>
  );
};
