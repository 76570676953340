import React from 'react';
import { Box } from '@xstyled/styled-components';
// components
import { Button, Text } from '../common';
import { CopyExample } from '../kitchen-sink/Copy';

export const ButtonExample = () => {
  return (
    <Box marginTop="x1" marginBottom="x1">
      <hr />
      <Text forwardedAs="h2" marginBottom="s">
        Button
      </Text>
      <Button width="100%" color="white" backgroundColor="primary" onClick={() => {}}>
        Click Me!
      </Button>
      <CopyExample>{`<Button width="100%" color="white" backgroundColor="primary" onClick={() => {}}>Click Me!</Button>`}</CopyExample>
    </Box>
  );
};
