import React, { FC, useContext, useState } from 'react';
import styled from '@xstyled/styled-components';
import { darken } from 'polished';
// components
import { Text, Button, Centered, Modal } from '../components/common';
import { Page, PageTitle } from '../components/skeleton';
import { Box } from '@xstyled/styled-components';
import { FindEquipmentButton } from '../components/find-equipment';
// hoc
import { withTracker } from '../services/ga';
import { Language } from '../context/language';

const FindEquipment: FC = () => {
  const [isShowingModal, showModal] = useState<boolean>(false);
  const { isFrench } = useContext(Language);

  return (
    <>
      <Page
        secCol={
          <Box marginLeft={{ xs: 0, md: '-10px' }}>
            <Box marginLeft={{ xs: 'm', md: 0 }}>
              <PageTitle>{isFrench ? 'Recherche d’équipement sur le compte' : 'Find Equipment On Account'}</PageTitle>
              {isFrench ? (
                <Text fontSize="s" marginBottom="xxs">
                  Cette application peut être utilisée pour demander une réparation sur votre équipement Coca-Cola actuel.{' '}
                  <LearnMoreButton onClick={() => showModal(true)}>Apprenez-en plus.</LearnMoreButton>
                </Text>
              ) : (
                <Text fontSize="s" marginBottom="xxs">
                  This app can be used to request repair on your current Coca-Cola equipment. <LearnMoreButton onClick={() => showModal(true)}>Learn more.</LearnMoreButton>
                </Text>
              )}
              <Text marginBottom="m" fontSize="s">
                {isFrench ? "Remarque : Cette application n'accepte pas les demandes de remboursement" : 'Note: This app does not accept refund requests.'}
              </Text>
            </Box>
            <Box display="flex" margin={{ xs: '0', sm: 's', md: 'm' }} alignItems="center" justifyContent="center" flexWrap="wrap">
              <FindEquipmentButton
                link="/equipment-scan-barcode"
                iconName="FitPage"
                title={isFrench ? 'CODE-BARRES' : 'Barcode'}
                subtitle={isFrench ? 'Scanner un code à barre sur un équipement' : 'Scan a barcode on a piece of equipment'}
              />
              <FindEquipmentButton
                link="/equipment-account-number"
                iconName="AccountActivity"
                title={isFrench ? 'NUMÉRO DE COMPTE' : 'Account No.'}
                subtitle={isFrench ? 'Entrer le numéro de compte de votre magasin/restaurant' : 'Enter your store/restaurant account number'}
              />
              <FindEquipmentButton
                link="/equipment-asset-number"
                iconName="NumberField"
                title={isFrench ? 'NUMÉRO D’ÉQUIPEMENT' : 'Asset No.'}
                subtitle={isFrench ? 'Entrer manuellement le numéro d’équipement' : 'Manually enter the asset number of the equipment'}
              />
              <FindEquipmentButton
                link="/equipment-phone-number"
                iconName="Phone"
                title={isFrench ? 'NUMÉRO DE TÉLÉPHONE' : 'Phone No.'}
                subtitle={isFrench ? 'Rechercher l’équipement avec numéro de téléphone du magasin/restaurant' : 'Lookup equipment by store/restaurant phone'}
              />
              {/* <FindEquipmentButton link="/find-by-address" iconName="MapPin" title="Business Name/Address" subtitle="Lookup equipment by store/restaurant address" /> */}
            </Box>
          </Box>
        }
      />
      <Modal isOpen={isShowingModal} onDismiss={() => showModal(false)} containerStyles={{ width: '30rem' }}>
        <Centered flexDirection="column" padding="m">
          <Text forwardedAs="h2" marginTop="m" marginBottom="m" fontWeight="300" color="primary" fontSize={{ xs: '1.25rem', md: 'xxxl' }}>
            {isFrench ? "À propos de l'application de service Coke" : 'About the Coke® Service App'}
          </Text>
          <Text marginBottom="x2" lineHeight="1.35">
            {isFrench
              ? "Cette application peut être utilisée pour demander du service sur votre équipement Coca-Cola actuel. Cette application vous guidera tout au long du processus d'identification d'équipement dans notre système et de soumission d'une demande de service. Une fois soumis, un technicien sera affecté pour prendre en charge votre demande. Vous pouvez revenir à cette application pour voir votre demande soumise à tout moment."
              : 'This app can be used for requesting service on your current Coca-Cola equipment. This app will guide you through the process of identifying equipment in our system and submitting a service request. Once submitted, a technician will be assigned to support your request. You may return to this app to view your submitted request at any time.'}
          </Text>
          <Text>
            {isFrench
              ? 'Remarque : Cette application ne peut pas être utilisée pour demander des remboursements provenant d’une machine distributrice.'
              : 'Note: This app cannot be used for requesting refunds from vending machines.'}
          </Text>
          <Box marginTop="x2" display="flex" alignItems="center" justifyContent="center">
            <Button marginLeft="m" onClick={() => showModal(false)} backgroundColor="primary" color="white">
              Ok
            </Button>
          </Box>
        </Centered>
      </Modal>
    </>
  );
};

const LearnMoreButton = styled(Button)`
  padding: 0;
  color: primary;
  :hover {
    background-color: transparent;
    color: ${(p: any) => darken(0.05, p.theme.colors.primary)};
  }
`;

export default withTracker(FindEquipment);
