import React, { FC, useState, useEffect, useContext } from 'react';
import to from 'await-to-js';
import { useMedia } from 'react-use';
import { useLocation } from 'react-router-dom';
// components
import { SearchInput, Button, Text, Loader, NoResult, ErrorMessage } from '../common';
import { Box } from '@xstyled/styled-components';
import { LookupInformation } from './LookupInformation';
import { ZipCodeLookupToggle } from '../zip-code';
// fetch
import { fetchServiceRequest } from '../../fetch';
// models
import { ILookupServiceRequest } from '../../models';
// helpers
import { formatInputPhoneNumber, appendZeroToAccountNumber } from '../../helpers';
import { Language } from '../../context/language';

interface ILookupContent {
  setHeading: (val: string) => void;
}

export const LookupContent: FC<ILookupContent> = ({ setHeading }) => {
  const [result, setResult] = useState<ILookupServiceRequest | null>(null);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const isTabletOrDesktop = useMedia('(min-width: 768px)');
  const isMobile = useMedia('(max-width: 576px)');

  const [inputTicketValue, setTicketNumber] = useState<string>('');
  const [inputAccountValue, setAccountNumber] = useState<string>('');
  const [inputPhoneValue, setPhoneNumber] = useState<string>('');
  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (query && query.get('ticketNumber')) {
      setTicketNumber(query.get('ticketNumber') as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async () => {
    setFetching(true);
    setHeading('');
    setResult(null);
    const [, data] = await to(fetchServiceRequest(inputTicketValue, appendZeroToAccountNumber(inputAccountValue), inputPhoneValue));

    if (data && (data.err || !data.res)) {
      setFetching(false);
      return setError(data.err || { message: '404 - No Repair Requests Found', name: '' });
    }
    if (data) {
      setFetching(false);
      setHeading('Result');
      if (Object.values(data.res).length > 0) {
        return setResult(data.res);
      }
      return setError({ message: '404 - No Repair Requests Found', name: '' });
    }
  };
  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  const isValid = inputTicketValue && (inputAccountValue || inputPhoneValue.length === 12);
  return (
    <>
      <Box display="flex" flexDirection="column" marginRight={{ xs: '0', md: '.75rem' }} paddingRight={{ xs: '0', md: '.75rem' }}>
        {isFrench ? (
          <Text marginBottom={{ xs: 's', md: 'x1' }}>
            Veuillez entrer votre numéro de billet, ainsi que le numéro de compte ou le numéro de téléphone du contact, entré dans la demande. Votre numéro de billet se trouve dans
            le paramètre de notification que vous avez choisi (courriel ou texto)
          </Text>
        ) : (
          <Text marginBottom={{ xs: 's', md: 'x1' }}>
            Please enter your ticket number, along with the Account Number, or Contact Phone Number, entered on the request. Your ticket number can be found in your chosen
            notification setting (email or text).
          </Text>
        )}
        <Box backgroundColor="grayLighter" padding="m">
          <Text fontWeight="bold" fontSize="s" marginBottom={{ xs: 's', md: 'x1' }}>
            1. {isFrench ? 'Entrer votre numéro de billet' : 'Enter your ticket number'}
          </Text>
          <Box margin="0 auto" width={isMobile ? '100%' : '50%'}>
            <SearchInput
              placeholder={isFrench ? 'Numéro de billet' : 'Ticket Number'}
              minWidth="1px"
              flex={isTabletOrDesktop ? '0 0 auto' : '1'}
              value={inputTicketValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (error) {
                  setError(null);
                }
                setTicketNumber(e.target.value);
              }}
            />
          </Box>
        </Box>
        <Box backgroundColor="grayLighter" padding="m" marginTop="m">
          <Text fontWeight="bold" fontSize="s" marginBottom={{ xs: 's', md: 'x1' }}>
            2.{' '}
            {isFrench
              ? 'Afin de valider les informations de votre compte, veuillez saisir le numéro de compte OU le numéro de téléphone du contact utilisé pour soumettre la demande de service'
              : 'In order to validate your account information, please enter the account number OR contact phone number used to submit the service request.'}
          </Text>
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent={{ xs: 'center', sm: 'space-between' }}>
            <SearchInput
              placeholder={isFrench ? '# Compte' : 'Account #'}
              width={isMobile ? '100%' : '46%'}
              minWidth="1px"
              value={inputAccountValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (error) {
                  setError(null);
                }
                setAccountNumber(e.target.value);
              }}
            />
            <Text fontSize="m" fontWeight="bold" margin={{ xs: '1rem 0', sm: '0' }}>
              {isFrench ? 'Ou' : 'Or'}
            </Text>
            <SearchInput
              placeholder={isFrench ? '# Téléphone' : 'Phone #'}
              width={isMobile ? '100%' : '46%'}
              minWidth="1px"
              value={inputPhoneValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (error) {
                  setError(null);
                }
                setPhoneNumber(formatInputPhoneNumber(e.target.value) as string);
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" marginTop="m" marginBottom="m" justifyContent="center" marginRight={{ xs: '0', md: '1.25rem' }}>
        <Button backgroundColor={isValid ? 'primary' : ''} color="white" disabled={!isValid || isFetching} onClick={() => handleSearch()} width={{ xs: '100%', sm: '50%' }}>
          {isFrench ? 'Rechercher' : 'Search'}
        </Button>
      </Box>
      {!isFetching && result && Object.values(result).length > 0 && (
        <Box marginRight={{ xs: '0', md: '1.25rem' }}>
          {isFrench ? (
            <Text marginTop="m" marginBottom={{ xs: 'm', md: 'x1' }}>
              Vous souhaitez mettre à jour votre demande ? <ZipCodeLookupToggle text="Click here" /> pour déterminer quel numéro vous devez appeler.
            </Text>
          ) : (
            <Text marginTop="m" marginBottom={{ xs: 'm', md: 'x1' }}>
              Want to update your request? <ZipCodeLookupToggle text="Click here" /> to determine which number you should call.
            </Text>
          )}

          <LookupInformation info={result} isExpanded={true} />
        </Box>
      )}
      {isFetching && (
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" width="100%" height="x16">
          <Loader />
        </Box>
      )}
      {!result && !isFetching && error && error.message.includes('404') && (
        <NoResult>
          {isFrench ? 'Aucun résultat trouvé pour le numéro de billet' : 'No results found for ticket number'} {inputTicketValue}
          {inputAccountValue && ` ${isFrench ? 'et numéro de compte' : 'and Account Number'} ${inputAccountValue}`}
          {inputPhoneValue && ` ${isFrench ? 'et numéro de téléphone' : 'and Phone Number'} ${inputPhoneValue}`} that was entered. Please try again.
        </NoResult>
      )}
      {!result && !isFetching && error && error.message.includes('500') && (
        <ErrorMessage>
          {isFrench
            ? 'Désolé, une erreur s’est produite lors de la recherche de votre demande de service, veuillez actualiser et réessayer.'
            : 'Sorry there was an error searching for your service request, please refresh and try again.'}
        </ErrorMessage>
      )}
    </>
  );
};

/**
 * // test lookup request object, replace line 132 to see the content rendered and drop conditional check
 * {
            id: 1,
            status: 1,
            createdAt: '',
            outletId: 1,
            equipmentId: 1,
            equipmentRepairTicketNumber: 'asdasd',
            requestDescription: '22323',
            requestSummary: null,
            availableDays: '22323',
            availabilityHoursStart: 'asdasdada',
            availabilityHoursEnd: 'asdasdada',
            outletName: 'asdasdada',
            outletNumber: 'asdasdada',
            phoneNumber: null,
            street: 'asdasdada',
            city: 'asdasdada',
            state: 'asdasdada',
            postalCode: 'asdasdada',
            serialNumber: 'asdasdada',
            equipmentType: 'asdasdada',
            equipmentProblemDescription: '2323dwe13413',
            contactFirstName: '2323dwe13413',
            contactLastName: '2323dwe13413',
            contactEmail: '2323dwe13413',
            contactPhone: '2323dwe13413',
            contactRole: '2323dwe13413',
            employeeName: '2323dwe13413',
            employeeEmail: '2323dwe13413',
            employeePhone: '2323dwe13413',
            requestEquipmentLocationCity: 'adasdad',
            requestEquipmentLocationPostalCode: 'adasdad',
            requestEquipmentLocationState: 'adasdad',
            requestEquipmentLocationStreet: 'adasdad',
            requestEquipmentLocationName: 'adasdad',
            ticketStatusCode: 'adasdad',
            ticketStatusDescription: 'adasdad'
          }
 */
