import React, { FC, createContext, useReducer, useContext, Dispatch } from 'react';

import { initialState, GlobalReducer, GlobalState, GlobalAction } from './GlobalReducer';
import { IOutletInner, IEquipmentInner } from '../models';

interface IContextProps {
  state: GlobalState;
  dispatch: Dispatch<GlobalAction>;
}

/* Define a context and a reducer for updating the context */
const GlobalStateContext = createContext<IContextProps>({} as IContextProps);

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const GlobalStateProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialState);

  return <GlobalStateContext.Provider value={{ state, dispatch }}>{children}</GlobalStateContext.Provider>;
};

/*
Default export is a hook that provides a simple API for updating the global state.
This also allows us to keep all of this state logic in this one file
*/

export const useGlobalState = () => {
  const { state, dispatch } = useContext<IContextProps>(GlobalStateContext);

  const setNav = (payload: { isEquipmentNavOpen: boolean; isAccountRequestNavOpen: boolean }) => {
    dispatch({
      type: 'setNav',
      payload
    });
  };

  const setAccountFlow = (isAccountFlow: boolean = false) => {
    dispatch({
      type: 'setAccountFlow',
      payload: isAccountFlow
    });
  };

  const setAccount = (selectedAccount: IOutletInner | undefined) => {
    dispatch({
      type: 'setAccount',
      payload: selectedAccount
    });
  };

  const setEquipment = (payload: IEquipmentInner | undefined) => {
    dispatch({
      type: 'setEquipment',
      payload
    });
  };

  const showZipLookup = (isOpen: boolean = true) => {
    dispatch({
      type: 'showZipLookup',
      payload: isOpen
    });
  };

  return {
    setAccountFlow,
    setAccount,
    showZipLookup,
    setEquipment,
    setNav,
    ...state
  };
};
