import React, { FC, useContext } from 'react';
// components
import { Text, Icon, Link } from '../common';
import { darken } from 'polished';
import styled, { Box } from '@xstyled/styled-components';
import { Language } from '../../context/language';

interface IFindEquipmentButton {
  title: string;
  subtitle: string;
  iconName: string;
  link: string;
}

export const FindEquipmentButton: FC<IFindEquipmentButton> = ({ title, subtitle, iconName, link }) => {
  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  return (
    <StyledLink to={link}>
      <Wrapper
        padding="m"
        position="relative"
        margin={{ xs: '.5rem', sm: 'm' }}
        width={{ xs: title.includes('Address') ? '11rem' : isFrench ? '7rem' : '6rem', sm: title.includes('Address') ? '15rem' : '12rem' }}
        height={isFrench ? '11rem' : '9rem'}
        backgroundColor="primary"
        borderRadius="m"
        display="flex"
        alignItems="center"
        flexDirection="column"
        color="white"
      >
        <Icon iconName={iconName} fontSize={{ xs: '3rem', sm: '4rem' }} marginBottom="xs" color="white" />
        {/* A little hacky to make the icon look like a barcode */}
        {title === 'CODE-BARRES' && (
          <>
            <Icon iconName="Pause" top={{ xs: '33px', sm: '40px' }} fontSize={{ xs: 'm', sm: 'xl' }} left={{ xs: '57px', sm: '93px' }} color="white" position="absolute" />
            <Icon iconName="Pause" top={{ xs: '33px', sm: '40px' }} fontSize={{ xs: 'm', sm: 'xl' }} left={{ xs: '59px', sm: '96px' }} color="white" position="absolute" />
            <Icon iconName="Pause" top={{ xs: '33px', sm: '40px' }} fontSize={{ xs: 'm', sm: 'xl' }} left={{ xs: '66px', sm: '100px' }} color="white" position="absolute" />
            <Icon iconName="Pause" top={{ xs: '33px', sm: '40px' }} fontSize={{ xs: 'm', sm: 'xl' }} left={{ xs: '68px', sm: '105px' }} color="white" position="absolute" />
            <Icon iconName="Pause" top={{ xs: '33px', sm: '40px' }} fontSize={{ xs: 'm', sm: 'xl' }} left={{ xs: '70px', sm: '109px' }} color="white" position="absolute" />
            <Icon iconName="Pause" top={{ xs: '33px', sm: '40px' }} fontSize={{ xs: '0', sm: 'xl' }} left={{ xs: '76px', sm: '112px' }} color="white" position="absolute" />
          </>
        )}
        {title === 'Barcode' && (
          <>
            <Icon iconName="Pause" top={{ xs: '33px', sm: '40px' }} fontSize={{ xs: 'm', sm: 'xl' }} left={{ xs: '49px', sm: '93px' }} color="white" position="absolute" />
            <Icon iconName="Pause" top={{ xs: '33px', sm: '40px' }} fontSize={{ xs: 'm', sm: 'xl' }} left={{ xs: '51px', sm: '96px' }} color="white" position="absolute" />
            <Icon iconName="Pause" top={{ xs: '33px', sm: '40px' }} fontSize={{ xs: 'm', sm: 'xl' }} left={{ xs: '58px', sm: '100px' }} color="white" position="absolute" />
            <Icon iconName="Pause" top={{ xs: '33px', sm: '40px' }} fontSize={{ xs: 'm', sm: 'xl' }} left={{ xs: '60px', sm: '105px' }} color="white" position="absolute" />
            <Icon iconName="Pause" top={{ xs: '33px', sm: '40px' }} fontSize={{ xs: 'm', sm: 'xl' }} left={{ xs: '62px', sm: '109px' }} color="white" position="absolute" />
            <Icon iconName="Pause" top={{ xs: '33px', sm: '40px' }} fontSize={{ xs: '0', sm: 'xl' }} left={{ xs: '68px', sm: '112px' }} color="white" position="absolute" />
          </>
        )}
        {isFrench ? (
          <FrenchTitle forwardedAs="h2" fontSize={isFrench ? { xs: '.85rem', sm: 'xl' } : { xs: '.85rem', sm: 'xl' }} marginBottom="xs" textTransform="uppercase">
            {title}
          </FrenchTitle>
        ) : (
          <Title forwardedAs="h2" fontSize={isFrench ? { xs: '.85rem', sm: 'xl' } : { xs: '.85rem', sm: 'xl' }} marginBottom="xs" textTransform="uppercase">
            {title}
          </Title>
        )}
        <Text maxWidth="10rem" fontSize={{ xs: '.75rem', sm: 'xs' }} textAlign="center">
          {subtitle}
        </Text>
      </Wrapper>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const Wrapper = styled(Box)`
  :hover {
    background-color: ${(p: any) => darken(0.05, p.theme.colors[p.backgroundColor] || p.theme.colors.primary)};
  }
`;

const FrenchTitle = styled(Text)`
  text-align: center;
`;
const Title = styled(Text)`
  white-space: nowrap;
`;
