import React, { FC, useContext, useEffect, useState } from 'react';
// components
import { Link, NoResult, Text } from '../components/common';
import { Box } from '@xstyled/styled-components';
import { PageTitle, Page } from '../components/skeleton';
// hoc
import { withTracker } from '../services/ga';
import { Language } from '../context/language';
import { PhoneContent } from '../components/phone-number';
import { useGlobalState } from '../stateManagement';

const NonEquipPhoneSearch: FC = () => {
  const [heading, setHeading] = useState<string>('');
  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  const [phoneNumberError, setPhoneNumberError] = useState<any>(null);

  const [phoneNumber, setPhoneNumber] = useState('');

  const { isAccountFlow, setAccountFlow, setAccount, setEquipment } = useGlobalState();

  useEffect(() => {
    // if account is set when you come to the page, reset it on load
    setAccount(undefined);
    // reset equipment
    setEquipment(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAccountFlow(true);
    setPhoneNumberError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  return (
    <Page
      secCol={
        <>
          <Box padding={{ xs: 'm', md: '0' }} paddingTop="0" paddingBottom="0" marginLeft={{ xs: 0, md: '-10px' }}>
            <PageTitle>{isFrench ? 'Trouver votre compte' : 'Find your Account'}</PageTitle>
            <>
              <Text
                forwardedAs={isAccountFlow ? 'h5' : 'h1'}
                marginTop="m"
                color="primary"
                fontWeight={isAccountFlow ? '200' : '300'}
                marginBottom="m"
                fontSize={{ xs: '1.25rem', md: 'xxl' }}
              >
                {isFrench ? 'Par numéro de téléphone' : 'By Phone Number'} {heading ? `- ${heading}` : ''}
              </Text>
              <PhoneContent setHeading={setHeading} hasError={setPhoneNumberError} setPhoneNumber={setPhoneNumber} />
            </>
          </Box>

          {phoneNumberError && (
            <>
              {isFrench && (
                <NoResult>
                  Aucun résultat trouvé pour {phoneNumber}. Veuillez réessayer de rechercher un autre numéro de téléphone, ou <Link to="/">sélectionnez une autre option</Link> si
                  vous ne parvenez pas à trouver votre compte.
                </NoResult>
              )}
              {!isFrench && (
                <NoResult>
                  No results found for {phoneNumber}. Please try searching again for a different phone number, or <Link to="/">select a different option</Link> if you are unable to
                  find your account.
                </NoResult>
              )}
            </>
          )}
        </>
      }
    />
  );
};

export default withTracker(NonEquipPhoneSearch);
