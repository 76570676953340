export const hours = [
  { key: '6:00', text: '6:00' },
  { key: '6:15', text: '6:15' },
  { key: '6:30', text: '6:30' },
  { key: '6:45', text: '6:45' },
  { key: '7:00', text: '7:00' },
  { key: '7:15', text: '7:15' },
  { key: '7:30', text: '7:30' },
  { key: '7:45', text: '7:45' },
  { key: '8:00', text: '8:00' },
  { key: '8:15', text: '8:15' },
  { key: '8:30', text: '8:30' },
  { key: '8:45', text: '8:45' },
  { key: '9:00', text: '9:00' },
  { key: '9:15', text: '9:15' },
  { key: '9:30', text: '9:30' },
  { key: '9:45', text: '9:45' },
  { key: '10:00', text: '10:00' },
  { key: '10:15', text: '10:15' },
  { key: '10:30', text: '10:30' },
  { key: '10:45', text: '10:45' },
  { key: '11:00', text: '11:00' },
  { key: '11:15', text: '11:15' },
  { key: '11:30', text: '11:30' },
  { key: '11:45', text: '11:45' },
  { key: '12:00', text: '12:00' },
  { key: '12:15', text: '12:15' },
  { key: '12:30', text: '12:30' },
  { key: '12:45', text: '12:45' },
  { key: '1:00', text: '1:00' },
  { key: '1:15', text: '1:15' },
  { key: '1:30', text: '1:30' },
  { key: '1:45', text: '1:45' },
  { key: '2:00', text: '2:00' },
  { key: '2:15', text: '2:15' },
  { key: '2:30', text: '2:30' },
  { key: '2:45', text: '2:45' },
  { key: '3:00', text: '3:00' },
  { key: '3:15', text: '3:15' },
  { key: '3:30', text: '3:30' },
  { key: '3:45', text: '3:45' },
  { key: '4:00', text: '4:00' },
  { key: '4:15', text: '4:15' },
  { key: '4:30', text: '4:30' },
  { key: '4:45', text: '4:45' },
  { key: '5:00', text: '5:00' },
  { key: '5:15', text: '5:15' },
  { key: '5:30', text: '5:30' },
  { key: '5:45', text: '5:45' }
];

export const days = [
  {
    key: 'Sunday',
    text: 'Sun',
    frenchText: 'Soleil'
  },
  {
    key: 'Monday',
    text: 'Mon',
    frenchText: 'Mon'
  },
  {
    key: 'Tuesday',
    text: 'Tue',
    frenchText: 'Mar'
  },
  {
    key: 'Wednesday',
    text: 'Wed',
    frenchText: 'Marier'
  },
  {
    key: 'Thursday',
    text: 'Thu',
    frenchText: 'Automne'
  },
  {
    key: 'Friday',
    text: 'Fri',
    frenchText: 'Ven'
  },
  {
    key: 'Saturday',
    text: 'Sat',
    frenchText: 'Sam'
  }
];
