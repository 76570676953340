import { FC } from 'react';
import styled from '@xstyled/styled-components';
import { SystemProps } from '@xstyled/system';

interface IImageProps {
  alt: string;
  src: string;
  className?: string;
}

export const Image: FC<IImageProps & SystemProps> = styled.imgBox``;
