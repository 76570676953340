import React, { FC, useContext, useEffect, useState } from 'react';
// components
import { AccountContent } from '../components/account-number';
import { Page, PageTitle } from '../components/skeleton';
import { Box } from '@xstyled/styled-components';
// hoc
import { withTracker } from '../services/ga';
import { Language } from '../context/language';
import { useGlobalState } from '../stateManagement';

const AccountNumber: FC = () => {
  const [heading, setHeading] = useState<string>('');
  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  const { setAccountFlow, setAccount, setEquipment } = useGlobalState();

  useEffect(() => {
    setAccountFlow(false);
    // if account is set when you come to the page, reset it on load
    setAccount(undefined);
    // reset equipment
    setEquipment(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      secCol={
        <Box padding={{ xs: 'm', md: '0' }} paddingTop="0" paddingBottom="0">
          <PageTitle>
            {isFrench ? 'Par numéro de compte ' : 'By Account Number '}
            {heading ? `- ${heading}` : ''}
          </PageTitle>
          <AccountContent setHeading={setHeading} heading={heading} />
        </Box>
      }
    />
  );
};

export default withTracker(AccountNumber);
