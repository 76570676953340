export const formatPhoneNumber = (phoneNumber: string, showLeadingOne: boolean = true) => {
  const correctNumber = ('' + phoneNumber).replace(/\D/g, '');

  // Check if the input is of correct length
  let match = correctNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `${showLeadingOne ? '1-' : ''}${match[1]}-${match[2]}-${match[3]}`;
  }
  // if formatting or matching fails, at least return original
  return phoneNumber;
};

export const phoneRegExp = /1?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?/;

export const formatAccountNumber = (accountNumber: string): string => `*****${accountNumber.substr(accountNumber.length - 4)}`;

export const formatInputPhoneNumber = (val: string | undefined) => {
  let number = val?.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  // taken from here, https://stackoverflow.com/questions/17651207/mask-us-phone-number-string-with-javascript
  return number && (!number[2] ? number[1] : number[1] + '-' + number[2] + (number[3] ? '-' + number[3] : ''));
};

// allow user to enter the account number without a leading zero, we prepend it for them.
export const appendZeroToAccountNumber = (value: string) => (value.charAt(0) !== '0' && value.length === 9 ? `0${value}` : value);
