import { IEquipmentInner, IOutletInner } from '../models';

export const initialState = {
  account: undefined,
  equipment: undefined,
  isZipLookupOpen: false,
  isAccountFlow: false,
  isEquipmentNavOpen: true,
  isAccountRequestNavOpen: true
};

export type GlobalState = {
  isAccountFlow?: boolean;
  account?: IOutletInner;
  isZipLookupOpen: boolean;
  equipment?: IEquipmentInner;
  isEquipmentNavOpen: boolean;
  isAccountRequestNavOpen: boolean;
};

export type GlobalAction =
  | { type: 'setAccountFlow'; payload: boolean | undefined }
  | { type: 'setAccount'; payload: IOutletInner | undefined }
  | { type: 'showZipLookup'; payload: boolean | undefined }
  | { type: 'setEquipment'; payload: IEquipmentInner | undefined }
  | {
      type: 'setNav';
      payload: {
        isEquipmentNavOpen?: boolean;
        isAccountRequestNavOpen?: boolean;
      };
    };

export const GlobalReducer = (state: GlobalState, action: GlobalAction): GlobalState => {
  switch (action.type) {
    case 'setNav':
      return { ...state, ...action.payload };
    case 'setAccountFlow':
      return { ...state, isAccountFlow: action.payload };
    case 'setAccount':
      return { ...state, account: action.payload };
    case 'setEquipment':
      return { ...state, equipment: action.payload };
    case 'showZipLookup':
      return { ...state, isZipLookupOpen: action.payload || false };
    default:
      return state || {};
  }
};
