import { FC } from 'react';
import styled from '@xstyled/styled-components';
import { SystemProps } from '@xstyled/system';

interface ITextProps {
  children: any;
  forwardedAs?: string;
}

export const Text: FC<SystemProps & ITextProps> = styled.pBox`
  margin: 0;
`;
