import styled from '@xstyled/styled-components';
import { system, SystemProps } from '@xstyled/system';
import { IconButton as OfficeIconButton, IButtonProps } from '@fluentui/react';
import { FC } from 'react';

export const IconButton: FC<SystemProps & IButtonProps> = styled(OfficeIconButton)`
  ${system};
  &:hover {
    color: black;
  }
`;
