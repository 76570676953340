import React, { FC } from 'react';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import styled from '@xstyled/styled-components';

interface RadioList {
  options: IChoiceGroupOption[];
  onChange: (ev?: React.FormEvent<HTMLInputElement | HTMLElement> | undefined, option?: IChoiceGroupOption | undefined) => void;
  selectedKey: string;
}

export const RadioList: FC<RadioList> = ({ options, onChange, selectedKey }) => {
  return (
    <Wrapper>
      <ChoiceGroup onChange={onChange} selectedKey={selectedKey} options={options} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  && .ms-ChoiceField {
    &:nth-child(odd) {
      background-color: grayLightest;
    }
  }
`;
