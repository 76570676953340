import React, { Fragment, FC, useContext } from 'react';
import { useMedia } from 'react-use';
import { Box } from '@xstyled/styled-components';
import { Language } from '../../context/language';
import { Button, Centered, Modal, Text } from '../common';

interface IConsentInfoModal {
  isOpen: boolean;
  handleClose: () => void;
}

export const ConsentInfoModal: FC<IConsentInfoModal> = ({ isOpen, handleClose }) => {
  const isDesktop = useMedia('(min-width: 500px)');
  const { isFrench } = useContext(Language);

  const PhoneNumberWrapper = isDesktop ? Fragment : 'a';

  return (
    <Modal isOpen={isOpen} onDismiss={handleClose} containerStyles={{ width: isDesktop ? '30%' : '90%' }}>
      <Centered padding="x1" paddingLeft="m" paddingRight="m" textAlign="center" flexDirection="column">
        <Text forwardedAs="h2" marginBottom="x1" width="80%" fontWeight="300">
          {isFrench
            ? 'Pour toutes questions concernant notre politique de service, veuillez nous contacter au'
            : 'Should you have any questions related to our service policy, call'}{' '}
          <PhoneNumberWrapper {...(!isDesktop ? { href: `tel:1-800-218-2653` } : {})}>1-800-218-2653</PhoneNumberWrapper>.
        </Text>

        <Box display="flex" justifyContent="center" marginTop="x2" width={{ xs: '100%', md: '80%' }}>
          <Button backgroundColor="primary" color="white" width="30%" marginRight="s" onClick={handleClose}>
            {isFrench ? "D'accord" : 'Ok'}
          </Button>
        </Box>
      </Centered>
    </Modal>
  );
};
