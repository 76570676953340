import React, { FC, useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import to from 'await-to-js';
import { useCookies } from 'react-cookie';
// @ts-ignore
import { ThemeProvider } from '@xstyled/styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createTheme, Customizations } from '@fluentui/react';
// config
import config from '../buildSettings.json';
// helpers
import theme from '../theme';
import { GlobalStateProvider } from '../stateManagement';
import { setLocalForageItem, TIMER_KEY, getLocalForageItem, removeLocalForageItem, logEvent, LANGUAGE } from '../services';
// components
import Home from './Home';
import AccountNumber from './AccountNumber';
// import { Address } from './Address';
import AssetNumber from './AssetNumber';
import FindEquipment from './FindEquipment';
import InsightsProvider from '../components/provider/InsightsProvider';
import { KitchenSink } from './KitchenSink';
import NotFound from './NotFound';
import PhoneNumber from './PhoneNumber';
import ScanBarcode from './ScanBarcode';
import { ScrollToTop } from '../components/skeleton';
import SelectEquipment from './SelectEquipment';
import SubmitRequest from './SubmitRequest';
import { ZipPhoneLookupModal } from '../components/zip-code';
import LookupServiceRequest from './LookupServiceRequest';
import PrivacyPolicy from './PrivacyPolicy';
import { PrivacyBanner } from '../components/skeleton';
// context
import { BottlersAccess } from '../context/bottlersAccess';
// fetch
import { getBottlersAccess } from '../fetch';
import { IBottlerAccess } from '../models';
import { Language } from '../context/language';
import AccountSearch from './AccountSearch';
import NonEquipPhoneSearch from './NonEquipPhoneSearch';
import NonEquipAccountSearch from './NonEquipAccountSearch';

// https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/index.html
const myTheme = createTheme({
  palette: {
    themePrimary: theme.colors.primary,
    themeLighterAlt: theme.colors.primaryLighterAlt,
    themeLighter: theme.colors.primaryLighter,
    themeLight: theme.colors.primaryLight,
    themeTertiary: theme.colors.tertiary,
    themeSecondary: theme.colors.secondary,
    themeDarkAlt: theme.colors.primaryDarkAlt,
    themeDark: theme.colors.primaryDark,
    themeDarker: theme.colors.primaryDarker,
    neutralLighterAlt: theme.colors.neutralLighterAlt,
    neutralLighter: theme.colors.neutralLighter,
    neutralLight: theme.colors.neutralLight,
    neutralQuaternaryAlt: theme.colors.neutralQuaternaryAlt,
    neutralQuaternary: theme.colors.neutralQuaternary,
    neutralTertiaryAlt: theme.colors.neutralTertiaryAlt,
    neutralTertiary: theme.colors.neutralTertiary,
    neutralSecondary: theme.colors.neutralSecondary,
    neutralPrimaryAlt: theme.colors.neutralPrimaryAlt,
    neutralPrimary: theme.colors.neutralPrimary,
    neutralDark: theme.colors.neutralDark,
    black: theme.colors.black,
    white: theme.colors.white
  }
});

const App: FC = () => {
  const [cookie] = useCookies(['PrivacyBanner']);
  const [bottlersAccess, setBottlersAccess] = useState<IBottlerAccess[]>([]);
  const [language, setLanguage] = useState<string>();
  // this will also cause a reset on refresh too.
  const checkForTimer = async () => {
    try {
      const timer = await getLocalForageItem(TIMER_KEY);
      // if one already exists when you hit the app, remove it and set a new one so its an accurate time.
      if (timer) {
        removeLocalForageItem(TIMER_KEY);
      }
      setLocalForageItem(TIMER_KEY, new Date());
    } catch (error) {
      logEvent({ event: error, location: `Error settting ${TIMER_KEY} in local forage` });
    }
  };

  const getLanguage = async () => {
    try {
      const language = await getLocalForageItem(LANGUAGE);
      if (!language) {
        await setLocalForageItem(LANGUAGE, 'en-us');
        setLanguage('en-us');
      } else {
        setLanguage(language as string);
      }
    } catch (error) {
      logEvent({ event: error, location: `Error setting ${LANGUAGE} in local forage` });
    }
  };

  const fetchBottlersAccess = async () => {
    const [, data] = await to(getBottlersAccess());

    if (data && data.res) {
      return setBottlersAccess(data.res);
    }
  };
  useEffect(() => {
    checkForTimer();
    getLanguage();
    fetchBottlersAccess();
    Customizations.applySettings({ theme: myTheme });
  }, []);
  return (
    <GlobalStateProvider>
      <ThemeProvider theme={theme}>
        <BottlersAccess.Provider value={{ bottlersAccess }}>
          <Language.Provider value={{ language, setLanguage, isFrench: language === 'fr-ca' }}>
            {language && (
              <Router>
                <ScrollToTop />
                <InsightsProvider history={createBrowserHistory({ basename: '' })} instrumentationKey={config.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY}>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/equipment-request" component={FindEquipment} />
                    <Route exact path="/equipment-scan-barcode" component={ScanBarcode} />
                    <Route exact path="/equipment-account-number" component={AccountNumber} />
                    <Route exact path="/equipment-asset-number" component={AssetNumber} />
                    <Route exact path="/equipment-phone-number" component={PhoneNumber} />
                    {/* <Route exact path="/find-by-address" component={Address} /> */}
                    <Route exact path="/account-request" component={AccountSearch} />
                    <Route exact path="/request-phone-number" component={NonEquipPhoneSearch} />
                    <Route exact path="/request-account-number" component={NonEquipAccountSearch} />
                    <Route exact path="/select-equipment" component={SelectEquipment} />
                    <Route exact path="/submit-request" component={SubmitRequest} />
                    <Route exact path="/lookup-service-request" component={LookupServiceRequest} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/kitchen-sink" component={KitchenSink} />
                    <Route component={NotFound} />
                  </Switch>
                </InsightsProvider>
              </Router>
            )}
            <ZipPhoneLookupModal />
            {!cookie.PrivacyBanner && <PrivacyBanner />}
          </Language.Provider>
        </BottlersAccess.Provider>
      </ThemeProvider>
    </GlobalStateProvider>
  );
};

export default App;
