import React, { FC, Fragment, useContext } from 'react';
import { useMedia } from 'react-use';
// components
import { Text, Centered, Modal } from '../common';
// config
import config from '../../buildSettings.json';
import { Language } from '../../context/language';

interface INotListedAccountModal {
  isOpen: boolean;
  handleClose: () => void;
}

export const NationalAccountModal: FC<INotListedAccountModal> = ({ isOpen, handleClose }) => {
  const isDesktop = useMedia('(min-width: 500px)');
  const PhoneNumberWrapper = isDesktop ? Fragment : 'a';
  const { language } = useContext(Language);
  const isFrench = language === 'fr-ca';

  return (
    <Modal isOpen={isOpen} onDismiss={handleClose} containerStyles={{ width: '20rem' }}>
      <Centered flexDirection="column" padding="m" height="7rem">
        {isFrench ? (
          <Text lineHeight="1.35">
            L’équipement que vous avez sélectionné est attaché à un compte national. Veuillez composer le{' '}
            <PhoneNumberWrapper {...(!isDesktop ? { href: `tel:${config.REACT_APP_NATIONAL_PHONE}` } : {})}>{config.REACT_APP_NATIONAL_PHONE}</PhoneNumberWrapper> afin de soumettre
            une demande pour ce type de compte.
          </Text>
        ) : (
          <Text lineHeight="1.35">
            The equipment you have selected is attached to a national account. In order to submit a request for this type of account, please call: US{' '}
            <PhoneNumberWrapper {...(!isDesktop ? { href: `tel:${config.REACT_APP_NATIONAL_PHONE}` } : {})}>{config.REACT_APP_NATIONAL_PHONE}</PhoneNumberWrapper> Canada:{' '}
            <PhoneNumberWrapper {...(!isDesktop ? { href: `tel:1-800-218-2653` } : {})}>1-800-218-2653</PhoneNumberWrapper>
          </Text>
        )}
      </Centered>
    </Modal>
  );
};
