import React, { FC, useContext } from 'react';
// components
import { Button, Text, ButtonLink, Centered, Modal } from '../common';
import { ZipCodeLookupToggle } from '../zip-code';
import { Box } from '@xstyled/styled-components';
import { Language } from '../../context/language';

interface INotListedAccountModal {
  isOpen: boolean;
  handleClose: () => void;
}

export const NotListedAccountModal: FC<INotListedAccountModal> = ({ isOpen, handleClose }) => {
  const { isFrench } = useContext(Language);
  return (
    <Modal isOpen={isOpen} onDismiss={handleClose}>
      <Centered flexDirection="column" height="5rem" padding="x1" paddingTop="x3" paddingBottom="x3">
        <Text>
          {isFrench ? 'Essayez de rechercher par compte ou' : 'Try searching by account or'} <ZipCodeLookupToggle onClick={handleClose} />,{' '}
          {isFrench ? 'pour demander de l’aide pour trouver le compte.' : 'to request assistance with finding the account.'}
        </Text>
        <Box marginTop="x2" display="flex" alignItems="center" justifyContent="center">
          <ButtonLink padding="s" display="inline-block" to="/equipment-account-number" backgroundColor="primary" color="white">
            {isFrench ? 'Recherche par compte' : 'Search by Account'}
          </ButtonLink>
          <Button marginLeft="m" onClick={handleClose} backgroundColor="gray" color="white">
            {isFrench ? 'Annuler' : 'Cancel'}
          </Button>
        </Box>
      </Centered>
    </Modal>
  );
};
