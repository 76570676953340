import React, { FC, useContext } from 'react';
// components
import { Text, Label, Expand } from '../common';
import { Box } from '@xstyled/styled-components';
import { IOutletInner } from '../../models';
import { formatAccountNumber } from '../../helpers';
import { Language } from '../../context/language';

interface IAccountInformation {
  accountInfo: IOutletInner;
  type?: string;
  withConfirmation?: boolean;
}

export const AccountInformation: FC<IAccountInformation> = ({ type, accountInfo, withConfirmation = true }) => {
  const { language } = useContext(Language);

  const isFrench = language === 'fr-ca';

  return (
    <>
      {withConfirmation && (
        <>
          <Text marginTop="m" marginBottom="m">
            {isFrench ? 'Bonnes nouvelles! Le numéro de compte que vous avez entré a été trouvé.' : `Good News! The ${type} you entered was found.`}
          </Text>
          <Text marginBottom="m">
            {isFrench ? 'Veuillez confirmer que les informations de compte suivantes sont correctes:' : 'Please confirm that the following account information is correct:'}
          </Text>
        </>
      )}
      <Expand isExpanded={withConfirmation} showExpandButton={!withConfirmation}>
        <Text forwardedAs="h3" marginBottom="m">
          {isFrench ? 'Information du compte' : 'Account Information'}
        </Text>
        <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '15rem' }}>
            <Label>{isFrench ? 'Nom' : 'Name'}</Label>
            <Text fontSize="s">{accountInfo.outletName}</Text>
          </Box>
          <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '9rem' }}>
            <Label>{isFrench ? '#Compte' : 'Account#'}</Label>
            <Text fontSize="s">{formatAccountNumber(accountInfo.outletNumber)}</Text>
          </Box>
          {!withConfirmation && accountInfo.phoneNumber && (
            <Box marginBottom={{ xs: 's', md: 'm' }} width={{ xs: 'auto', md: '7rem' }}>
              <Label>{isFrench ? '#TÉLÉPHONE' : 'Phone#'}</Label>
              <Text fontSize="s">{accountInfo.phoneNumber}</Text>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems={{ xs: 'flex-start', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box marginBottom={{ xs: 's', md: '0' }} width={{ xs: 'auto', md: '15rem' }}>
            <Label>{isFrench ? 'Rue' : 'Street'}</Label>
            <Text fontSize="s">{accountInfo.street}</Text>
          </Box>
          <Box marginBottom={{ xs: 's', md: '0' }} width={{ xs: 'auto', md: '7rem' }}>
            <Label>{isFrench ? 'Ville' : 'City'}</Label>
            <Text fontSize="s">{accountInfo.city}</Text>
          </Box>
          <Box marginBottom={{ xs: 's', md: '0' }} width={{ xs: 'auto', md: '7rem' }}>
            <Label>{isFrench ? 'Province' : 'State/Prov'}</Label>
            <Text fontSize="s">{accountInfo.state}</Text>
          </Box>
          <Box marginBottom={{ xs: 's', md: '0' }} width={{ xs: 'auto', md: '8rem' }}>
            <Label>{isFrench ? 'Code Postal' : 'ZIP/Postal Code'}</Label>
            <Text fontSize="s">{accountInfo.postalCode}</Text>
          </Box>
        </Box>
      </Expand>
    </>
  );
};
