import to from 'await-to-js';
import { axios } from '../helpers';
import { logEvent } from '../services';
import { IBottlerAccess, Apps } from '../models';

export const getBottlersAccess = async (): Promise<{ err: Error | null; res: IBottlerAccess[] }> => {
  const [err, res] = await to(axios.get(`app-access-control?appId=${Apps.COKE_SERVICES_APP}`, {}, 'v1'));

  if (err) {
    logEvent({ event: err, location: 'fetch/bottlerAccess.ts getBottlersAccess' });
  }

  return { err, res: res ? res.data.data : null };
};
