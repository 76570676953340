import React, { FC } from 'react';
// components
import { Text, Label } from '../common';
import { Box } from '@xstyled/styled-components';
import { SystemProps } from '@xstyled/system';

interface IItemValueProps {
  title?: string;
  value?: any;
  systemProps?: SystemProps;
}

export const ItemValue: FC<IItemValueProps> = ({ title, value, systemProps }) => {
  return (
    <Box {...systemProps}>
      <Label>{title}</Label>
      <Text fontSize="s">{value}</Text>
    </Box>
  );
};
