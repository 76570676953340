import React, { FC, useContext, useEffect, useState } from 'react';
import to from 'await-to-js';
// components
import { Text, TextField, Modal, Button } from '../common';
import { Box } from '@xstyled/styled-components';
// fetch
import { fetchValidateEmployeeEmail } from '../../fetch';
import { Language } from '../../context/language';
import { useGlobalState } from '../../stateManagement';

/**
 * Verify if a user's email address is tied to a Coke domain
 */

interface ICokeEmployeeModal {
  isOpen: boolean;
  setShowingEmployeeModal: (val: boolean) => void;
  setHasValidEmployeeEmail: (val: boolean) => void;
  setShowingNonEquipmentEmployeeModal: (val: boolean) => void;
  setEmployeeEmail: (email: string) => void;
  setContactRole: (val: string) => void;
}

const errorMessages = {
  notCoke: 'Please enter a valid employee email address to continue. If you are not an employee of one of the Coca-Cola Bottlers, then please click the "Cancel" button below.',
  notAnEmail: 'Please enter a valid email'
};

const emailRegex = RegExp(/[a-z0-9._%+!$&*=^|~#%'`?{}/-]+@([a-z0-9-]+\.){1,}([a-z]{2,16})/i);

export const EmployeeEmailModal: FC<ICokeEmployeeModal> = ({
  isOpen,
  setShowingEmployeeModal,
  setHasValidEmployeeEmail,
  setEmployeeEmail,
  setContactRole,
  setShowingNonEquipmentEmployeeModal
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [emailValue, setEmailValue] = useState<string>('');
  const [isShowingEmployeeError, setIsShowingEmployeeError] = useState<boolean>(false);

  const { isAccountFlow } = useGlobalState();

  const handleSubmit = async () => {
    if (!isValidEmail) {
      return;
    }

    setIsLoading(true);
    const [, data] = await to(fetchValidateEmployeeEmail(emailValue));
    setIsLoading(false);

    if (data?.res?.length > 0) {
      setHasValidEmployeeEmail(true);
      setShowingEmployeeModal(false);
      if (isAccountFlow) {
        setShowingNonEquipmentEmployeeModal(true);
      }
      setEmployeeEmail(emailValue);
    } else {
      setIsShowingEmployeeError(true);
      setEmployeeEmail('');
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsShowingEmployeeError(false);
    }
  }, [isOpen]);

  // Reset role if user dismisses the modal, i.e., they didn't
  // validate their email.
  const handleDismiss = () => {
    setContactRole('');
    setShowingEmployeeModal(false);
  };
  const { isFrench } = useContext(Language);

  return (
    <Modal isOpen={isOpen} onDismiss={handleDismiss} containerStyles={{ width: '24rem', maxWidth: '100%' }}>
      <Box flexDirection="column" padding="m">
        {isShowingEmployeeError && (
          <Text lineHeight="1.35" marginTop="x1" color="error">
            {errorMessages.notCoke}
          </Text>
        )}
        <TextField
          label={isFrench ? "Veuillez entrer votre adresse courriel d'employé de Coke:" : 'Please enter your Coke employee email address:'}
          value={emailValue}
          disabled={isLoading}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
            const value = newValue ? newValue.replace(/ /g, '') : '';
            setEmailValue(value);
            if (!isValidEmail) {
              setIsValidEmail(emailRegex.test(value));
            }
          }}
          onBlur={() => {
            if (!emailRegex.test(emailValue)) {
              setIsValidEmail(false);
            }
          }}
          errorMessage={isValidEmail ? undefined : errorMessages.notAnEmail}
          marginTop="x1"
          type="email"
        />
        <Box display="flex" width="100%" marginTop="x1" alignItems="center" justifyContent="space-between">
          <Button onClick={handleSubmit} disabled={!emailValue || isLoading} color="white" width="48%" backgroundColor={emailValue && !isLoading ? 'primary' : 'grayLight'}>
            Ok
          </Button>
          <Button onClick={handleDismiss} color="black" width="48%" backgroundColor="grayLight">
            {isFrench ? 'Annuler' : 'Cancel'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
