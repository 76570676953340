import React, { FC, useState, useRef, useEffect } from 'react';
// components
import { IconButton } from '../common';
import { Box } from '@xstyled/styled-components';

interface IExpandProps {
  isExpanded?: boolean;
  handleToggle?: () => void;
  showExpandButton?: boolean;
}

export const Expand: FC<IExpandProps> = ({ children, isExpanded, handleToggle, showExpandButton = true }) => {
  const [isToggled, toggleMore] = useState<boolean>(false);
  const contentContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isExpanded && !isToggled) {
      return toggleMore(true);
    }
    if (!isExpanded && isToggled) {
      return toggleMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);
  return (
    <Box
      forwardedAs="section"
      ref={contentContainer}
      maxHeight={isToggled && contentContainer !== null && contentContainer.current !== null ? contentContainer.current.scrollHeight * 2 : 'm'}
      backgroundColor="grayLightest"
      padding={{ xs: 'x1' }}
      position="relative"
      overflow="hidden"
      marginTop="m"
      marginBottom="m"
    >
      {children}
      {showExpandButton && (
        <IconButton
          backgroundColor="transparent"
          color="primary"
          position="absolute"
          right="5px"
          top="5px"
          padding="0"
          iconProps={{
            iconName: isToggled ? 'ChevronDown' : 'ChevronUp'
          }}
          onClick={() => {
            toggleMore(!isToggled);
            handleToggle && handleToggle();
          }}
        />
      )}
    </Box>
  );
};
