import React from 'react';
import { Box } from '@xstyled/styled-components';
// components
import { Checkbox, Text } from '../common';
import { CopyExample } from '../kitchen-sink/Copy';

export const CheckboxExample = () => {
  return (
    <Box marginTop="x1" marginBottom="x1">
      <hr />
      <Text forwardedAs="h2" marginBottom="s">
        Checkbox
      </Text>
      <Checkbox label="Checkbox label" checked />
      <CopyExample>{`<Checkbox label="Checkbox label" checked />`}</CopyExample>
    </Box>
  );
};
