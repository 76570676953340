import React from 'react';
import { Box } from '@xstyled/styled-components';
// components
import { Label, Text } from '../common';
import { CopyExample } from '../kitchen-sink/Copy';

export const LabelExample = () => {
  return (
    <Box marginTop="x1" marginBottom="x1">
      <hr />
      <Text forwardedAs="h2" marginBottom="s">
        Label
      </Text>
      <Label>This is a Label</Label>
      <CopyExample>{`<Label>This is a Label</Label>`}</CopyExample>
    </Box>
  );
};
