import React from 'react';
import { Box } from '@xstyled/styled-components';
// components
import { List, Text } from '../common';
import { CopyExample } from '../kitchen-sink/Copy';

export const ListExample = () => {
  return (
    <Box marginTop="x1" marginBottom="x1">
      <hr />
      <Text forwardedAs="h2" marginBottom="s">
        List
      </Text>
      <List>
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
      </List>
      <CopyExample>
        {`
                <List>
                  <li>Item 1</li>
                  <li>Item 2</li>
                  <li>Item 3</li>
                </List>
                `}
      </CopyExample>
    </Box>
  );
};
