import React, { useState, Fragment, useContext } from 'react';
import { useMedia } from 'react-use';
import to from 'await-to-js';
import { Box } from '@xstyled/styled-components';

import { Button, Centered, Modal, SearchInput, Text, Loader } from '../common';
import { useGlobalState } from '../../stateManagement';
import { fetchPhoneNumberByZipCode } from '../../fetch';
import { formatPhoneNumber } from '../../helpers';
import { Language } from '../../context/language';

const fiveDigitZipRegex = /\d{5}/;
const fallbackContactPhoneNumber = '8006472653';

export const ZipPhoneLookupModal = () => {
  const [zipCode, setZipCode] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { isZipLookupOpen, showZipLookup } = useGlobalState();
  const isDesktop = useMedia('(min-width: 500px)');
  const PhoneNumberWrapper = isDesktop ? Fragment : 'a';

  const searchByZipCode = async () => {
    setIsFetching(true);
    const [, data] = await to(fetchPhoneNumberByZipCode(zipCode));

    if (data) {
      setPhoneNumber((data && data.res && data.res.phoneNumber) || fallbackContactPhoneNumber);
    }
    setIsFetching(false);
  };

  const closeModal = () => {
    showZipLookup(false);
    // wait for modal to hide
    setTimeout(() => {
      setZipCode('');
      setPhoneNumber('');
    }, 300);
  };

  const { isFrench } = useContext(Language);

  return (
    <Modal isOpen={isZipLookupOpen} onDismiss={closeModal} containerStyles={{ width: isDesktop ? '23%' : '90%' }}>
      <Centered padding="x1" paddingLeft="m" paddingRight="m" textAlign="center" flexDirection="column">
        <Text forwardedAs="h2" marginBottom="x1" width="80%" color="primary" fontWeight="300">
          {!phoneNumber ? (isFrench ? `S’il vous plait, entrer un code postal` : 'Please enter a ZIP code') : isFrench ? 'Numéro pour nous contacter' : 'Contact Number'}
        </Text>
        <Text marginBottom="x1" fontSize="m" width={{ xs: '100%', md: '80%' }}>
          {!phoneNumber ? (
            isFrench ? (
              <Text forwardedAs="span">
                Veuillez entrer le code postal où se trouve le compte, afin que nous puissions déterminer quel numéro vous devez appeler pour obtenir de l'aide En fonction du code
                postal que vous avez entré, veuillez appeler le{' '}
                <PhoneNumberWrapper {...(!isDesktop ? { href: `tel:${phoneNumber}` } : {})}>{formatPhoneNumber(phoneNumber)}</PhoneNumberWrapper> pour demander de l'aide.
              </Text>
            ) : (
              <Text forwardedAs="span">
                For{' '}
                <Text forwardedAs="span" fontWeight="bold">
                  US customers
                </Text>
                , please enter the ZIP code where the account is located so that we can determine which number you should call for assistance
              </Text>
            )
          ) : (
            <Text forwardedAs="span">
              {isFrench ? 'En fonction du code postal que vous avez entré, veuillez appeler' : 'Based on the ZIP code you entered, please call'}{' '}
              <PhoneNumberWrapper {...(!isDesktop ? { href: `tel:${phoneNumber}` } : {})}>{formatPhoneNumber(phoneNumber)}</PhoneNumberWrapper>{' '}
              {isFrench ? 'pour demander de l’aide.' : 'to request assistance.'}
            </Text>
          )}
        </Text>
        {!phoneNumber && (
          <Centered>
            {isFetching ? (
              <Loader />
            ) : (
              <SearchInput
                placeholder={isFrench ? 'Entrer un code postal...' : 'Enter a ZIP code...'}
                width="100%"
                value={zipCode}
                maxLength={5}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.keyCode === 13) {
                    searchByZipCode();
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  // allow only numbers
                  setZipCode(e.target.value.replace(/\D/, ''));
                }}
              />
            )}
          </Centered>
        )}
        {!phoneNumber && (
          <>
            <Text marginTop="m" fontSize="m">
              {isFrench ? 'Pour les' : 'For'}{' '}
              <Text forwardedAs="span" fontWeight="bold">
                {isFrench ? 'clients canadiens' : 'Canadian customers'}
              </Text>
              , {isFrench ? 'veuillez appeler: ' : 'please call:'}
            </Text>
            <Text forwardedAs="p" marginTop="xs">
              <PhoneNumberWrapper {...(!isDesktop ? { href: `tel:1-800-218-2653` } : {})}>1-800-218-2653</PhoneNumberWrapper>
            </Text>
          </>
        )}
        <Box display="flex" justifyContent="center" marginTop="x2" width={{ xs: '100%', md: '80%' }}>
          <Button
            backgroundColor="primary"
            color="white"
            width="30%"
            marginRight="s"
            disabled={!zipCode || zipCode.length !== 5 || !fiveDigitZipRegex.test(zipCode)}
            onClick={!phoneNumber ? searchByZipCode : closeModal}
          >
            Ok
          </Button>
          {!phoneNumber && (
            <Button backgroundColor="grayLighter" color="black" onClick={closeModal}>
              {isFrench ? 'Annuler' : 'Cancel'}
            </Button>
          )}
        </Box>
      </Centered>
    </Modal>
  );
};
