import Axios, { Method } from 'axios';

import { logRocketInfo, logRocketError } from '../services';
import { logEvent } from '../services/applicationInsights';
let buildConfig = require('../buildSettings');

const axiosInstance = Axios.create({
  baseURL: `${buildConfig.REACT_APP_API_URL}/api/`,
  headers: { secret: buildConfig.REACT_APP_SECRET_KEY }
});

const axiosRequest = async (method: Method, url: string, data: object | null, options: object) => {
  logRocketInfo(`axios.js request started: ${url}`);
  logEvent({ event: `axios.js request started: ${url}` });
  try {
    return await axiosInstance({
      method,
      url,
      ...(data ? { data } : {}),
      ...options
    });
  } catch (err) {
    logRocketError(err as any, '', 'axios.js request failed');
    logEvent({ url, error: err });
    return Promise.reject(err);
  }
};

export default {
  get: async (url: string, options = {}, apiVersion = 'v1') => {
    return await axiosRequest('get', `${apiVersion}/${url}`, null, { ...options });
  },
  post: async (url: string, data = {}, options = {}, apiVersion = 'v1') => {
    return await axiosRequest('post', `${apiVersion}/${url}`, data, { ...options });
  },
  put: async (url: string, data = {}, options = {}, apiVersion = 'v1') => {
    return await axiosRequest('put', `${apiVersion}/${url}`, data, { ...options });
  },
  delete: async (url: string, options = {}, apiVersion = 'v1') => {
    return await axiosRequest('delete', `${apiVersion}/${url}`, null, { ...options });
  }
};
