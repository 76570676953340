import React, { FC } from 'react';
// components
import { Text, Icon } from '../common';
import { Box } from '@xstyled/styled-components';

interface IErrorMessage {
  children: any;
  iconName?: string;
  title?: string;
  height?: string;
}

export const ErrorMessage: FC<IErrorMessage> = ({ title, iconName, children, ...props }) => {
  return (
    <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" width="100%" height="x16" {...props}>
      <Icon iconName={iconName || 'Error'} color="primary" fontSize="5rem" />
      {title && (
        <Text marginBottom="m" marginTop="m" fontSize="xxl" color="primary">
          {title}
        </Text>
      )}
      <Text maxWidth="x24" textAlign="center" lineHeight="1.5">
        {children}
      </Text>
    </Box>
  );
};
