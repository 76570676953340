import React, { FC } from 'react';
import styled from '@xstyled/styled-components';
import { system, SystemProps } from '@xstyled/system';
import { Nav as OfficeNav, INavProps } from '@fluentui/react';

export const Nav: FC<SystemProps & INavProps> = styled((props: SystemProps & INavProps) => {
  return (
    <OfficeNav
      {...props}
      styles={({ isSelected }) => ({
        chevronButton: {
          color: 'black',
          backgroundColor: 'white',
          selectors: {
            ':hover': {
              color: '#e01b26',
              backgroundColor: 'rgb(243, 242, 241)'
            },
            '::after': {
              borderLeft: 'black'
            }
          }
        },
        chevronIcon: {
          color: 'black',
          selectors: {
            ':hover': {
              color: '#e01b26'
            }
          }
        },
        link: {
          fontFamily: `'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif`,
          backgroundColor: 'white',
          color: isSelected ? '#e01b26' : 'black',
          selectors: {
            ':hover': {
              color: '#e01b26'
            },
            '&:after': isSelected
              ? {
                  borderLeft: '2px solid #e01b26'
                }
              : {}
          }
        },
        groupContent: {
          animation: 'none'
        }
      })}
    />
  );
})`
  ${system}
`;
