import React from 'react';
import { Box } from '@xstyled/styled-components';
import { Text, Icon, Link } from '../components/common';
import { Footer, Header, PageTitle } from '../components/skeleton';
import { setWindowToDesktop } from '../helpers';
import { withTracker } from '../services';

// only used in tests
setWindowToDesktop();

export const NotFound = () => {
  return (
    <>
      <Header />
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" minHeight={{ xs: 'x25', md: 'x40' }} padding="m" flex="1">
        <Icon iconName="SurveyQuestions" fontSize="10rem" marginBottom="x1" />
        <PageTitle>404 Page Not Found</PageTitle>
        <Text marginTop="x1" textAlign="center">
          We could not find the page you were looking for.
        </Text>
        <Text marginTop="s" textAlign="center">
          Please <Link to="/equipment-request">go to the find equipment page</Link> to create a service request.
        </Text>
      </Box>
      <Footer />
    </>
  );
};

export default withTracker(NotFound);
