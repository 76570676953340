import React, { FC } from 'react';
import { Box } from '@xstyled/styled-components';

import { Text } from './Text';
import { Icon } from './Icon';

export const NoResult: FC = ({ children }) => (
  <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" width="100%" height="x16">
    <Icon iconName="StatusCircleBlock" color="primary" fontSize="5rem" />
    <Text maxWidth="x24" textAlign="center" lineHeight="1.5">
      {children}
    </Text>
  </Box>
);
